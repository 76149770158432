@import 'vendor/colors';
@import 'partials/Quotes/quotes-popin-add-item';

div.external-availability-price > a.view-availability {
  background-image: url(../../images/picto_plus.png);
  width: auto;
  padding: 8px 13px 8px 21px;
  height: auto;
  display: block;
  background-color: #0F5E96;
  margin-top: 5px;
  border-radius: 6px;
  color: #fff;
  background-repeat: no-repeat;
  background-position: 4px 10px;
  min-width: 132px;
}

.productDetail {
  &.externalPlatfom{
    position:relative;
    margin-top:0;
    .carousel-product{
      width: 100%;
      top:0;
    }
  }
}

.popin-ptm-row {
  display: flex;
  align-items: center;
  .popin-ptm-label {
    font-weight: bold;
    word-break: break-word;
  }
  .popin-ptm-availability{
    .tooltips {
      display:none;
    }
    .availability-pricing {
      display: flex;
      align-items: center;
      .dispo-details {
        &.DispoECPF {
          display: flex;
          align-items: center;
          .text-description-green {
            margin-left: 7px !important;
          }
        }
        &.dispoA {
          padding-left: 15px;
          margin: 0;
          span input {
            width: auto !important;
          }
        }
        span {
          display: flex;
          align-items: center;
        }
      }
    }
    .datepicker-pricing {
      outline: none;
    }
    .dispo-price-service-unavailable {
      margin: 0 !important;
    }
  }
}
.popin-ptm-qty {
  .block-quantity {
    float: left;
    height: 84px;
    margin-top: 10px;
    width: 48px;
    .popin-action {
      margin-top: 0;
      height: 100%;
      width: 40px;
      border: 2px solid $lightgrey2;
    }
    .qty-btn {
      display: block;
      width: 36px;
      text-align: center;
      height: 32px;
      position: relative;
      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
    a {
      color: $darkGrey;
    }
    input {
      width: 34px;
      text-align: center;
      border: 0;
      outline: 0!important;
      font-weight: 700;
      margin-left: 1px;
    }
  }
  .minus {
    height: 30px;
    i {
      transform: translate(-50%,-70%);
    }
  }
}
.popin-ptm-container {
  width: 100%;
  .popin-ptm-margin {
    margin-bottom: 30px;
  }
}
.popin-ptm-alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin: 2rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}
.popin-ptm-alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.popin-ptm-alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.popin-ptm-tab {
  margin-top: 5px;
}

.external-availability-modal {
  &.choose_vehicle {
    .modal-dialog {
      width: 1000px;
    }
  }
  .modal-body.pB-0 {
    padding: 15px;
    .quantity-label {
      float: left;
      line-height: 108px;
    }
  }
  .external-availability-content {
    display: flex;
    flex-wrap: nowrap;
    .links-container {
      display: none !important;
    }
  }
  .external-quantity-container {
    display: flex;
    align-items: center;
  }
  .whole-delay {
    cursor: pointer;
  }
  .v3-pricing-part .dispo-price-bloc {
    padding: 0;
  }
  .productDetail {
    .carousel-product {
      .owl-carousel  {
        .item {
          img {
            max-width: 253px;
            max-height: 223px;
          }
        }
      }
    }
  }
  .ps-price-block.complete-price {
    height: auto !important;
  }
}
.complete-price {
  .tarif_hidden.hidden {
    display: block !important;
    visibility: visible !important;
    & > div:not(.external-availability-price) {
      display: none !important;
      visibility: hidden !important;
    }
  }
}
.external-with-ppft {
  padding-bottom: 30px;
  .links-container {
    top: 150px !important;
  }
}
.zoom-img-popin {
  z-index: 16;
}

.oilParameterForm {
  .input-without-border {
    border: none;
    outline: none;
  }
}