@media screen and (max-width: 1000px) {
  //init css
  h1, .h1, h2, .h2, h3, .h3 {
    margin: 0;
    font-family: $roboto;
    line-height: 20px;
  }
  .hidden-sm {
    display: none;
    visibility: hidden;
  }
  body {
    background: $white;
  }
  .text-2, .text-3 {
    font-family: $roboto;
    line-height: 20px;
    max-width: 400px;
    margin: 0 auto;
  }
  .text-3 {
    max-width: 380px;
  }
  .m-red {
    color: $cc-red;
  }
  .with-autossimo {
    display: block;
    font-weight: 600;
    font-size: 20px;
  }
  %login-btn {
    font-family: $roboto;
    font-size: 14px;
    border-radius: $btn-radius;
    cursor: pointer;
    border: 0;
    text-transform: uppercase;
    color: $white;
    padding: 15px 25px;
    background: $cc-red;
  }
  //erase css
  .home-container {
    min-width: auto !important;
    max-width: none !important;
    box-sizing: border-box;
    .home-logo {
      text-align: center;
      box-sizing: border-box;
      width: 50%;
      overflow: hidden;
      float: left;
      display: block;
      height: 100%;
      position: relative;
      img {
        width: calc(100% - 18px);
        max-width: 195px;
        margin: 0;
        display: inline-block;
        position: absolute;
        bottom: 15px;
        left: 15px;
      }
    }
    section {
      &.reassurance {
        padding-left: 15px;
        padding-right: 15px;
        .rea-heading {
          font-family: $roboto;
          font-size: 20px;
          font-weight: 600;
        }
        .rea-text {
          font-family: $roboto;
          line-height: 20px;
          font-weight: 600;
          font-size: 14px;
          margin-bottom: 25px;
        }
        .rea-point-container {
          margin: 0 auto;
          max-width: 530px;
        }
        .rea-point {
          border: 0;
          display: block;
          width: 100%;
          padding-left: 115px;
          float: left;
          min-height: 144px;
          text-align: left;
          font-family: $roboto;
          font-weight: 500;
          &:after {
            background-color: transparent;
          }
          p {
            font-family: $roboto;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
          }
          .point {
            font-family: $roboto;
            font-size: 18px;
            font-weight: 700;
            text-align: left;
            &:after {
              width: 100px;
              height: 100px;
              background-size: 120px;
              margin: 0;
              position: absolute;
              top: 0;
              left: 0;
            }
            &.precis {
              & + p {
                max-width: 320px;
              }
            }
            &.rapide {
             &:after {
                background-position: -10px -125px;
              }
              & + p {
                max-width: 320px;
              }
            }
            &.simple {
              &:after {
                background-position: -10px -240px;
              }
              & + p {
                max-width: 320px;
              }
            }
            &.complet {
              &:after {
                background-position: -10px -240px;
              }
              & + p {
                max-width: 320px;
              }
            }
          }
        }
      }
      &.presentation {
        padding: 30px 15px;
        height: auto;
        border-top: 0;
        background: url('../../../../../images/m-home-bg.png') $c-blue;
        margin-top: 0;
        .pres-wrapper {
          margin: 0;
          width: auto;
        }
        .text-2 {
          font-size: 16px !important;
          font-weight: 500;
        }
        .text-3 {
          font-size: 14px;
          line-height: 16px;
        }
        .button-wrapper {
          form {
            .affiliate {
              background: $white;
              color: $cc-red;
              border-radius: 10px;
              font-family: $roboto;
              line-height: 20px;
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
      }
      &.platform {
        padding: 30px 15px;
        h3 {
          width: 100%;
          text-align: left !important;
          margin-top: 20px;
          font-size: 18px;
          font-weight: 500;
          &.pf-heading {
            max-width: 280px;
            margin: 0 auto;
            text-align: center !important;
          }
        }
        .thumbnail {
          width: 100%;
          display: block;
          padding-top: 20px;
          img {
            width: 100%;
            height: auto;
            max-width: 313px;
            display: inline-block;
          }
          .platform-list {
            margin-top: 20px;
            padding: 0;
            li {
              width: 100%;
              list-style: none;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }
    }
    .video-container {
      display: block;
      margin-top: 30px;
      margin-bottom: 15px;
      video {
        width: 100%;
      }
    }
  }
  .to-login-container {
    width: 50%;
    position: relative;
    display: block;
    float: left;
    height: 100%;
    .to-login-btn {
      position: absolute;
      right: 15px;
      bottom: 15px;
      display: inline-block;
      background: $cc-red;
      @extend %login-btn;
      font-size: 13px;
    }
  }
  header {
    height: 77px;
    .login-form-container {
      display: none;
      position: absolute;
      top: 76px;
      z-index: 1;
      width: 100%;
      padding: 70px 0;
      background: $white;
      -webkit-box-shadow: inset 1px 50px 34px 0px rgb(0 0 0 / 7%);
      -moz-box-shadow: inset 1px 50px 34px 0px rgb(0 0 0 / 7%);
      box-shadow: inset 1px 50px 34px 0px rgb(0 0 0 / 7%);

      .login-form {
        margin: 0 auto;
        width: 300px;
        .error {
          position: relative;
          width: 100%;
          bottom: 0;
        }
        .form-box {
          float: none;
          margin: 0 auto 20px;
          input {
            width: 100%;
            outline: none;
          }
        }
        .login-submit {
          @extend %login-btn;
          position: relative;
          display: block;
          right: auto;
          bottom: auto;
          margin: 20px auto;
          width: 205px;
        }
      }
    }
  }
  .general-footer {
    width: 100%;
  }
  //rgpd
  .rgpd-container {
    text-align: center;
    .container {
      width: auto;
      & > div {
        width: 100%;
      }
    }
  }
}


