.headband {
  top: 130px;
  position: fixed;
  text-align: center;
  z-index: 2;
  @media screen and (max-width:1340px) {
    & {
      display: none;
    }
  }
  &.right {
    right: 0;
  }
  &.left {
    left: 0;
  }
}