.devis-maintain, .packages-ad {
    padding: 15px 0;
    .content {
        background: white;
        padding: 15px;
        box-shadow: 0px 1px 1px 1px rgba(0, 1, 1, 0.11);
        overflow: hidden;
        .select-step,
        .select-vehicle,
        .informations,
        .kilometer{
            margin-bottom: 60px;
        }
    }
    h1 {
        font-weight: 700;
        color: #000;
        margin: 0;
        font-size: 16px;
        position: relative;
        text-transform: uppercase;
    }
    h1::after,
    section h2::after{
        content: '';
        margin-top: 10px;
        margin-bottom: 10px;
        padding-bottom: 5px;
        display: block;
        width: 80px;
        left: 0px;
        border-bottom: 4px solid #e10f21;
    }

    i.calculator{
        background-image: url(../../../images/devis/calculator.png);
        height: 31px;
        width: 21px;
        position: absolute;
        top: -5px;
        left: 0;
    }
    .head h1{
        text-indent: 35px;
    }
    .steps {
        color: #8c8c8c;
        padding: 25px 0 15px 0;
        overflow: hidden;
        #steps-picture{
            height: 23px;
        }
        ul {
            list-style: none;
            display: block;
            padding: 0;
            overflow: hidden;
            margin: 0;
            li {
                float: left;
                text-transform: capitalize;
                font-size: 12px;
                font-weight: bold;
                p {
                    text-transform: none;
                }
            }
            .active {
                color: #0f5e96;
            }
            .step-1 {
                width: 150px;
            }
            .step-2 {
                text-align: center;
                width: 365px;
            }
            .step-3 {
                text-align: center;
                width: 250px;
            }
            .step-4 {
                text-align: right;
                width: 203px;
            }
        }
    }
}