/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 5 avr. 2016, 14:05:07
    Author     : saimrudu
*/

/*
    Imports
 */

@import '../vendor/colors';
@import "../vendor/font-family";

/*
    Utilitary classes
 */
.float-right {
    float: right;
}

.overflow-hidden {
    overflow : hidden;
}

.inline-block {
    display: inline-block;
}

.pointer {
    cursor: pointer;
}

$width : 25%;
/*
    End utilitary classes
 */

.cart-option a.btn-cart {
    text-indent: 30px;
    color: #fff;
    height: 15px;
    background: #D80000;
    text-align: center;
    background-position: left;
    background-position: 30%;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 12px;
    padding-top: 10px;
}

.cart-option a.btn-cart:hover {
    background: #D80000;
}

.panier-details .cart-option .btn-cart {
    height: 35px;
    width: 60%;
}

.cart-option .btn-cart span {
    left: 13%;
    top: 65%;
    background: rgba(0, 0, 0, 0) url("../../../images/panier_white.png") no-repeat scroll 0 0 ;
}

a.btn-cart, span.emptyCart, span.ajaxError {
    float: right;
}

a.btn-cart img.spinner { /* spinner*/
    display: none;
    margin: -3px 0 0 -33px;
    position: absolute;
    width: 25px;
}

span.emptyCart, span.ajaxError { /* votre panier est vide*/
    color: red;
    display: none;
    font-size: 15px;
    font-style: italic;
    text-align: center;
    width: 198px;
}

.bloc-reference > .row > [class^="col-"], .bloc-reference > .row > [class*=" col-"] {
    border-bottom: 0px;
}

table.table-bordered.table-condensed tbody tr.panier-input td.prix-details {

    border-bottom-color: white;
}

.reference-produits-container .produits > table .complements-produit td {
    text-align: left;
}

.reference-produits-container .produits > table .complements-produit td a {
    color: #fff;
    cursor: pointer;
}

.reference-produits-container .produits > table > thead > tr > th {
    padding: 0px;
}

.reference-produits-container .produits > table .produit-visuel-container .add-favoris {
    top: -17px;
    width: 160px;
    background: rgba(0, 0, 0, 0) url("../../../images/star_empty_transparent.png") no-repeat scroll left top;
    padding-top:2px;
    border-right: thin solid #fff;
}

.icon-ajout-devis {
    background: rgba(0, 0, 0, 0) url("../../../images/devis.png") no-repeat scroll 0 0;
    height:15px;
    margin: 0 0 0 0;        }

tbody tr.complements-produit.could-have-class-no-border-bottom.truc.hover td,
tbody tr.complements-produit.could-have-class-no-border-bottom.truc {
    background: #0f5e96;
    color: #fff;
    height: 40px;
}

table.table-bordered.table-condensed tbody tr.panier-input td p.title {
    color: #0f5e96;
}

.reference-produits-container .produits > table tr.panier-input td.prix-details {
    padding-top: 5px;
}

.reference-produits-container .produits > table tr.panier-input td.prix-details.topspace {
    padding-top: 18px;
}

table.table-bordered.table-condensed tbody tr.panier-input td.caracteristiques-details {
    border-left-color: white;
    border-right-color: white;
    border-bottom-color: white;
}

.caracteristiques-details.black_text .row{
    margin-left: 0;
}

table.table-bordered.table-condensed tbody tr.panier-input td.prix-details h5 {
    text-align: center;
    padding: 13px;
}

.reference-produits-container .produits > table .panier-details {
    padding-right: 35px;
}

table.table-bordered.table-condensed tbody tr.panier-input td.panier-details {
    border-left-color: white;
}

.cart-option .minus, .cart-option .plus {
    height: 35px;
    background-color: #ccc7c2;
    width: 35%;
}

.cart-option .value {
    border: 1px #d2cece solid;
    font-size: 16px;
    height: 27px;
    width: 27px;
    &.disabled-qte {
        width: 84px;
    }
}

table.table-bordered.table-condensed tbody tr.complements-produit.could-have-class-no-border-bottom td.fiche {
    border-right-color: transparent;
    border-left-color: #d2cece;
    /*    text-align: left;   */
}

table.table-bordered.table-condensed tbody tr.complements-produit.could-have-class-no-border-bottom td.fichesmallline {
    border-right-color: transparent;
    color: red;
    text-align: left;
}

table.table-bordered.table-condensed tbody tr.complements-produit.could-have-class-no-border-bottom td.fichesmallline_white {
    border-right-color: transparent;
    color: white;
    text-align: left;
    width: 10%;
}

table.table-bordered.table-condensed tbody tr.complements-produit.could-have-class-no-border-bottom.truc td.could-have-class-no-border-bottom.fiche {
    width: 10%;
}

.icon-fiche-produit {
    background: none;
}

.reference-produits-container .produits > table .complements-produit td .row {
    width: 105%;
}

.reference-produits-container .produits > table .caracteristiques-details, .reference-produits-container .produits > table .references-details {
    font-size: 12px;
}

table.table-bordered.table-condensed tbody tr.panier-input td.caracteristiques-details strong {
    color: #000;
}

.reference-produits-container .produits > table .complements-produit td .ajout-devis {
    text-align: left;
    padding-bottom: 30px;
}

.reference-produits-container .produits > table tr.title .arrows,
section.ref-results-controls.row .sort > span.arrows {
    padding-top: 7px;
}

table.table-bordered.table-condensed tbody tr.complements-produit.could-have-class-no-border-bottom.shaded-bottom-border {
    box-shadow: -3px 5px 4px -6px #171515;
    -webkit-box-shadow: -3px 5px 4px -6px #171515;
    -moz-box-shadow: -3px 5px 4px -6px #171515;
    -o-box-shadow: -3px 5px 4px -6px #171515;
}

.reference-produits-container .produits > table tr.title {
    font-style: normal;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
}

table.table-bordered.table-condensed thead tr.title.head th {
    border-right-color: transparent;
    border-left-color: transparent;
    font-size: 13px;
    height: 60px;
}

table.table-bordered.table-condensed tbody tr.panier-input td.could-have-class-no-border-bottom {
    border-right-color: transparent;
}

table.table-bordered.table-condensed tbody tr.panier-input td.could-have-class-no-border-bottom div.produit-visuel-container div.produit-visuel {
    border-color: #ccc7c2;
    border-style: solid;
    border-width: 1px;
}

.reference-produits-container .produits > table tr.panier-input td.could-have-class-no-border-bottom {
    padding-top: 10px;
    padding-bottom: 10px;
}

.bloc-reference .body {
    border-left: 1px solid #cbcbcb;
    border-bottom: 1px solid #cbcbcb;
}

div.reference-produits-container div.references-produit-list div.reference-produits-container div.bloc-reference div.row.body div.col-xs-7{
    background: rgba(0, 0, 0, 0) url("../../../images/temps_bareme.png") no-repeat scroll 30px center;
    text-indent:58px;
    padding:7px;}

.temps_bareme{
    background: rgba(0, 0, 0, 0) url("../../../images/temps_bareme.png") no-repeat scroll 30px center;
    text-indent:45px;
}

div.reference-produits-container div.produits table.table-bordered.table-condensed thead tr.title.head th div.produits p.countResult {
    color: #000;
}

div.reference-produits-container div.bloc-reference div.row.body {
    padding: 16px;
    min-height: 50px;
}

/*for header from here*/
.header .header-holder {
    background: rgba(0, 0, 0, 0) url("../../../images/trans-header.png") repeat scroll 0 0;   }

div.add-nav {
    width: 250px;
}

.user-action .icon.icon-fav {
    background: rgba(0, 0, 0, 0) url("../../../images/favoris_new.png") no-repeat scroll 0 0;        }

.user-action .icon.icon-panier {
    background: rgba(0, 0, 0, 0) url("../../../images/panier_new.png") no-repeat scroll 0 0;     }

.user-action .icon.icon-mail{
    background: rgba(0, 0, 0, 0) url("../../../images/icon-mail_blue.png") no-repeat scroll 0 0;       }

.user-action .icon.icon-profile ,
.icon-profile{
    background: rgba(0, 0, 0, 0) url("../../../images/profile_blue.png") no-repeat scroll 0 0;        }

.user-action .icon.icon-profile_arrow{
    background: rgba(0, 0, 0, 0) url("../../../images/arrow_down_red.png") no-repeat scroll 0 0;
    margin-top:5px;     }

.user-action li {
    background: transparent none repeat scroll 0 0;
    height: 59px;
    width: 35px;
}

div.wrapper div.header div.header-holder div.container ul.user-action.pull-right li.star strong a span.icon.icon-fav {
    width: 22px;
    height: 22px;
}

div.produits div.reference-produits-container div.bloc-reference div.row div.col-xs-7.main div.head,
div.produits div.reference-produits-container div.bloc-reference div.row div.col-xs-5.main div.head,
div.produits div.reference-produits-container div.bloc-reference div.row div.col-xs-3.main div.head,
div.produits div.reference-produits-container div.bloc-reference div.row div.col-xs-4.main div.head {
    padding-top: 13px;
}

div.produits div.reference-produits-container div.bloc-reference div.row div.col-xs-15.main div.head {
    padding-top: 13px;
}

.bloc-reference .main {
    border-left: 0px none #cbcbcb;
}

div.wrapper div.produits div.reference-produits-container div.bloc-reference div.row.pad_filAriane {
    padding-left: 170px;
    padding-right: 95px;
}

div.row.pad_filAriane div.col-xs-1.main span.input-group-btn.search-btn-red button#btnExpress.btn.btn-search-pieces-dark.search-btn-red {
    background: #d80000;
    height: 45px;
    padding-right: 1px;
    border-color: red;
    padding-top: 10px;
}

ul.nav.row li.col-xs-2.quotation a.dropdown-toggle span.text {
    color: #000;
}

ul.nav.row li.col-xs-2.informations a.dropdown-toggle span.text {
    color: #000;
}

div.header div.header-holder div.container div.nav-holder div.container-fluid ul.nav.row li.col-xs-2.quotation {
    width: auto;
    padding-top: 17px;
}

div.header div.header-holder div.container div.nav-holder div.container-fluid ul.nav.row li.col-xs-2.informations {
    padding-top: 5px;
    width: 150px;
}

.nav-holder {
    font: 14px/21px "Arial", sans-serif;
}

div.reference-produits-container div.bloc-reference div.row.pad_filAriane div.col-xs-4.main div.head.vehicle{
    background: rgba(0, 0, 0, 0) url("../../../images/recherche_vehicle.png") no-repeat scroll 0 0;
    background-position:  left; }

.vehicle_blue{
    background: rgba(0, 0, 0, 0) url("../../../images/recherche_vehicle_blue.png") no-repeat scroll 0 0;
    background-position:  left; }

div.bloc-reference div.row.pad_filAriane.pad-top,
div.bloc-reference div.row.pad-top,
.pad-top {
    padding-top: 20px;
}

div.reference-produits-container div.bloc-reference div.row.pad_filAriane.pad-top span.btn,
div.reference-produits-container div.bloc-reference div.pad-top span.btn {
    background-color: #d80000;
    color: #fff;
}

div.produits div.reference-produits-container div.bloc-reference.xx div.row.pad-top div.col-xs-6 span.btn-xs.white-block {
    padding: 9px;
    border-color: red;
}

div.reference-produits-container div.bloc-reference div.row.pad_filAriane.pad-top span.btn-xs.white-block,
.white-block {
    background-color: #fff;
    padding: 9px;
    border-color: #ccc7c2;
    margin: 1px;
}

.nav > li > a.image-more {
    padding: 12px 4px;
}

p.line_gap {
    margin: 16px 17px -36.5px;
}

.dd,
.pubtech-open-catalogue {
    position: relative;
}

.dd::after{ content:"";
    position: absolute;
    background:  url("../../../images/sprite.png") -178px -220px;
    width: 30px;
    height: 15px;
    margin-right:-15px ;   }

.showDevisToolbox::after, .pubtech-open-catalogue::after{
    content:"";
    background: rgba(0, 0, 0, 0) url("../../../images/jquery-ui/base/images/ui-icons_cd0a0a_256x240.png") no-repeat scroll -63px 1px;
    position: absolute;
    width: 22px;
    height: 20px;
    margin-right: -15px;
}

strong.open .showDevisToolbox::after, .pubtech-open-catalogue.pubtech-open-catalogue-opened::after{
    background: rgba(0, 0, 0, 0) url("../../../images/jquery-ui/base/images/ui-icons_cd0a0a_256x240.png") no-repeat scroll 2px 1px;
}


.icon-search-form-search_btn{
    background-image: url("../../../images/search_symbol.png");
    background-position: 0 0;
    height: 38px;
    line-height: 38px;
    width: 27px;
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus, .nav .open > a:active {
    border-bottom: 4px solid #d80000;
}

.cart-option .minus {
    background-image: url("../../../images/minus_symbol.png");
    background-position: 7px 13px;
    background-size: 12px 2px;
    height: 27px;
    width: 27px;
}

.cart-option .plus {
    background-image: url("../../../images/plus.png");
    background-position: 6px 5px;
    background-size: 16px 16px;
    height: 27px;
    width: 27px;
}

.wrapper,
.wrapper-relative {
    margin: auto;
    width: 1000px;
    background: #e6e6e6;
    padding: 0;
}

.wrapper-relative {
    width: 1000px;
    position: relative;
}

.scrolled .wrapper > .header {
    width: 1255px;
}

.add-favoris::after {
    /*content:"|";*/
    position: absolute;
}

.smallrow::after {
    /*content:"|";*/
    /*border-right: medium solid #fff;*/
}

.devis::after {
    content:"";
    background: rgba(0, 0, 0, 0) url("../../../images/jquery-ui/base/images/ui-icons_cd0a0a_256x240.png") no-repeat scroll -63px 1px;
    position: absolute;
    width: 20px;
    height: 20px;
    margin-right: -15px;
}

tr.pad-effect  {
    border-top: 10px solid;
    border-bottom: 10px solid;
    border-color: #e6e6e6;
}

.spacer {
    height: 3px;
    background-color: transparent;
}

a._action-open-fiche-vehicule,
td.fiche-vehicule a {
    color: #0f5e96;
    text-decoration: none;
}

td.fiche-vehicule a.disable_package  {
    color: #b4b4b4;
}

td.fiche-vehicule a.disable_package:hover  {
    text-decoration: none;
}

.right-arrow{
    content:"";
    background: rgba(0, 0, 0, 0) url("../../../images/jquery-ui/base/images/ui-icons_cd0a0a_256x240.png") no-repeat scroll -31px 5px;
    position: absolute;
    width: 20px;
    height: 20px;
}

div.main.container div#infos-generales div.row.bloc-historique h4.title{
    height: 22px;
    text-indent: 30px;
    font-weight: normal;
}

div.wrapper div.main.container div.row div.col-xs-5 h2.title.devis_encours,
div.wrapper div.main.container div.row div.col-xs-6 h2.title.commandes_encours {
    font-size: 13px;
    color: black;
}

.action-catalogue:after{
    content:"";
    background: rgba(0, 0, 0, 0) url("../../../images/jquery-ui/base/images/ui-icons_cd0a0a_256x240.png") no-repeat scroll -58px 0;
    position: absolute;
    width: 27px;
    height: 20px;
    margin-right: -15px;
}

.action-catalogue {
    background-color: #ddd;
}

div.row div.col-xs-5 h2.title.devis_encours{
    background: rgba(0, 0, 0, 0) url("../../../images/devis-encours.png") no-repeat scroll 0 0;
    height: 30px;
    text-indent: 30px;
    padding-top: 5px;
    margin-left: 15px;
}

div.row div span.side_arrow:after{
    background: rgba(0, 0, 0, 0) url("../../../images/jquery-ui/base/images/ui-icons_cd0a0a_256x240.png") no-repeat scroll -33px -1px;
    content: "";
    height: 20px;
    position: absolute;
    width: 20px;
}

div.row div.col-xs-6 h2.title.commandes_encours {
    background: rgba(0, 0, 0, 0) url("../../../images/commandes_encours.png") no-repeat scroll 0 0;
    height: 30px;
    text-indent: 35px;
    padding-top: 5px;
    margin-left: 15px;
}

.commandes_encours:hover,
.devis_encours:hover {
    border-bottom: 3px solid #d80000;
}

/*
div.row.bloc-historique div.row div.centered a.btn.btn-default.see-all {
    background-color: #ddd;
    border-color: transparent;
}
*/

div.row.bloc-historique div.row div.toggle-btn-historic a.btn.btn-default.close-all, div.row.bloc-historique div.row div.toggle-btn-historic a.btn.btn-default.see-all {
    padding: 15px 30px;
    font-weight: 700;
    margin: 15px;
    background-color: red;
    color: #fff;
    text-transform: uppercase;
}

.table-result-search {
    tbody {
        .type-immatriculation {
            text-transform: none;
            white-space: nowrap;
        }
        .type-immatriculation::first-letter {
            text-transform: uppercase;
        }
        .fiche-vehicule {
            text-align: left;
            text-wrap: none;
        }
        .td-quote {
            white-space: nowrap;
            padding-right:25px;
            border-left:#ececec 0px solid;
            border-right:#ececec 0px solid;
            .right-arrow {
                background-position-y: 0px;
            }
            .row{
                padding-top: 2px;
                margin-left: -18px;
                margin-right: -31px;
            }
            a {
                font-weight: 700;
                font-size: 12px;
                color: #0f5e96;
            }
        }

        .error-fiche-vehicule{
            .first-row{
                height: 5px;
                padding-left: 500px;
                img{
                    margin-top: -30px;
                }
            }
            .second-row{
                .text{
                    background: url(../../../images/info_red.png) left center no-repeat;
                }
            }
        }

    }
}

.white_background {
    background-color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 7px;
}

.input-group-lg > .input-group-btn > .btn {
    height: 36px;
    padding-right: 7px;
}

.free_space {
    background: #e6e6e6 none repeat scroll 0 0;
    width: 1000px;
    position: relative;
    right: 15px;
}

.free_space_background {
    right: 15px;
    position: relative;
}

.body_background {
    right: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}

.wrapper_background {
    background-color: transparent !important;
}

tbody tr.row.top_text td {
    vertical-align: top;
}

div.wrapper div.main.container div div#infos-generales div.row.bloc-historique div.row div.centered {
    margin-bottom: 10px;
}

.red_text {
    color: #d80000;
}

.white_text {
    color: #fff;
}

.green_text {
    color: #7AC943;
}

.blue_text {
    color: #0f5e96;
}

.black_text {
    color: black;
}

.orange_text {
    color: #f6a828;
}

.blue_background_block {
    background-color: #0f5e96;
}
.red_background_block {
    background-color: #eb212e;
}
.white_background_block {
    background-color: #fff;
}

.background_grey_choisir {
    background-color: #ddd;
    height: 35px;
    padding: 10px;
}

.background_grey_choisir:after {
    content:"";
    background: rgba(0, 0, 0, 0) url("../../../images/jquery-ui/base/images/ui-icons_cd0a0a_256x240.png") no-repeat scroll -58px 0;
    position: absolute;
    width: 27px;
    height: 20px;
    margin-right: -15px;
}

div.wrapper div.produits div.reference-produits-container div.bloc-reference div.row.pad-top div.col-xs-4 span div.background_grey_choisir {
    width: 153px;
}

html.js body div.wrapper div.produits div.reference-produits-container div.bloc-reference div.row.pad-top {
    background-color: #fff;
    margin-left: 155px;
    padding-left: 47px;
    width: 980px;
}

.pad-bottom {
    padding-bottom: 10px;
}

.arrow_red:after {
    content:"";
    background: rgba(0, 0, 0, 0) url("../../../images/jquery-ui/base/images/ui-icons_cd0a0a_256x240.png") no-repeat scroll -67px -5px;
    position: absolute;
    width: 19px;
    height: 16px;
    margin-top: 7px;
}

.aucune_ref_eq {
    background-color: #f6a828;
    color: #fff;
    font-size: 17px;
    padding: 20px;
}

.border_space {
    border-left: 1px solid #ccc7c2;
    border-right: 1px solid #ccc7c2;
}

.pieces {
    content:"";
    background-image: url("../../../images/pieces_1.png");
    background-repeat: no-repeat;
    position: absolute;
    width: 60px;
    height: 40px;
    padding-left: 60px;
    padding-top: 10px;
    color: #fff;
}

.pieces_1 {
    content:"";
    background-image: url("../../../images/pieces_2.png");
    background-repeat: no-repeat;
    position: absolute;
    width: 60px;
    height: 40px;
    padding-left: 40px;
    padding-top: 5px;
    color: #fff;
}

.peintures {
    line-height: 45px;
    color: #fff;
    background: url(../../../images/sprite.png) no-repeat -91px -327px;
    width: 31px;
    height: 31px;
    vertical-align: -8px;
    display: inline-block;
    margin-top: 5px;
}
.peintures.disabled {
    text-align: center;
}

.active .peintures {
    margin-top: 0;
}

.pneus {
    background: url(../../../images/sprite.png) no-repeat -98px -289px;
    width: 31px;
    height: 28px;
    vertical-align: -8px;
    display: inline-block;
    margin-top: 6px;
}

.consumables {
    content:"";
    background-repeat: no-repeat;
    position: absolute;
    height: 30px;
    padding-left: 35px;
    color:#fff;
}

.active .pneus {
    margin-top: 0;
    padding-top: 0;
}

.user-action .shipping .icon {
    height: 20px;
}

.steelblue_div{
    height:50px;
    padding-top: 5px;
    padding-left: 50px;
    background-color: #aabed3;
    border-top: solid #e6e6e6 3px;
    border-bottom: solid #fff 2px;
    border-left: solid #fff 2px;
    color: #fff;
    flex: 1 1 0px;
}

#headernav .nav-container{
    border-bottom: 0 !important;
}
.nav-holder .piecesnav {
    padding-top: 8px;
    border-left: none;
}

.nav-holder .consommablesnav {
    padding: 0 !important;
    a{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 98%;
        height: 100%;
        padding: 8px;
        text-align: center;
        color: #fff;
        .consumables-span-nav{
            background: url(../../../images/sprite.png) no-repeat -300px -260px;
            width: 52px;
            height: 36px;
            display: inline-block;
            vertical-align: middle;
        }
        .consumables-span-nav-text{
            font-size: 13px;
            padding: 3px;
        }
    }
}
.nav-holder .pneusnav {
    padding: 0 !important;
    a{
        display: block;
        width: 100%;
        height: 100%;
        text-align: center;
        color: #fff;
        line-height: 49px;
    }
}
.nav-holder .pneusnav {
    text-align: center;
    .pneus.disabled{
        background-repeat: no-repeat ;
        background-position: 0 50%;
        color: #fff;
        display: inline-block;
        line-height: 50px;
        width: 80px;
        height: auto;
        padding: 0;
        text-align: right;
        position: static;
        margin-top: 0;
    }
}
.nav-holder .peinturesnav {
    padding: 0 !important;
    a{
        display: block;
        width: 100%;
        height: 100%;
        text-align: center;
        color: #fff;
        line-height: 50px;
        .peintures{
            background: url(../../../images/sprite.png) no-repeat -91px -329px;
            width: 31px;
            height: 31px;
            vertical-align: -8px;
            display: inline-block;
        }
    }
}
.nav-holder .peinturesnav {
    text-align: center;
}
.nav-holder .piecesnav {
    padding: 0 !important;
    a{
        display: block;
        width: 100%;
        height: 100%;
        text-align: center;
        color: #fff;
        line-height: 49px;
        .parts{
            background: url(../../../images/sprite.png) no-repeat -44px -279px;
            width: 36px;
            height: 22px;
            vertical-align: -8px;
            display: inline-block;
        }
    }
}
.nav-holder .piecesnav {
    text-align: center;
    .parts.disabled{
        background: url(../../../images/sprite.png) no-repeat -44px -279px;
        width: 41px;
        height: 31px;
        vertical-align: -13px;
        display: inline-block;
        margin-top: 6px;
    }
}
/*    height:75px;
    padding-top: 10px;
    padding-left: 85px;
    background-color: #aabed3;
    border-top: solid #e6e6e6 7px;
    border-bottom: solid #fff 1px;
    border-left: solid #fff 1px;
}

.steelblue_div:first-of-type {
    border-left-width: 0;
}

.nav-holder .piecesnav {
    margin-left: 142.5px;
    width: 26%;
}

.nav-holder .peinturesnav {
    width: 27%;
}

.nav-holder .pneusnav {
    width: 24.8%;

}*/
.nav-holder .active{
    background-color: #0f5e96;
    height: 50px;
    width: $width;
    padding-top: 20px;
}
.nav-holder .active:not(.opisto-nav-tab) {
    border-bottom: 1px solid #0f5e96;
    border-top: 1px solid #0f5e96;
}
.nav-holder .active_consumables{
    padding-top: 8px;
}

.profile::after {
    /*content:"|";*/
}

.red_circle {
    background-color: #d80000;
    border-radius: 50%;
    color: #fff;
    font-size: 6px;
    font-weight: normal;
    min-width: 10px;
    padding: 3px 7px;
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;
    padding-top: 5px;
}

.no_white-space {
    white-space: nowrap;
}

.background_grey {
    background-color: #e6e6e6;
}

.input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group {
    margin-left: 0px;
}

.pieces_background {
    margin-left: 142.5px;
    background: #0f5e96 none repeat scroll 0 0;
    height: 75px;
    padding-top: 15px;
}

.affectation-origine .results label {
    display: block;
    font-weight: normal;
    text-transform: none;
    margin-top: 10px;
}

.bloc-reference .name, .bloc-reference .description {
    font-size: 11px;
    color: #0f5e96;
    position: relative;
}

.bloc-reference .tags.blue_background li {
    background: #0f5e96 none repeat scroll 0 0;
    color: #fff;
    display: inline-block;
    font-family: "Futura LT", sans-serif;
    margin-right: 20px;
    padding: 5px 15px;
}

.grey_line {
    background: #e6e6e6 none repeat scroll 0 0;
    &.affectation-origin {
        padding: 35px 0 25px;
    }
}

.grey_line22 {
    background: #f2f2f2 none repeat scroll 0 0;
}

.sub-nav-block.modele {
    height: 65px;
}

div.main div.affectation-origine.container div.row div.centre div.results div.tri.grey_line.row div.col-xs-6.detail_piece div.triEquipementiers div.btn-group.bootstrap-select button.btn.dropdown-toggle.selectpicker.btn-bootstrap-select span.caret,
div.wrapper div.main.container div div.main div.affectation-origine.container div.row div.centre div.results div.result div.row div.detail_piece div.bloc-reference div.row div.col-xs-7.autres div.col-xs-10.choix-equipementiers div div.btn-group.bootstrap-select button.btn.dropdown-toggle.selectpicker.btn-bootstrap-select span.caret{
    background: rgba(0, 0, 0, 0) url("../../../images/jquery-ui/base/images/ui-icons_cd0a0a_256x240.png") no-repeat scroll -67px -5px ;
    border: medium none;
    height: 11px;
    margin-top: -2px;
    position: absolute;
    right: 12px;
    top: 43%;
    vertical-align: middle;
    width: 16px;
}

.nav-holder div.container-fluid ul.nav.row li.col-xs-2.quotation a.dropdown-toggle {
    padding: 13px 15px;
}

.container-fluid ul.nav.row li.col-xs-2.quotation a.dropdown-toggle span.text,
.container-fluid ul.nav.row li.col-xs-2.informations a.dropdown-toggle span.text {
    font-size: 12px;
}

.container-fluid ul.nav.row li.col-xs-2.quotation {
    width: 10px;
}

.nav-holder div.container-fluid ul.nav.row li.col-xs-2 {
    padding-left: 0px;
    padding-right: 0px;
}

.search-pieces .types {
    box-shadow: 0 0px 0px 0px #2c2c2c;
}

html.js body div.wrapper div.header div.header-holder div.container div.nav-holder {
    position: inherit;
}

html.js body div.wrapper div.ariane.container,
html.js body div.wrapper div.flfilters.container {
    background: #fff;
    width: 1000px;
    margin-bottom: 7px;
}

html.js body div.wrapper div.ariane.container div.row div a,
html.js body div.wrapper div.flfilters.container div.row div.col-xs-4 a {
    height: 50px;
    background: #fff none repeat scroll 0 0;
    text-decoration: none;
}

html.js body div.wrapper div.ariane.container div.row div.col-xs-4 a.critere,
html.js body div.wrapper div.flfilters.container div.row div.col-xs-4 a.critere {
    height: 25px;
    background: #fff none repeat scroll 0 0;
    text-decoration: none;
}

.ariane.container div.row div.col-xs-4,
.flfilters.container div.row div.col-xs-4 {
    border-right: inherit;
}

.more-filters {
    padding-right: 15px;
    padding-top: 24px;
    text-align: right;
}

.bloc-image-ariane {
    margin-top: 7px;
    padding: 10px;
    float: left;
    .search-vehicle {
        background: url(../../../images/sprite.png) $red no-repeat -289px -218px;
        width: 39px;
        height: 37px;
        display: inline-block;
    }
}

.bloc-vehicle {
    margin-top: 7px;
    padding: 17px;
    width: 100%;
    height: 100%;
}

html.js body.scrolled div.wrapper div#headernav.header-nav div.nav-container div.nav-holder {
    background-color: inherit;
}

.row div.col-xs-4 span.active.active {
    background: #fff none repeat scroll 0 0;
    color: inherit;
}

.row div.col-xs-3 span.active.active {
    background: #fff none repeat scroll 0 0;
    color: inherit;
}

.row div.col-xs-4.family a span {
    padding: 10px;

}

.row div.col-xs-4.family a span span.caret {
    display: inline-block;
}

html.js body div.wrapper div.main.container div div.main div.affectation-origine.container div.row div.centre div.results div.tri.grey_line.row div.col-xs-8.countResult {
    color: black;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.form-search-express .input-group .logo-immatriculation {
    left: 25px;
}

.btn-search-pieces-dark {
    background-color: red;
    border-color: red;
}

.form-search-vehicule .input-group {
    border: 0px solid #196fbd;
}

.search-pieces .form-search-vehicule{
    padding: 22px 14px 10px;
}

.references-produit-list {
    margin: 0 0px 29px 5px;
}

.filter-body {
    width: 100%;
    float: left;
}

.filter-position {
    width: 100%;
    float: left;
}

.reference-produits-container .produits > table tr.title .arrows a.up,
section.ref-results-controls .sort > span.arrows a.up {
    background: rgba(0, 0, 0, 0) url("../../../images/icon_up_arrow.png") no-repeat scroll 0 0;
    cursor: pointer;
    display: block;
    height: 6px;
    width: 13px;
}

.reference-produits-container .produits > table tr.title .arrows a.bottom,
section.ref-results-controls .sort > span.arrows a.bottom {
    background: rgba(0, 0, 0, 0) url("../../../images/icon_down_arrow.png") no-repeat scroll 0 0;
    cursor: pointer;
    display: block;
    height: 6px;
    width: 13px;
}

#shelter22 {
    text-transform: uppercase;
    background-color: #f1f1f1;
    margin-bottom: 10px;
    box-shadow: 1px 2px 3px #b2b2b2;
}

html.js body div.wrapper div.ariane.container div#shelter22 strong.blue_text,
html.js body div.wrapper div.flfilters.container div#shelter22 strong.blue_text {
    text-transform: uppercase;
    padding-bottom: 15px;
}

/*html.js body div.wrapper div.ariane.container div#shelter22 {
    display: none;      }*/

.left-right-effect::before {
    background-color: #aabed3;
    border-color: #aabed3 -moz-use-text-color #000 #000;
    border-image: none;
    border-style: solid none solid solid;
    border-width: 0;
    content: "";
    display: block;
    height: 30px;
    left: -12px;
    position: absolute;
    top: 0px;
    width: 12px;
}

.left-right-effect::after {
    background-color: #aabed3;
    border-color: #aabed3 #000 #000 -moz-use-text-color;
    border-image: none;
    border-style: solid solid solid none;
    border-width: 0;
    content: "";
    display: block;
    height: 30px;
    position: absolute;
    right: -12px;
    top: 0px;
    width: 12px;
}

.ariane [class^="col-"] > a, .ariane [class^="col-"] > span.active, .ariane [class*=" col-"] > a, .ariane [class*=" col-"] > span.active,
.flfilters [class^="col-"] > a, .flfilters [class^="col-"] > span.active, .flfilters [class*=" col-"] > a, .flfilters [class*=" col-"] > span.active {
    color: #0f5e96;
}

html.js body div.wrapper div.main.container div div.main div.affectation-origine.container div.row div.centre div.results div.tri.grey_line div.row div.col-xs-6 div.triEquipementiers div.btn-group.bootstrap-select button.btn.dropdown-toggle.selectpicker.btn-bootstrap-select span.caret{
    background: rgba(0, 0, 0, 0) url("../../../images/icon_down_arrow.png") no-repeat scroll center center;
    border: medium none;
    height: 11px;
    margin-top: -2px;
    position: absolute;
    right: 12px;
    top: 43%;
    vertical-align: middle;
    width: 16px;
}

html.js body div.wrapper div.search-pieces.container div.row div.col-xs-16 form.form-search-vehicule.form-search-express div.input-group.input-group-lg input#search.form-control.jsSearchAutocomplete.ui-autocomplete-input,
html.js body div.wrapper div.search-pieces.container div.row div.col-xs-14 form.form-search-vehicule.form-search-express div.input-group.input-group-lg input#search.form-control.jsSearchAutocomplete {
    border: 0 solid #ccc;
}

.border_bottom_red:after {
    content: '';
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 5px;
    display: block;
    width: 80px;
    left: 0px;
    border-bottom: 4px solid #d80000;
}

.search-form-peinture .form-control, .search-form-pneu .form-control {
    float: left;
    font-family: "Futura LT Book", sans-serif;
    font-size: 17px;
    font-style: italic;
    height: 54px;
    line-height: 46px;
    width: 578px;
}

.sub-nav-container-peinture.peintures, .sub-nav-container-pneu.pneus {
    background: #0f5e96 none repeat scroll 0 0;
    height: 120px;
    /*    margin-left: 127px;*/
    width: 1000px;
}

.search-pieces .type {
    background-color: transparent;
    color: black;
    display: table;
    font-family: "Futura LT", sans-serif;
    font-size: 15px;
    height: 10px;
    padding: 0 0px;
    text-align: left;
    text-transform: none;
}

.ui-autocomplete.search-pieces-autocomplete .product-family {
    color: #0f5e96;
    font-weight: normal;
    margin-left: 5%;
    margin-bottom: 2%;
    text-transform: lowercase;
}

.ui-autocomplete.search-pieces-autocomplete .ui-autocomplete-category {
    border-bottom: 0px solid #8d8d8d;
    clear: none;
}

.ui-autocomplete.search-pieces-autocomplete .ui-autocomplete-category span {
    /*padding-right: 530px;*/
    padding-left: 0px;
    text-transform: uppercase;
    color: black;
    float: left;
}

html.js body div.wrapper div.search-pieces.container div.row div.col-xs-16 form.form-search-vehicule.form-search-express div.input-group.input-group-lg input#search.form-control.jsSearchAutocomplete.ui-autocomplete-input.has-immatriculation {
    color: #0f5e96;
    text-transform: uppercase;
}

.ui-autocomplete.search-pieces-autocomplete .ui-autocomplete-category span:hover:after,
.ui-autocomplete.search-pieces-autocomplete .ui-autocomplete-category span:after,
.ui-autocomplete .ui-autocomplete-paint span:after {
    content: '';
    margin-top: 10px;
    display: block;
    width: 80px;
    margin-bottom: 8px;
    left: 0px;
    border-bottom: 4px solid #d80000;
}

/*html.js body ul.ui-autocomplete.ui-menu.ui-widget.ui-widget-content.ui-corner-all.search-pieces-autocomplete {
    width: 664px;
    left: 695px;
}*/

.bg-warning {
    background-color: #FFBA00;
}

html.js body div.wrapper div.search-pieces.container div.row div.col-xs-16 form.form-search-modele {
    position: relative;
    background: #0f5e96 none repeat scroll 0 0;
}

.badge {
    background-color: #d80000;
}

.btn-search-pieces {
    background-color: #d80000;
    border-color: #d80000;
    text-transform: uppercase;
}

.form-search-modele .selection-criteres .brand {
    /*height:100px;*/
    /*width:100px;*/
}

.form-search-modele .selection-criteres .brand .change-brand {
    right: 5px;
    top: 3px;
}

.form-search-modele .selection-criteres .btn-search-pieces {
    font-size: 15px;
}

.form-search-modele .selection-criteres .more-criteres, .form-search-modele .selection-criteres .less-criteres {
    bottom: 0px;
    font-family: "Futura LT Heavy", sans-serif;
    font-size: 14px;
    left: 110px;
    position: relative;
    text-transform: none;
}

.bootstrap-select.btn-group .btn .caret, .bootstrap-select.btn-group .btn .sub-nav-container-peinture .sub-nav-links a > .inner > .arrow, .sub-nav-container-peinture .sub-nav-links .bootstrap-select.btn-group .btn a > .inner > .arrow, .bootstrap-select.btn-group .btn .sub-nav-container-peinture .sub-nav-links .item > .inner > .arrow, .sub-nav-container-peinture .sub-nav-links .bootstrap-select.btn-group .btn .item > .inner > .arrow, .bootstrap-select.btn-group .btn .sub-nav-container-peinture .sub-nav-links a > .arrow, .sub-nav-container-peinture .sub-nav-links .bootstrap-select.btn-group .btn a > .arrow, .bootstrap-select.btn-group .btn .sub-nav-container-peinture .sub-nav-links .item > .arrow, .sub-nav-container-peinture .sub-nav-links .bootstrap-select.btn-group .btn .item > .arrow, .bootstrap-select.btn-group .btn .sub-nav-container-peinture .fabricants-list .fabricant.active::after, .sub-nav-container-peinture .fabricants-list .bootstrap-select.btn-group .btn .fabricant.active::after, .bootstrap-select.btn-group .btn .sub-nav-container .filters-tags > li .arrow, .sub-nav-container .filters-tags > li .bootstrap-select.btn-group .btn .arrow, .bootstrap-select.btn-group .btn .news-list .news .tags > li > .arrow, .news-list .news .bootstrap-select.btn-group .btn .tags > li > .arrow {
    background: rgba(0, 0, 0, 0) url("../../../images/icon_down_arrow.png") no-repeat scroll center center;
    border: medium none;
    height: 11px;
    margin-top: -2px;
    position: absolute;
    right: 12px;
    top: 43%;
    vertical-align: middle;
    width: 16px;
}

.bootstrap-select.btn-group .btn .caret, .bootstrap-select.btn-group .btn .sub-nav-container-pneu .sub-nav-links a > .inner > .arrow, .sub-nav-container-pneu .sub-nav-links .bootstrap-select.btn-group .btn a > .inner > .arrow, .bootstrap-select.btn-group .btn .sub-nav-container-pneu .sub-nav-links .item > .inner > .arrow, .sub-nav-container-pneu .sub-nav-links .bootstrap-select.btn-group .btn .item > .inner > .arrow, .bootstrap-select.btn-group .btn .sub-nav-container-pneu .sub-nav-links a > .arrow, .sub-nav-container-pneu .sub-nav-links .bootstrap-select.btn-group .btn a > .arrow, .bootstrap-select.btn-group .btn .sub-nav-container-pneu .sub-nav-links .item > .arrow, .sub-nav-container-pneu .sub-nav-links .bootstrap-select.btn-group .btn .item > .arrow, .bootstrap-select.btn-group .btn .sub-nav-container-pneu .fabricants-list .fabricant.active::after, .sub-nav-container-pneu .fabricants-list .bootstrap-select.btn-group .btn .fabricant.active::after, .bootstrap-select.btn-group .btn .sub-nav-container .filters-tags > li .arrow, .sub-nav-container .filters-tags > li .bootstrap-select.btn-group .btn .arrow, .bootstrap-select.btn-group .btn .news-list .news .tags > li > .arrow, .news-list .news .bootstrap-select.btn-group .btn .tags > li > .arrow {
    background: rgba(0, 0, 0, 0) url("../../../images/icon_down_arrow.png") no-repeat scroll;
    border: medium none;
    height: 11px;
    margin-top: -2px;
    position: absolute;
    right: 12px;
    top: 43%;
    vertical-align: middle;
    width: 16px;
}

.form-search-modele .selection-criteres .selectpicker {
    text-transform: none;
}

.search-pieces .type,
.search-pieces .type.selected {
    background: none;
    box-shadow: none;
    color: black;
    font-family: "Futura LT", sans-serif;
    font-size: 15px;
    font-weight: normal;
    position: relative;
    text-transform: none;
    padding: 10px;
}

.search-pieces .type:hover,
.search-pieces .type:active,
.search-pieces .type.selected
.search-pieces .type.selected:hover,
.search-pieces .type.selected:active {
    background-color: #0f5e96;
    color: #fff;
    width: 100%;
}

.form-search-reference .more-references {
    text-transform: none;
    font-size: 12px;
    position: static;
}

.form-search-reference .less-references {
    font-size: 12px;
    text-transform: none;
    position: static;
}

.form-search-reference {
    position: relative;
    padding: 22px;
}

.form-search-reference .reference input {
    border: 0px solid #196fbd;
}

.form-search-reference .btn {
    font-size: 15px;
}

.icon-search-form {
    background-image: url("../../../images/search_icon.png");
    background-position: 0 0;
    height: 30px;
    line-height: 27px;
    width: 30px;
}

.table-list-products .price {
    color: black;
}

.cart-list-products {
    margin: 10px 0 0;
    padding: 0px;
}

html.js body div.wrapper div.ariane.container div.row div.family > a > img,
html.js body div.wrapper div.ariane.container div.row div.subfamily > a > img,
html.js body div.wrapper div.ariane.container div.row div.subsubfamily > a > img,
html.js body div.wrapper div.ariane.container div.row div.repere > a > img,
html.js body div.wrapper div.flfilters.container div.row div.family > a > img,
html.js body div.wrapper div.flfilters.container div.row div.subfamily > a > img,
html.js body div.wrapper div.flfilters.container div.row div.subsubfamily > a > img {
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -3px;
}

.ariane.container .family {
    box-shadow: -1px 0px 16px rgba(184, 173, 184, 1);
    -webkit-box-shadow: -1px 0px 16px rgba(184, 173, 184, 1);
    -moz-box-shadow: -1px 0px 16px rgba(184, 173, 184, 1);
}

.ariane.container .subfamily,
.ariane.container .subsubfamily {
    box-shadow: 5px 0px 16px rgba(184, 173, 184, 1);
    -webkit-box-shadow: 5px 0px 16px rgba(184, 173, 184, 1);
    -moz-box-shadow: 5px 0px 16px rgba(184, 173, 184, 1);
}

.ariane.container .subfamily .active,
.ariane.container .subsubfamily .active {
    padding: 0;
}

html.js body div.wrapper div.ariane.container div#shelter22 strong.blue_text.FRO,
html.js body div.wrapper div.flfilters.container div#shelter22 strong.blue_text.FRO {
    content: '';
    margin-top: 10px;
    display: block;
    width: 80px;
    left: 0px;
    border-bottom: 4px solid #d80000;
}

.border_bottom_red2:after,
.border_bottom_red2:active:after {
    content: '';
    margin-top: 10px;
    display: block;
    width: 55px;
    left: 0px;
    border-bottom: 3px solid #d80000;
}

.ariane.container div.row div.subfamily span.active span.blue_text,
.ariane.container div.row div.subsubfamily span.active span.blue_text,
.ariane.container div.row div.subfamily a span,
.ariane.container div.row div.subsubfamily a span,
.ariane.container div.row div.family a span,
.ariane.container div.row div.col-xs-3.subfamily span.active span.blue_text,
.ariane.container div.row div.col-xs-3.subsubfamily span.active span.blue_text,
.ariane.container div.row div.col-xs-3.subfamily a span,
.ariane.container div.row div.col-xs-3.subsubfamily a span,
.ariane.container div.row div.col-xs-3.family a span {
    text-align: left;
    padding-left: 12px;
}

.ariane.container div.row div.subfamily a span,
.ariane.container div.row div.subsubfamily a span,
.ariane.container div.row div.family a span{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-right: 30px;
}

.ariane.container div.row div.subfamily span.active,
.ariane.container div.row div.subsubfamily span.active {
    height: 50px;
}

html.js body.scrolled div.wrapper div.main.container div div.main div.affectation-origine.container div.row div.centre div.results div.tri.grey_line div.row div.col-xs-5 div.col-xs-7.triAffectations div.btn-group.bootstrap-select.open div.dropdown-menu.open {
    min-width: inherit;
}

html.js body.scrolled div.wrapper div.main.container div div.main div.affectation-origine.container div.row div.centre div.results div.tri.grey_line div.row div.col-xs-5 div.col-xs-7.triAffectations div.btn-group.bootstrap-select button.btn.dropdown-toggle.selectpicker.btn-bootstrap-select {
    width: inherit;
}

.affectation-origine.container div.row div.centre div.results div.tri.grey_line div.row div.col-xs-6 {
    width: inherit;
}

.triEquipementiers div.btn-group.bootstrap-select button.btn.dropdown-toggle.selectpicker.btn-bootstrap-select {
    width: 234px;
    margin-left: 12px;
}

.triEquipementiers .bootstrap-select.btn-group .dropdown-menu {
    margin-left: 0px;
}

.triEquipementiers .bootstrap-select.btn-group .dropdown-menu ul {
    margin-left: 0px;
}

.triEquipementiers .bootstrap-select.btn-group .dropdown-menu ul li {
    background: #fff;
}

html.js body.scrolled div.wrapper div.main.container div div.main div.affectation-origine.container div.row div.centre div.results div.tri.grey_line div.row div.col-xs-6 div.triEquipementiers div.btn-group.bootstrap-select.open div.dropdown-menu.open {
    min-width: inherit;
    width: 234px;
}

.refequip22 {
    /*      a changer par code de couleur*/
    color: black;
}

.filtres-equip th select#equipementiers.btn.dropdown-toggle.selectpicker.btn-bootstrap-select {
    float: right;
}

.with_pipe {
    border-right: thin solid #fff;
    /*width: 70%;*/
}

.fiche-produit {
    width: 155px;
}

.fiche-produit.no-border,
.fiche-technique.no-border {
    border: none;
}
.no-border{
    border: none !important;
}
.fiche-produit, .fiche-technique {
    border-right: thin solid #D80000;
    float: left;
    height: 30px;
    margin: 10px;
    padding: 0;
    text-align: center;
}

.fiche-technique {
    margin-left : 25px;
}

td.could-have-class-no-border-bottom.left-border div.produit-visuel-container {
    border: thin solid #ccc;
}

html.js body div.wrapper div.search-pieces.container div.row div.col-xs-2.left-right-effect span.logo-immatriculation.jsSearchImmatriculation {
    padding-left: 16px;
    padding-right: 10px;
}

html.js body div.wrapper div.ariane.container,
html.js body div.wrapper div.flfilters.container {
    background: #f7f7f7;
    margin-top: 10px;
}

html.js body div.wrapper div.ariane.container div.row div.col-xs-1.toggle_filter,
html.js body div.wrapper div.flfilters.container div.row div.col-xs-1.toggle_filter {
    float: right;
    height: 70px;
}

html.js body div.wrapper div.ariane.container div.row div.col-xs-1.timer,
html.js body div.wrapper div.flfilters.container div.row div.col-xs-1.timer {
    height: 70px;
    background: #fff;
}

.form-search-vehicule.form-search-express div.input-group.input-group-lg {
    width: 100%
}

html.js body div.wrapper div.search-pieces.container div.row div.col-xs-14 form.form-search-vehicule.form-search-express div.input-group.input-group-lg input#search.form-control.jsSearchAutocomplete {
    height: 36px;
    margin-top: 10px;
}

html.js body div.wrapper div.search-pieces.container div.row div form.form-search-vehicule {
    padding: 22px 22px 10px;
}

.form-search-express .input-group INPUT {
    padding-bottom: inherit;
    padding-top: inherit;
}

html.js body div.wrapper div.header div.header-holder div.container div.nav-holder div.container-fluid ul.nav.row ul.user-action.pull-right li.setting strong ul.dropdown-menu.profile {
    /*display:  block;*/
    padding-left: 10px;
    margin-top: 9px;
}

html.js body div.wrapper div.ariane.container div.row div.col-xs-7.all-families {
    border-bottom: thin solid #e2e2e2;
    white-space: nowrap;
    height: 71px;
    vertical-align: middle;

}

/* Kotso Catalogue */

.overlay {
    background: none;
    background-color: #000;
    opacity: 0.5;
}

.ariane .family .slide .holder,
.ariane .subfamily .slide .holder,
.ariane .subsubfamily .slide .holder {
    width: auto;
}

.ariane .family .holder, .ariane .subfamily .holder {
    width: auto;
}

.ariane .open[class^="col-"] .slide, .ariane .open[class*=" col-"] .slide,
.flfilters .open[class^="col-"] .slide, .flfilters .open[class*=" col-"] .slide {
    width: 1000px;
    position: relative;
}

.ariane .family .slide .holder {
    padding: 0 10px 10px 0;
}

.ariane .subfamily .slide,
.ariane .subsubfamily .slide {
    left: -249px;
}

.famille {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 18%;
    min-height: 200px;
    padding-bottom: 10px;
    margin: 10px -25px 0 35px;
    background-color: #FFF;
    text-align: center;
    box-shadow: 0px 5px 5px 2px #E8E8E8;
}

/* Famille non disponible*/
#familleNonDispo {
    -webkit-filter: grayscale(100%);
    background: #ABACAD;
}

#familleNonDispo span {
    display: inline-block;
    width: 104px;
    height: 135px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-style: solid;
    border-color: #EEE;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    text-align: center;
    margin-bottom: 10px;
}

#familleNonDispo h2 {
    display: block;
    position: absolute;
    bottom: 12px;
    width: 100%;
    background-color: transparent;
    border: none;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
    color: #0f5e96;
}

html.js body div.wrapper div.ariane.container div.row div.family div.famille a {
    display: inline-block;
    width: 104px;
    height: 135px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-style: solid;
    border-color: #EEE;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    text-align: center;
    margin-bottom: 10px;
    cursor: pointer;
}

#catalogue-full-page .famille a {
    display: inline-block;
    width: 104px;
    height: 135px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-style: solid;
    border-color: rgb(230, 230, 230);
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    text-align: center;
    margin-bottom: 10px;
    cursor: pointer;
}

img.js-affiche {
    display: inline-block;
}

img.arrow-fil-ariane-repere {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
}

img.js-cache {
    display: none;
}

html.js body div.wrapper div.ariane.container div.row div.family .holder a img {
    padding-top: 0px;
}

.famille h3 {
    display: block;
    position: absolute;
    bottom: 12px;
    width: 100%;
    background-color: transparent;
    border: none;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
    color: #0f5e96;
    cursor: pointer;
}

.catalogue-product .products .sous-famille .image img {
    width: 100%;
    height: 148px;
    border: 1px solid #ebebeb;
}

.sous-famille {
    display: inline-block;
    vertical-align: top;
    min-height: 205px;
    margin: 0px;
    margin-left: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 209px;

}

html.js body div.wrapper div.ariane.container div.row div.col-xs-4 a.eclate {
    height: auto;
    box-shadow: 0px 1px 1px 0px #E8E8E8;
    border: 0;
}

.ariane .slide .sous-famille .menu {
    background-color: #FFF;
    padding: 0px;
    margin: 0px;
    border: none;
    border-width: 0px;
    box-shadow: -1px 5px 1px 0px #E8E8E8;
    display: flex;
    cursor: pointer;
}

.ariane .sous-famille .menu {
    background-color: #FFF;
    padding: 0px;
    margin: 0px;
    border: none;
    border-width: 0px;
    box-shadow: -1px 5px 1px 0px #E8E8E8;
    display: flex;
    cursor: pointer;
}

.zone-search-plus {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 43px;
    color: $white;
    font-size: 20px;
    background: $blue;
}

.catalogue-product .products .sous-famille h3 {
    position: relative;
    display: inline-block;
    background-color: transparent;
    border: none;
    border-width: 0px;
    width: 132px;
    margin: 0px;
    padding: 0px;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #0f5e96;
    top: 10px;
    left: 10px;
    cursor: pointer;
}

.sous-famille .menu .fleche-bas {
    display: inline-block;
    vertical-align: top;
    background: transparent url('../../../images/fleche_bas.png');
    background-repeat: no-repeat;
    background-position: center center;
    border: none;
    border-width: 0px;
    width: 35px;
    height: 43px;
    margin: 0px;
    padding: 0px;
    cursor: pointer;
}

.sous-famille .menu .loupe-plus {
    display: inline-block;
    vertical-align: top;
    background: #0f5e96 url('../../../images/loupe_plus.png');
    background-repeat: no-repeat;
    background-position: center center;
    border: none;
    border-width: 0px;
    width: 35px;
    height: 43px;
    cursor: pointer;
}

.catalogue-product .products .sous-famille ul {
    display: none;
    background-color: #FFF;
    padding-left: 5px;
    padding-bottom: 5px;
    width: 100%;
    border-style: solid;
    border-color: #E5E5E5;
    border-left-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 2px;
    border-top-width: 0px;
    left: -1px;
    position: relative;
}

.catalogue-product .products .sous-famille ul li {
    background: none;
    margin: 0px;
    padding: 0px;
    font-weight: 500;
}

.catalogue-product .products .sous-famille ul li span.puce {
    background-color: #0f5e96;
    color: #FFF;
    border-width: 1px;
    border-color: transparent;
    border-style: solid;
    border-radius: 50%;
    font-size: 8px;
    width: 17px;
    height: 17px;
    line-height: initial;
    display: inline-block;
    padding-top: 2px;
    padding-left: 0px;
    text-align: center;
    margin-right: 5px;
    font-weight: 300;
}

.defaultPage .centre table.table-commande .supprimer {
    background: rgba(0, 0, 0, 0) url("../../../images/delete_button.png") no-repeat scroll 0 0;
    opacity: 0.92;
    margin-left: -15px;
}

html.js body.scrolled div.wrapper div.main.wireframeTableau div div div.defaultPage.container div.row div.centre.col-xs-20.results.commande div.infos-commande div.row form div.col-xs-7 p div.btn-group.bootstrap-select button.btn.dropdown-toggle.selectpicker.btn-bootstrap-select span.caret{
    background: rgba(0, 0, 0, 0) url("../../../images/icon_down_arrow.png") no-repeat scroll 0 0;
}

.icon-bulle.new{
    background-image: url("../../../images/message_not_empty.png");
    text-indent: 25px;
}

.icon-bulle-vide {
    background-image: url("../../../images/message_empty.png");
    text-indent: 25px;
}
.ajout-favoris{
    background: rgba(0, 0, 0, 0) url("../../../images/star_empty_transparent.png") no-repeat scroll left top;
    padding-top:25px;}

.form-search-express .input-group .logo-immatriculation {
    border-bottom: 0px solid #000;
    border-top: 0px solid #000;
    color: #000;
    display: none;
    height: 30px;
    margin-top: -13px;
    padding-top: 4px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 86px;
    z-index: 45;
    background-color: #e6e6e6;
}

.form-search-express .input-group .logo-immatriculation::after {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #0f5e96;
    border-color: #000 #000 #000 -moz-use-text-color;
    border-image: none;
    border-radius: 0px;
    border-style: none;
    border-width: 0px;
    content: "";
    display: block;
    height: 30px;
    position: absolute;
    right: -12px;
    top: 0px;
    width: 12px;
}

.form-search-express .input-group .logo-immatriculation::before {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #0f5e96;
    border-color: #000 -moz-use-text-color #000 #000;
    border-image: none;
    border-radius: 0px;
    border-style: none;
    border-width: 0px;
    content: "";
    display: block;
    height: 30px;
    position: absolute;
    top: 0px;
    width: 12px;
}

.form-search-express #btnExpress {
    background: 0;
    cursor: pointer;
}

.ui-autocomplete.search-pieces-autocomplete .product-name {
    color: black;
}

html.js body ul.ui-autocomplete.ui-menu.ui-widget.ui-widget-content.ui-corner-all.search-pieces-autocomplete,
html.js body.scrolled ul.ui-autocomplete.ui-menu.ui-widget.ui-widget-content.ui-corner-all.search-pieces-autocomplete {
    width: 664px;
    left: 694px;
}

.pieces-histo {
    color: white;
}

.pieces-histo a {
    padding: 17px 17px 17px 17px;
    background-color: white;
    display: inline-block;
    color: black;
    margin-right: 15px;
    margin-top: 15px;
}

.pieces-histo span {
    padding: 17px 17px 17px 17px;
    background-color: white;
    display: inline-block;
    color: black;
    margin-right: 15px;
    margin-top: 15px;
    opacity: 0.5;
    filter: alpha(opacity=50);
}

.pieces-histo a:hover {
    cursor: pointer;
}

.familles-reperes-list:hover {
    cursor: pointer;
}

.familles-reperes-list {
    margin: 0 0 20px 13px;
    display: inline-block;
    background-color: white;
    width: 150px;
    height: 200px;
    position: relative;
}

.img-familles-reperes {
    color: #0f5e96;
    padding-top: 35px;
    width: 150px;
    height: 200px;
    position: absolute;
    text-align: center;
}

.calque-reperes {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: #186CB2;
    opacity: 0.9;
}

.reperes-uull {
    padding-top: 10px;
    height: 190px;
    top: 0;
    left: 0;
    display: none;
    font-size: 11px;
    width: 100%;
    position: absolute;
    z-index: 3;
    opacity: 1;
    overflow: auto;
}

.reperes-uull .reperes-uull-li {
    margin-top: 10px;
    margin-right: 10px;
    padding-left: 25px;
}

.reperes-uull .reperes-uull-li a {
    color: white;
}

.reperes-uull .reperes-uull-li a strong {
    color: white;
}

.reperes-uull .reperes-uull-li span li {
    color: #BCBBBF;
}

.reperes-uull .reperes-uull-li span li:hover {
    cursor: default;
}

.reperes-uull .famille-uull-li {
    margin: 15px 0 0 0;
    text-align: center;
    color: white;
}

.reperes-uull .famille-uull-li a {
    font-size: 13px;
}

.reperes-uull .famille-uull-li a strong {
    font-size: 13px;
    color: white;
}

.familles-reperes-perso a strong {
    color: #0f5e96;
}

.catalogue-historique a {
    padding: 15px 40px 15px 40px;
    background-color: red;
    border-color: red;
    color: white;
}

.catalogue-historique a strong {
    color: white;
}

.popin .piece_sousFamille ul li a span.puce {
    background-color: #0f5e96;
    color: #FFF;
    border-width: 1px;
    border-color: transparent;
    border-style: solid;
    border-radius: 50%;
    font-size: 8px;
    width: 17px;
    height: 17px;
    line-height: initial;
    display: inline-block;
    padding-top: 2px;
    padding-left: 0px;
    text-align: center;
    margin-right: 5px;
    font-weight: 300;
}

/* Kotso Fiche Vehicule */

#popinFicheVehicule .modal-dialog {
    position: relative;
}

#popinFicheVehicule .modal-body {
    padding: 0px;
}

#popinFicheVehicule div.close {
    width: 30px;
    height: 30px;
    top: -29px;
    right: 1px;
    float: none;
    background-color: #0f5e96;
    position: absolute;
    opacity: 1;
    z-index: 1;
}

#popinFicheVehicule button.close {
    float: none;
    top: 5px;
    right: 3px;
    background: #0f5e96 url('../../../images/close-button.png?1411723840') no-repeat;
    opacity: 1;
}

#popinFicheVehicule .modal-header {
    border-bottom: none;
    padding: 0px;
    min-height: auto;
    height: auto;
    width: 100%;
    background: none;
}

#popinFicheVehicule .immat {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    color: #000;
    font-size: 14px;
    background-color: rgb(230, 230, 230);
    margin: 0px;
    margin-top: 20px;
}

#popinFicheVehicule .cartouche , #Vehicule .cartouche {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: rgb(240, 195, 0);
    margin: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    top: auto;
    right: auto;
    text-align: center;
    .unavailable-service {
        font-size: 15px;
        b {
            font-size: 16px;
        }
    }

}

#popinFicheVehicule .cartouche img {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

#popinFicheVehicule .cartouche p , #Vehicule .cartouche p{
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    width: 90%;
    margin-left: 30px;
    font-size: 13px;
    color: #FFF;
}

#popinFicheVehicule .logo-immat {
    margin: 0px;
    margin-left: 10px;
    display: inline-block;
    padding: 8px;
    border-width: 0px;
    border-left-width: 8px;
    border-right-width: 8px;
    border-color: #0f5e96;
    border-style: solid;
    color: #034E87;
    background-color: #FFF;
}

#popinFicheVehicule .nav-tabs {
    display: flex;
    border: none;
}

#popinFicheVehicule .nav-tabs li {
    text-align: center;
    margin: 0px;
    padding: 10px;
    background-color: #0f5e96;
    width: 20%;
}

#popinFicheVehicule .nav-tabs li.flex{
    width: 100%;
}

#popinFicheVehicule .nav-tabs li.active {
    background-color: transparent;
}

#popinFicheVehicule .nav-tabs li:last-child a {
    border: none;
}

#popinFicheVehicule .nav-tabs li a {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    border-width: 0px;
    border-right-width: 1px;
    border-style: solid;
    border-color: #FFF;
    background: #0F5E96;
    &.disabledUrl {
        pointer-events: none;
    }
}

#popinFicheVehicule .nav-tabs li a .pull-right {
    margin-left: 5px;
}

#popinFicheVehicule .nav-tabs li a.active {
    border-width: 0px;
    border-bottom-width: 2px;
    border-color: #F00;
    border-style: solid;
    width: auto;
    padding-left: 0px;
    padding-right: 0px;
}

#popinFicheVehicule .tab-pane .container {
    padding: 20px;
}

table.carte-identite {
    float: left;
    display: table;
    width: 78% ;
}

table.carte-identite tbody {
    width: 100%;
}

table.carte-identite th {
    color: #0f5e96;
    padding: 6px;
    font-size: 14px;
    font-weight: 600;
}

table.carte-identite td {
    padding: 6px;
    font-size: 12px;
}

#popinFicheVehicule .NbPortes {
    color: #F00;
    background: transparent;
    display: table-cell;
    padding: 6px;
    margin-left: 0px;
}

div.schema-vehicule,
div.print-identity-card {
    float: right;
    width: 186px;
    margin-top: 30px;
    margin-right: 0px;
}

div.schema-vehicule img{
    width: 186px;
}

div.schema-vehicule h2 {
    margin: 0px;
    color: #0f5e96;
    padding: 6px;
    font-size: 14px;
    font-weight: 600;

}

a.print {
    display: block;
    background-color: #EE1C25;
    color: #FFF;
    padding: 10px;
    border: none;
}

a#acces-catalogue {
    display: block;
    background-color: #EE1C25;
    color: #FFF;
    padding: 10px;
    border: none;
    margin-top: 50px;
    margin-left: 20px;
}

.search-pieces {
    background: #0f5e96 none repeat scroll 0 0;
    position: relative;
}

.search-pieces .all-families {
    border-left: 1px solid white;
    border-right: 1px solid white;
    margin-left: 15px;
    padding: 9px 0 10px 10px;
}

.search-pieces .all-families .bloc-vehicle {
    margin-top: 10px;
}

.search-pieces .mec {
    margin-top: 27px;
}

.search-pieces .mec .input-mec {
    color: #000;
    border: 1px solid #fff;
    height: 34px;
}

.search-pieces .mec .input-mec:disabled {
    background-color: #fff;
}

.search-pieces .mec .input-mec-mois {
    margin-left: 10px;
    width: 34px;
}

.search-pieces .mec .input-mec-annee {
    margin-right: 5px;
    width: 50px;
}

.form-search-reference {
    background: #0f5e96 none repeat scroll 0 0;
}

.table-result-search tbody .fiche-vehicule a.active {
    background-color: transparent;
}

.table-result-search tbody .fiche-vehicule .effect_hover a:hover,
.table-result-search tbody .fiche-vehicule .effect_hover a.active:hover {
    background: #f5f5f5 none repeat scroll 0 0;
}

.reference-produits-container .produits {
    padding-left: inherit;
    margin-top: 0px;
}

.reference-produits-container .produits.origin-equivalence {
    padding: 15px;
}

.reference-produits-container .produits.origin-equivalence .selectpicker {
    margin-bottom: 10px;
}

.setting .dropdown-menu li {
    margin: 0px;
    padding-left: 15px;
    float: none;
    position: relative;
    display: block;
}

.setting strong ul.dropdown-menu li.sous_titre {
    margin: 0px;
    padding: 0px;
    text-align: left;
}

.sous_sous_titre {
    padding-left: 10px;
}

html.js body.scrolled div.wrapper div.header div.header-holder div.container div.nav-holder div.container-fluid ul.nav.row ul.user-action li.setting strong ul.dropdown-menu li {
    height: auto;
}

.user-action li {
    height: auto;
}

.setting strong ul.dropdown-menu li.sous_titre {
    text-align: left;
}

html.js body div.wrapper div.search-pieces.container div.row div.col-xs-14 form.form-search-vehicule.form-search-express div.input-group.input-group-lg a.input-group-addon {
    background-color: inherit;
}

.filtres div.btn.btn-default.criteres label span.button-checkbox.bootstrap-checkbox.enable-bootstrap-checkbox button.btn.clearfix.btn-link,
.btn.btn-default.cote label span.button-checkbox.bootstrap-checkbox.enable-bootstrap-checkbox button.btn.clearfix.btn-link,
.btn.btn-default.equipementier label span.button-checkbox.bootstrap-checkbox.enable-bootstrap-checkbox button.btn.clearfix.btn-link,
.filtres div.btn.btn-default.position label span.button-checkbox.bootstrap-checkbox.enable-bootstrap-checkbox button.btn.clearfix.btn-link {
    display: none;
}

html.js body div.wrapper div.main.container div#infos-generales div.row.bloc-historique {
    padding-top: 10px;
}

html.js body div.wrapper div.container.row.background_grey div.profile.col-xs-2.no_white-space {
    border-right: thin solid #ccc;
}

.pieces_1 {
    padding-left: 0;
    left: 140px;
    right: 61px;
}

.active .pieces_1 {
    left: 150px;
}

.pieces-not-active {
    margin-top: 7px;
    padding-left: 7px;
    color: #fff;
    position: absolute;
}

.pieces_1_2 {
    border-right-width: 0;
    height: auto;
    padding-right: 0;
}

.picto_profile {
    top: 12px;
    width: 28px;
}

/* fin  hafedh*/
/* BG pb menu */
.dropdown-menu {
    z-index: 2;
}

.border_bottom_red_180:after {
    content: '';
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 5px;
    display: block;
    width: 180px;
    left: 0px;
    border-bottom: 4px solid #d80000;
}

/* Saï */
.container {
    width: 1000px;
}

.go-to-top {
    background: red url("../../../images/sprite.png") no-repeat scroll -393px -213px  }

.results-search,
div.table-result-search {
    min-height: 190px;
}

.form-search-modele .selection-criteres .less-criteres {
    background-image: url("../../../images/less-references.png");
    background-position: left center;
    background-repeat: no-repeat;
    padding: 2px 0 3px 30px;
}

a.change-brand.white_text:hover,
a.change-brand.white_text:focus {
    color: #fff;
}

.btn-search-pieces:hover, .btn-search-pieces:focus, .btn-search-pieces:active, .btn-search-pieces.active, .open > .btn-search-pieces.dropdown-toggle {
    background-color: red;
}

div.table-result-search table.table.table-hover.table-bordered.table-result-search tbody.tbodyc tr.error-fiche-vehicule td div.row div.col-xs-19.text {
    background: rgba(0, 0, 0, 0) url("../../../images/info_red.png") no-repeat scroll left center; }

.affectation-origine .filtreDateMec .resetDate {
    background-color: red;
}

/* End Saï */

/* BG Filters */
/*.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open > .btn-default.dropdown-toggle {
    background-color: #0F5E96;
    border: none;
    color: #FFF;
}*/
div.btn.btn-default.criteres.active, div.btn.btn-default.criteres.active,
.btn.btn-default.criteres:hover, .btn.btn-default.criteres:focus, .btn.btn-default.criteres:active,
div.btn.btn-default.cote.active, div.btn.btn-default.position.active, div.btn.btn-default.equipementier.active,
.btn.btn-default.equipementier:hover, .btn.btn-default.equipementier:focus, .btn.btn-default.equipementier:active,
.btn.btn-default.position:hover, .btn.btn-default.position:focus, .btn.btn-default.position:active,
.btn.btn-default.cote:hover, .btn.btn-default.cote:focus, .btn.btn-default.cote:active, .open > .btn-default.dropdown-toggle {
    background-color: #0F5E96;
    border: none;
    color: #FFF;
}

.btn.cote, .btn.position, .btn.criteres, .btn.equipementier {
    padding: 0;
    margin-bottom: 10px;
}

div.position, div.cote, div.criteres, div.equipementier {
    box-shadow: 1px 1px 1px grey;
    color: #0F5E96;
}

div.position.active, div.cote.active, div.criteres.active, div.equipementier.active {
    box-shadow: 1px 1px 1px grey;
    color: #FFF;
}

div.position label, div.cote label, div.criteres label, div.equipementier label {
    padding: 6px 12px;
    cursor: pointer;
    text-transform: none;
    font-style: italic;
    font-weight: bold;
    margin: 0;
}

/* END BG filters */

#toggles {
    width: 60px;
}

.ios-toggle, .ios-toggle:active {
    position: absolute;
    top: -5000px;
    height: 0;
    width: 0;
    opacity: 0;
    border: none;
    outline: none;
}

.checkbox-label {
    display: block;
    position: relative;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 16px;
    width: 85%;
    height: 20px;
    /*border-radius*/
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    border-radius: 18px;
    background: #bfbfbf;
    cursor: pointer;
}

.checkbox-label:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    line-height: 34px;
    text-indent: 40px;
    height: 20px;
    width: 20px;
    /*border-radius*/
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    top: 0px;
    left: 0px;
    right: auto;
    background: #0f5e96;
    /*box-shadow*/
    -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, .2), 0 0 0 2px #dddddd;
    -moz-box-shadow: 0 3px 3px rgba(0, 0, 0, .2), 0 0 0 2px #dddddd;
    box-shadow: 0 3px 3px rgba(0, 0, 0, .2), 0 0 0 2px #dddddd;
}

.checkbox-label:after {
    content: attr(data-on);
    display: block;
    position: absolute;
    z-index: 0;
    top: -7px;
    left: -300px;
    padding: 10px;
    height: 100%;
    width: 300px;
    text-align: right;
    color: #0f5e96;
    white-space: nowrap;
}

/* Toggle switch blanc */
.checkbox-label-white {
    position: relative;
    display: inline;
    width: 60px;
    height: 34px;
}

.checkbox-label-white input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 0px;
    bottom: -7px;
    background-color: white;
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, .2), 0 0 0 2px #ffffff;
    -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, .2), 0 0 0 2px #ffffff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .2), 0 0 0 2px #ffffff;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #fff;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
}

.slider.round {
    border-radius: 34px;
    height: 5px;
    width: 45px;
    margin-top: 5px;
}

.slider.round:before {
    border-radius: 50%;
}

.ios-toggle:checked + .checkbox-label {
    /*box-shadow*/
    -webkit-box-shadow: inset 0 0 0 26px #0f5e96, 0 0 0 2px #0f5e96;
    -moz-box-shadow: inset 0 0 0 26px #0f5e96, 0 0 0 2px #0f5e96;
    box-shadow: inset 0 0 0 26px #0f5e96, 0 0 0 2px #0f5e96;
}

.ios-toggle:checked + .checkbox-label:before {
    left: calc(100% - 20px);
    /*box-shadow*/
    -webkit-box-shadow: 0 0 0 2px transparent, 0 3px 3px rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 0 0 2px transparent, 0 3px 3px rgba(0, 0, 0, .3);
    box-shadow: 0 0 0 2px transparent, 0 3px 3px rgba(0, 0, 0, .3);
}

.ios-toggle:checked + .checkbox-label:after {
    content: attr(data-off);
    left: 42px;
    width: 20px;
}

/* GREEN CHECKBOX */

#checkbox1 + .checkbox-label {
    -webkit-box-shadow: inset 0 0 0 0px #0f5e96, 0 0 0 2px #dddddd;
    -moz-box-shadow: inset 0 0 0 0px #0f5e96, 0 0 0 2px #dddddd;
    box-shadow: inset 0 0 0 0px #0f5e96, 0 0 0 2px #dddddd;
}

#checkbox1:checked + .checkbox-label {
    /*box-shadow*/
    /*    -webkit-box-shadow:inset 0 0 0 18px #0f5e96,0 0 0 2px #dddddd;
        -moz-box-shadow:inset 0 0 0 18px #0f5e96,0 0 0 2px #dddddd;
        box-shadow:inset 0 0 0 18px #0f5e96,0 0 0 2px #dddddd;*/
}

#checkbox1:checked + .checkbox-label:after {
    color: #797979;
    font-weight: bold;
}

body {
    background-color: #e6e6e6;
    min-height: 100%;
}

html {
    height: 100%;
}

/* bloc dispo */

.icon-disponibilite-info-bulle{
    background-image: url('../../../images/icon-dispo-info.png');
    background-repeat: no-repeat;
    margin-left: 4px;
    position: relative;
    height: 15px;
    width: 15px;
}

.icon-calendrier{
    background-image: url('../../../images/icon-calendrier.png');
    background-repeat: no-repeat;
    padding-left: 2px;
    position: relative;
    height: 15px;
    width: 15px;
}

.dispo-details .tooltip-new {
    position: absolute;
    display: none;
}

.dispo-details .tooltip-new .tooltip-inner {
    text-align: left;
}

.dispo-details .dispo-info-bulle:hover .tooltip-new {
    display: block;
}

.tooltip-new-arrow {
    border: 0;
    /*    background: url('/autossimo//img/triangle-bottom.png?1419263320') no-repeat;*/
    background: rgba(0, 0, 0, 0) url("../../../images/triangle-bottom.png?1419263320") no-repeat scroll 0 0 / 25px auto;
    /*    width: 14px;*/
    height: 15px;
    /*    margin: -3px 34px 0 0;*/
    margin: 4px -7px 0;
    z-index: 50;
}

.tooltip-new-inner {
    position: relative;
    z-index: 30;
    border: 1px solid rgb(156, 197, 86);
    border-top: 0px solid #fff;
    /*    border: 1px solid rgb(97, 195, 32);*/
    max-width: 370px;
    padding: 10px 10px 10px 10px;
    color: #565656;
    text-align: center;
    text-decoration: none;
    background-color: #FFF;
    border-radius: 0;
}

.dispo-details.dispoA input {
    background: rgba(0, 0, 0, 0) url("../../../images/icon-calendrier.png") no-repeat scroll 70px center !important;
    border: medium none;
    cursor: pointer;
    padding-top: 2px;
    margin-right: -18px;
}

/* BG fil ariane date mec */
.filtresmec div.btn.btn-default.activermec label span.button-checkbox.bootstrap-checkbox.enable-bootstrap-checkbox button.btn.clearfix.btn-link {
    display: none;
}

div.filtresmec {
    width: 200px;
}

div.filtresmec input.mois {
    width: 25px;
    height: 30px;
}

div.filtresmec input.annee {
    width: 40px;
    height: 30px;
}

.filtres-label {
    display: inline-block;
    margin-bottom: 4px;
    font-weight: bold
}

#showFilters i {
    background-image: url(../../../images/picto_blue_eye.png);
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 160px;
    cursor: pointer;
}

#hideFilters i {
    background-image: url("../../../images/hide.png");
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 0;
    left: 130px;
}

div.btn.btn-default.activermec.active,
.btn.btn-default.activermec:hover, .btn.btn-default.activermec:focus, .btn.btn-default.activermec:active {
    background-color: $red;
    border: none;
    color: #FFF;
}

.btn.activermec {
    padding: 0;
}

div.activermec {
    box-shadow: 1px 1px 1px grey;
    color: #0F5E96;
}

div.activermec.active {
    box-shadow: 1px 1px 1px grey;
    color: #FFF;
}

div.activermec label {
    padding: 8px 12px;
    cursor: pointer;
    text-transform: none;
    font-style: italic;
    font-weight: bold;
    margin: 0;
}

div.activermec button {
    display: none;
}

#formfiltrermec .filtresmec .triEquipementiers .activermec {
    margin-right: 15px;
    margin-bottom: 15px;
}
#formfiltrermec .filtresmec .triEquipementiers .activermec button {
    display: inline-block;
}
#shelter22 .btn-classic {
    width: 34px;
    height: 34px;
    padding: 5px 5px;
    background-color: #e2e2e2;
    position: absolute;
    bottom: 15%;
    right: 15px;
}

#shelter22 .btn-classic .tooltiptext {
    visibility: hidden;
    font-size: 12px;
    text-transform: uppercase;
    padding: 10px 8px;
    color: #fff;
    cursor: pointer;
    background-color: #b2b2b2;
    position: relative;
    left: -110px;
    top: -30px;
    display: inline-block;
    width: 100px;
    height: 34px;
}

#shelter22 .btn-classic .tooltiptext::after {
    content: '';
    background-image: url('../../../images/caret_right_grey.png');
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 10px;
    right: -10px;
}

#shelter22 .btn-classic:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

/* fin BG fil ariane date MEC */
a.action-open-fiche-vehicule.active {
    height: 5px;
    line-height: 5px;
}

/* hA */
.reference-produits-container .produits .complements-produit .list-family-associate {
    float :left;
    margin: 10px 10px 10px -10px;
    max-width: 600px;
}

.reference-produits-container .produits .complements-produit .list-family-associate a {
    color : #0f5e96;
    margin: 8px 3px 0;
}

div.produits table.table-condensed tbody tr.famille-associee-content {
    background: #fff;
}

html.js body.scrolled div.wrapper div.main.container div.main div.affectation-origine div.row div.centre div.results div#refEquip-391006802R.result div.main.container div.references-produit-list div.reference-produits-container div.bloc-reference div div.produits table.table-condensed tbody tr.famille-associee-content td table tbody tr td a,
tr.famille-associee-content td table tbody tr td a {
    color: inherit;
}

tr.famille-associee-content td table {
    margin-top: 5px;
    background: #E6E6E6;
    width: 100%;
    color: #0f5e96;
}

.reference-produits-container .produits > table .famille-associee-content a, .reference-produits-container .produits > table .fiche-technique-content a {
    color: #0f5e96;
    text-decoration: none;
}

.reference-produits-container .produits > table .famille-associee-content a:hover, .reference-produits-container .produits > table .fiche-technique-content a:hover {
    text-decoration: underline;
}

.wrap-fiche-technique {
    width: 600px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.reference-produits-container .produits > table .famille-associee-content > td {
    border-right: 1px solid #ccc;
}

.famillessss {
    background: #ccc;
}

.piece_sousFamille ul li {
    line-height: 13px;
    margin-top: 5px;
}

/* hA */
.ui-autocomplete.search-pieces-autocomplete .product-3col .product-family {
    width: 40%;
    font-weight: lighter;
}

.ui-autocomplete.search-pieces-autocomplete .product-3col .product-family,
.ui-autocomplete.search-pieces-autocomplete .product-ref.product-ref-re,
.ui-autocomplete.search-pieces-autocomplete .product-ref.product-ref-ro {
    font-family: inherit;
}

a.product-3col.ui-corner-all span.product-ref.product-ref-re span {
    background: rgb(255, 255, 255) none repeat scroll 0% 0%;
    color: red;
    border: 1px solid red;
    padding: 5px 7px;
}

a.product-3col.ui-corner-all span.product-ref.product-ref-ro span {
    background: rgb(255, 255, 255) none repeat scroll 0% 0%;
    color: rgb(15, 94, 150);
    border: 1px solid rgb(15, 94, 150);
    padding: 5px 7px;
}

.ref22 {
    margin-top: -13px;
    padding-left: 30px;
    color: #000;
    text-transform: capitalize;
}

a.product-3col.ui-corner-all span.product-family:first-letter {
    text-transform: uppercase;
}

.ui-autocomplete.ui-menu.ui-widget.ui-widget-content.ui-corner-all.search-pieces-autocomplete {
    padding-top: 20px;
}

/*modal popin fiche liaison*/
.popinFicheLiaison .modal-header {
    min-height: 16.42857px;
    font-family: "Gotham Medium", sans-serif;
    font-size: 14px;
    background: #0f5e96;
    width: 660px;
    border: none;
}

.popinFicheLiaison .modal-header .close {
    margin-top: -2px;
}

/*fishe liaison*/
.popinFicheLiaison textarea {
    color: inherit;
    font: inherit;
    margin-left: 8px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    padding: 0 15px;
}

.popinFicheLiaison textarea {
    overflow: auto;
}

.popinFicheLiaison .modal-dialog {
    /* width: 900px; */
    width: 660px;
}

#popinFicheLiaison .modal-dialog .modal-header {
    border-bottom: none;
    padding: 10px 15px 10px 20px;
}

.popinFicheLiaison .modal-dialog .modal-header .close {
    position: static;
}

.popinFicheLiaison button.close {
    padding: 0;
    cursor: pointer;
    border: 0;
    -webkit-appearance: none;
    background: url("../../../images/close_button_white.png") no-repeat center center;
    height: 20px;
    width: 20px;
}

.popinFicheLiaison .modal-dialog .modal-body {
    padding: 0 20px 20px 20px;
    min-height: 125px;
}

.popinFicheLiaison h1 {
    display: inline-block;
    font-family: 'Gotham Medium', sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    color: #ffffff;
}

.popinFicheLiaison [data-ouijai] h1 {
    color: #fff;
}

.popinFicheLiaison h2 {
    font-size: 12px;
    color: #0f5e96;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.popinFicheLiaison p {
    font-size: 12px;
    font-family: 'Gotham Book', sans-serif;
}

.popinFicheLiaison .notes_red {
    font-family: 'Gotham Book', sans-serif;
    font-style: italic;
    color: #e10f21;
    font-size: 11px;
    padding-right: 15px;
    float: right;
}

.popinFicheLiaison ul {
    font-size: 14px;
    padding: 0;
}

.popinFicheLiaison ul li {
    list-style: none;
    background: url('../../../images/puce-popin.png?1413461480') no-repeat left center; }
.popinFicheLiaison  ul a {
    color: #565656;
    padding-left: 10px;
    border-bottom: 1px solid #565656;
}

.popinFicheLiaison input[type="text"] {
    border: 1px solid #ccc;
    padding: 0 11px 0 13px;
    height: 37px;
    width: 175px;
    margin-left: 5px;
    font-style: italic;
    color: #333333;
}

.popinFicheLiaison label {
    clear: both;
    float: left;
    width: 150px;
    font-size: 11px;
    text-transform: uppercase;
    color: #8c8c8c;
}

.popinFicheLiaison .sectionFL {
    display: table;
    width: 620px;
    height: 25px;
    margin-top: 15px;
    border-bottom: 2px solid #ccc;
}

.popinFicheLiaison .headerSection {
    display: table-cell;
    width: 350px;
    vertical-align: bottom;
}

/*classes Deplie Plie/ Fiche Liaison*/
/*media all*/
.popinFicheLiaison .btnDepliePlie {
    display: table-cell;
    float: right;
    width: 460px;
    vertical-align: bottom;
}

.popinFicheLiaison .deplie {
    background: url("../../../images/arrow_down_red.jpg") no-repeat center;
    height: 20px;
    width: 20px;
    border: none;
    margin-top: 15px;
    margin-bottom: 0;
    float: right;
}

.popinFicheLiaison .plie {
    background: url("../../../images/arrow_up_red.jpg") no-repeat center;
    height: 20px;
    width: 20px;
    border: none;
    margin-top: 15px;
    margin-bottom: 0;
    float: right;
}

.popinFicheLiaison .modal-dialog {
    margin: 150px auto;
    min-height: 30px;
}

.popinFicheLiaison .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.popinFicheLiaison .form_element {
    margin-bottom: 5px;
}

.popinFicheLiaison .form_element label {
    display: inline-block;
    padding-top: 5px;
}

.popinFicheLiaison .form_element input {
    display: inline-block;
    width: 200px;
}

.popinFicheLiaison .form_element input.fullsize {
    width: 465px;
    height: 30px;
}

.popinFicheLiaison .form_element input:disabled.fullsize {
    border: none;
    background-color: #ffffff;
    margin-left: 0;
}

.popinFicheLiaison .form_element textarea.fullsize {
    width: 465px;
    margin-bottom: 5px;
}

.popinFicheLiaison .form_fiche_liaison .form_element label {
    width: 180px;
}

.popinFicheLiaison .form_fiche_liaison .form_element.inputradio label {
    float: none;
    width: 96%;
}

.popinFicheLiaison .form_fiche_liaison .form_element.inputradio:after {
    display: block;
}

.popinFicheLiaison .form_fiche_liaison .form_element.inputradio input {
    float: none;
    margin-right: 5px;
    width: auto;
}

.popinFicheLiaison .observation {
    width: 630px;
    display: table;
    height: auto;
    padding-top: 15px;
}

.popinFicheLiaison .labelObs {
    display: table-cell;
    vertical-align: top;
}

.popinFicheLiaison .inputradioObs {
    width: 460px;
    display: table-cell;
    float: right;
    margin-right: 15px;
}

.popinFicheLiaison .form_fiche_liaison textarea.fullsize {
    width: 400px;
    margin-bottom: 5px;
}

.popinFicheLiaison .form_fiche_liaison .form_element.inputradio label {
    float: none;
    width: 96%;
}

.popinFicheLiaison .form_element .fullsize {
    font-style: italic;
    color: #333333;
    font-size: 12px;
}

.popinFicheLiaison .fullsize {
    font-style: italic;
    color: #333333;
    font-size: 12px;
}

.popinFicheLiaison .popinFicheLiaison .modal-body {
    width: 660px;
    padding: 0 70px 20px 50px;
}

.popinFicheLiaison .popinFicheLiaison label {
    font-size: 14px;
}

.popinFicheLiaison .popinFicheliaison .close {
    z-index: -1;
}

/*media all*/
.popinFicheLiaison fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    min-width: 0px;
}

.popinFicheLiaison .form_value {
    padding-top: 5px;
    display: inline-block;
}

.popinFicheLiaison .form_fiche_liaison .form_element.inputradio label {
    float: none;
    width: 96%;
}

.popinFicheLiaison .form_fiche_liaison .form_element.inputradio label:after {
    display: block;
}

.popinFicheLiaison .form_fiche_liaison .form_element.inputradio input {
    float: none;
    margin-right: 5px;
    width: auto;
}

/*from pubtech-main.css*/
/* PT Fiche liaison */
.popinFicheLiaison .form_fiche_liaison .form_element label {
    width: 150px
}

.popinFicheLiaison .form_element input {
    width: 200px
}

.popinFicheLiaison .form_element input.fullsize {
    color: #333333;
    width: 460px;
    float: right;
    margin-top: 5px;
}

.popinFicheLiaison .form_fiche_liaison textarea.fullsize {
    color: #333333;
    width: 460px;
    margin-bottom: 5px;
    margin-top: 5px;
    float: right;
}

.popinFicheLiaison input[type="text"] {
    width: 460px;
    float: right;
}

.popinFicheLiaison .form_element input.fullsize {
    width: 460px;
}

.popinFicheLiaison .form_value{
    padding-top: 7px;
    display: inline-block;
    font-style: italic;
    font-size: 12px;
    height: 20px;
    color: #333333;
}

.popinFicheLiaison .form_fiche_liaison .form_element.inputradio label {

    font-style: italic;
    font-size: 12px;
    color: #333333;
    float: none;
    width: auto;
}

.popinFicheLiaison .form_fiche_liaison .form_element.inputradio label:after {
    display: block;
}

.popinFicheLiaison .form_fiche_liaison .form_element.inputradio input {
    float: none;
    margin-left: 15px;
    width: auto;
}

.popinFicheLiaison .form_element .inputradio {
    width: 460px;
    float: right;
    margin-right: 10px;
}

.popinFicheLiaison .btn-action {
    text-align: center;
    margin-top: 20px;
    float: right;
}

.popinFicheLiaison {
    .form_fiche_liaison {
        .form_element {
            label[for="immatFl"] {
                width: 75%;
                text-transform: initial;
                margin-top: 10px;
                font-size: 12px;
                font-family: $family-gotham-sans-serif;
                color: $grey56;
            }
            #immatFl {
                float: left;
                margin-bottom: 10px;
                margin-left: 0px;
            }
        }
        .immat_fl_error {
            margin-right: 460px;
        }
    }
}

.popinFicheLiaison input[type='reset'], .resetbutton {
    background-color: #b2b2b2;
    color: #fff;
    margin: 0 0 0 15px;
    padding: 5px 15px;
    border: none;
    font-size: 12px;
}

/*media all*/
.popinFicheLiaison input[type='reset'] {
    background: #b2b2b2;
}

.popinFicheLiaison input[type='submit'] {
    background: #e10f21;
}

.popinFicheLiaison input[type='reset'], input[type='submit'] {
    margin-left: 15px;
    color: #fff;
    display: inline-block;
    border: none;
    font-size: 12px;
    text-transform: uppercase;
    /*background-color:red;*/
    height: 28px;
    padding: 0 11px 0 11px;
}

.btn-action .popin-btn-valid {
    background-color: red;
}

.popinFicheLiaison button, input[type='button'], input[type='reset'], input[type='submit'] {
    -webkit-appearance: button;
    cursor: pointer;
}

/*media all*/
.popinFicheLiaison .clearfix::after {
    clear: both;
}

/* end modal popin fiche liaison*/

/*media all*/
.clearfix::before {
    content: " ";
    display: table;
}

.label_result_origin {
    color: #000;
}

.affectation-origine .tri {
    height: 30px;
    line-height: 30px;
    background-color: #e6e6e6;
}

.affectation-origine .tri .sort-by {
    color: #6E6E6E;
    text-align: center;
}

.affectation-origine .tri .arrow {
    cursor: pointer;
    height: 20px;
    display: inline-block;
    margin: 0 15px 0 5px;
}
#popinVehicleModelSelection {
    margin-top: 150px;
}
.affectation-origine .bared_time {
    content: " ";
    background-color: #0f5e96;
    background-image: url("../../../images/picto_temps_bareme.png");
    background-position: left;
    background-position-x: 8px;
    background-position-y: 6px;
    background-repeat: no-repeat;
    color: #fff;
    display: inline-block;
    font-family: "Futura LT", sans-serif;
    padding: 0px 15px 0px 35px;
    cursor: pointer;
}
.affectation-origine .sort-separator {
    background-color: #e6e6e6;
    height: 10px;
}

.affectation-origine .origin_piece,
.affectation-origine .label_assignment,
.label_dates_affectation_piece {
    margin-top: 15px;
}

.affectation-origine .caracteristic_piece,
.affectation-origine .assignment,
.dates_affectation_piece {
    margin-top: 10px;
}

.affectation-origine .assignment {
    font-weight: bold;
}

.affectation-origine .detail_criteria_piece {
    margin-top: 10px;
}

.affectation-origine .criteria_piece {
    margin-top: 15px;
}

.detail_criteria_piece .detail {
    background: #0f5e96 none repeat scroll 0 0;
    color: #fff;
    display: inline-block;
    font-family: "Futura LT", sans-serif;
    margin-right: 2px;
    margin-top: 2px;
    padding: 5px;
    text-align: center;
    font-size: 10px;
}

.affectation-origine .detail_piece .blue_border_left {
    border-left: solid 5px #0f5e96;
    padding-bottom: 10px;
}

.affectation-origine .border_shadow {
    box-shadow: 5px 0px 16px rgba(184, 173, 184, 1);
    -webkit-box-shadow: 5px 0px 16px rgba(184, 173, 184, 1);
    z-index: 1;
}

.choix-equipementiers {
    text-align: right;
    padding: 0px;
}

.references-produit-list .hide_detail,
.choix-equipementiers .lienRefRoRe {
    cursor: pointer;
    display: block;
    float: right;
    color: #FFF;
    background: #0f5e96 none repeat scroll 0 0;
    padding: 0px 10px 0 21px;
    margin-left: 15px;
}

.references-produit-list .hide_detail {
    width: 185px;
    margin-right: -15px;
    padding: 7px 0px 7px 13px;
    text-align: center;
}

.affectation-origine .refequip22 {
    font-weight: bold;
}

.affectation-origine .refequip22 .complement-piece-origine{
    font-weight: normal;
}

.rotated_180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.choix-equipementiers .lienRefRoRe span {
    margin-right: 40px;
}

.choix-equipementiers > p {
    background-color: #f7f7f7;
    height: 30px;
    line-height: 30px;
    margin: 0;
}

.popinTempsBaremes .modal-dialog {
    top: 0px !important;
}

.popin .popinTempsBaremes .modal-body {
    padding: 0 30px 20px;
}

.popin .modal-dialog .modal-header {
    padding: 10px 30px 10px 15px;
}

.popin .table {
    border-bottom: 2px solid #c2c2c2;
}

.showme {
    display: none;
}

.showit:hover .showme {
    display: block;
}

/*fiches de liaison*/
.fiche-button-checkbox [type="checkbox"]:not(:checked), .fiche-button-checkbox [type="checkbox"]:checked {
    left: -9999px;
    position: absolute;
}

.fiche-button-checkbox [type="checkbox"]:not(:checked) + label, .fiche-button-checkbox [type="checkbox"]:checked + label {
    cursor: pointer;
    padding-left: 25px;
    position: relative;
}

.fiche-button-checkbox [type="checkbox"]:not(:checked) + label::before, .fiche-button-checkbox [type="checkbox"]:checked + label::before {
    background: #f8f8f8 none repeat scroll 0 0;
    border: 1px solid #aaa;
    content: "";
    height: 17px;
    left: 1px;
    position: absolute;
    top: 4px;
    width: 17px;
}

.fiche-button-checkbox [type="checkbox"]:not(:checked) + label::after, .fiche-button-checkbox [type="checkbox"]:checked + label::after {
    color: #045E9D;
    content: "\2713";
    font-size: 18px;
    left: 3px;
    line-height: 0.8;
    position: absolute;
    top: 6px;
    transition: all 0.2s ease 0s;
}

.fiche-button-checkbox [type="checkbox"]:not(:checked) + label::after {
    opacity: 0;
    transform: scale(0);
}

.fiche-button-checkbox [type="checkbox"]:checked + label::after {
    opacity: 1;
    transform: scale(1);
}

.fiche-button-checkbox [type="checkbox"]:disabled:not(:checked) + label::before, .fiche-button-checkbox [type="checkbox"]:disabled:checked + label::before {
    background-color: #ddd;
    border-color: #bbb;
    box-shadow: none;
}

.fiche-button-checkbox [type="checkbox"]:disabled:checked + label::after {
    color: #999;
}

.fiche-button-checkbox [type="checkbox"]:disabled + label {
    color: #aaa;
}

.fiche-button-checkbox [type="checkbox"]:checked:focus + label::before, .fiche-button-checkbox [type="checkbox"]:focus:not(:checked) + label::before {
    border: 1px dotted blue;
}

.fiche-button {
    width: 180px;
    color: white;
    padding: 10px;
    font-size: 17px;
    border: none;
    display: inline-block;
}

.fiche-button-checkbox label {
    margin-bottom: 16px;
}

.fiche-top-buttons {
    display: inline-block;
    margin-top: 21px;
}

.fiche-title h4 {
    height: 50px;
    border: none;
    padding-left: 25px;
    background: white;
    color: #025E9D;
    display: inline;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 17px;
    font-weight: bold;
}

.fiche-title {
    height: 71px;
    line-height: 71px;
    background: white;
    margin-top: 38px;
}

.fiche-title img {
    height: 20px;
    width: 25px;
}

.fiche-message-content {
    text-align: left;
    min-height: 71px;
    background: white;
    padding: 20px;
    overflow: hidden;
}

.fiche-message-title {
    display: block;
    text-align: left;
    font-weight: normal;
}

.fiche-message-header {
    height: 50px;
    background: #045E9D;
}
.fiche-message-header:hover {
    background: $white;
    .fiche-message-header-text , span.divider{
        color: $blue;
    }
}

.fiche-message-content div.text-bold {
    font-weight: bold;
}

.fiche-message-content span.badge {
    position: absolute;
    top: -5px;
    right: -8px;
    font-size: 12px;
    padding: 1px 2px;
    min-width: 19px;
    background-color: #d80000;
    display: inline-block;
    line-height: 17px;
}

.fiche-message-content img.picto_lettre {
    margin-top: -5px;
    vertical-align: middle;
}

.fiche-message-content a {
    display: inline-block;
    position: relative;
}

.fiche-message div.delete-button {
    padding-right: 4px;
    text-align: right;
}

.fiche-message div.fiche-message-header-text {
    color: white;
    font-size: 14px;
    padding-top: 18px;
}
.fiche-message div.fiche-message-header-text-middle {
    color: white;
    font-size: 14px;
    padding-top: 7px;
}
.fiche-message span.divider{
    color: white;
    position: relative;
    left: 16px;
}

.fiche-message div.no-padding-left {
    padding-left: 0px;
}

.fiche-message div.no-padding-right {
    padding-right: 0px;
}

.fiche-message div.no-padding-on-sides {
    padding-left: 0px;
    padding-right: 0px;
}

.no-padding-left {
    padding-left: 0px;
}

.no-padding-right {
    padding-right: 0px;
}

.no-padding {
    padding-left: 0px;
    padding-right: 0px;
}

.no-margin-left {
    margin-left: 0px;
}

.no-margin-right {
    margin-right: 0px;
}

.no-margin-bottom {
    margin-bottom: 0px;
}

.no-margin {
    margin-left: 0px;
    margin-right: 0px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.underline {
    text-decoration: underline;
}

.relative {
    position: relative;
}

.fiche-image-container {
    background: #EDEBEC;
    display: inline;
    height: 71px;
    width: 73px;
    float: right;
    text-align: center
}

.fiche-message-content:hover {
    cursor: pointer
}

/*end of fiches de liaison*/

.form-search-modele .list .items ul a {
    font-size: 10px;
}

.moins_circle {
    background-color: #004179;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    font-weight: normal;
    line-height: 13px;
    min-width: 10px;
    padding: 2px 5px;
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;
    border: 1px solid #fff;
}

.form-search-modele .selection-criteres .less-criteres {
    background-image: none;
}

.modal-body .btn-action a {
    background-color: red;
}

.suivi .search-form .btn-action input[type="submit"] {
    float: left;
}

/* ba33 */
div#popinFicheProduit.modal.fade.fiche-vehicule.in div.modal-dialog {
    width: 1050px;
    height: auto;
}

.piece_layout  div#popinFicheProduit.modal.fade.fiche-vehicule.in div.modal-dialog {
    margin-top: 0px;
}

div#popinFicheProduit.modal.fade.fiche-vehicule.in div.modal-dialog div.modal-content div.modal-header {
    background: #0f5e96;
}

div#popinFicheProduit.modal.fade.fiche-vehicule.in div.modal-dialog div.modal-content div.modal-header,
div#popinFicheProduit.modal.fade.fiche-vehicule.in div.modal-dialog div.modal-content div.modal-header ul.nav.nav-tabs {
    border-bottom-width: 0px;
}

div#popinFicheProduit.modal.fade.fiche-vehicule.in div.modal-dialog div.modal-content div.modal-header ul.nav.nav-tabs li,
div#popinFicheProduit.modal.fade.fiche-vehicule.in div.modal-dialog div.modal-content div.modal-header ul.nav.nav-tabs li.active {
    margin: -15px 0 -15px -15px;
}

div#popinFicheProduit.modal.fade.fiche-vehicule.in div.modal-dialog div.modal-content div.modal-header ul.nav.nav-tabs li.active a.border_bottom_red,
div#popinFicheProduit.modal.fade.fiche-vehicule.in div.modal-dialog div.modal-content div.modal-header ul.nav.nav-tabs li.active a.border_bottom_red.active {
    text-decoration: none;
}

div#popinFicheProduit.modal.fade.fiche-vehicule.in div.modal-dialog div.modal-content div.modal-header ul.nav.nav-tabs li span.close a {
    color: #fff;
    padding: 20px 35px;
}

div#popinFicheProduit.modal.fade.fiche-vehicule.in div.modal-dialog div.modal-content div.modal-header ul.nav.nav-tabs li span.close {

    margin-top: 20px;
}

div#popinFicheProduit.modal.fade.fiche-vehicule.in div.modal-dialog div.modal-content div.modal-header ul.nav.nav-tabs {
    text-transform: uppercase;
}

.fiche-top-buttons {
    display: inline-block;
    margin-top: 21px;
}

.fiche_produit {
    color: #fff;
}

.compatibilite {
    margin-left: 13px;
    color: #fff;
}

.prix_ht_ttc {
    border-bottom: thin solid #ccc;
    margin-bottom: 15px;
    margin-top: 15px;
    padding-bottom: 15px;
    padding-top: 15px;
}

.product div.productDetail.container div.row div.col-xs-7 div.carousel-product {
    position: inherit;
    top: 0;
}

.add_panel {
    font-size: 12px;
    margin-left: -25px;
    margin-right: -25px;
    font-weight: bold;
}

.productDetail.container div.row div.col-xs-7 div.col-xs-20 div.row {
    width: 300px;
    background: #e6e6e6;
    margin-bottom: 5px;
    height: 40px;

}

.col-xs-7 div.col-xs-20 div.row div.produit-visuel-container {
    padding: 10px;
}

.nav-tabs li a#ficheproduit-onglet2.compatibilite:hover,
.nav-tabs li a#ficheproduit-onglet1.fiche_produit:hover {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #f7f7f7;
    border-color: #ddd #ddd transparent;
    border-image: none;
    border-style: solid;
    border-width: 1px;
    color: #555555;
    cursor: default;
    display: block;
    height: 68px;
    padding: 25px 25px 10px;
}

.nav-tabs li a#ficheproduit-onglet1.fiche_produit,
.nav-tabs li a#ficheproduit-onglet2.compatibilite {
    cursor: default;
    display: block;
    height: 68px;
    padding: 25px 25px 10px;
}

.product div.row.bloc-vehicules-compatibles div.col-xs-16.vehicules ul.list_marques li ul.modele.hide {
    display: inherit !important;
}

.datasheet_table_header {
    background: #0f5e96;
    padding-bottom: 6px;
}

.list-item div.title.datasheet_table_header p {
    color: #fff;
}

.bloc-vehicules-compatibles.logo {
    float: left;
    display: inline-block;
}

.modeles22 {
    background: #0f5e96;
    color: #fff;
    margin: -5px -10px 0;
    padding: 15px;
}

div.toggle-inner div.toggle-on.active,
div.toggle-inner div.toggle-off.active {
    background: #ccc none repeat scroll 0 0;
    border-radius: 15px;
}

.toggle-inner .toggle-on {

    background: #fff none repeat scroll 0 0;
    border-radius: 15px;
}

.product .productDetail .toggle-ht-ttc .toggle {
    border: 0px;
}

.toggle-ht-ttc .toggle-blob {
    background: #0f5e96;
}

html.js body.scrolled.modal-open div.modal-backdrop.fade.in {
    z-index: 10;
}

.modal.fade.fiche-vehicule.in {
    z-index: 105;
}

.search-pieces .types {
    z-index: inherit;
}

.modal-backdrop {
    z-index: 10;
}

div.modal.fade.fiche-vehicule.in.popin_fp_without_vehicle div.modal-dialog {
    width: 1050px;
}

/*ba33*/

.spacer:hover,
div.wrapper div.main.container div#infos-generales div.row.bloc-historique div.col-xs-20.bloc-historique-list table.table.table-hover.table-bordered.table-result-search tbody tr.spacer td:hover,
div.wrapper div.main.container div#infos-generales div.row.bloc-historique div.col-xs-20.bloc-historique-list table.table.table-hover.table-bordered.table-result-search tbody tr.entete:hover,
div.wrapper div.main.container div#infos-generales div.row.bloc-historique div.col-xs-20.bloc-historique-list table.table.table-hover.table-bordered.table-result-search tbody tr.entete td:hover {
    background-color: #fff;
}

/*hA*/
div.row.bloc-vehicules-compatibles.details22 div.col-xs-16.vehicules {
    background: inherit;
}

div.row.bloc-vehicules-compatibles.details22 div.col-xs-16.vehicules div.list_vehicules {
    background: #ececec;
}

.catalogue-product {
    z-index: inherit;
}

/*fin hA*/

.user-action li.active {
    background-color: #fff;
}

.user-action li.active .badge {
    background-color: red;
}

.dropdown-toggle .filter-option {
    line-height: 23px;
}

.dropdown-toggle span.caret,.defaultPage .centre .infos-commande .dropdown-toggle span.caret{
    background: url("../../../images/icon_down_arrow.png") no-repeat scroll center center;
    height: 11px;
    width: 16px;
    border-bottom: 0;
    border-top: 0;
}

div.main.wireframeTableau div div.defaultPage.container div.row div.centre.col-xs-20.results.commande div.infos-commande div.row form div.col-xs-20.btn-action {
    margin-top: -40px;
}

div.carousel-product.carouNav1 div.caroufredsel_wrapper {
    width: 298px;
    height: 298px;
}

.see_produit_associe:hover,
.see_produit_associe:active {
    background-color: #e6e6e6;
}

tr.product.see_produit_associe:hover td.actions,
tr.product.see_produit_associe:hover td.name,
tr.product.see_produit_associe:hover td.price {
    color: #0f5e96;
}

.cart-block .slide .holder {
    top: 29px;
    box-shadow: none;
}

.cart-block .slide {
    right: -106px;
}

/*hA*/
.infos-piece table tbody tr td.col-xs-2,
.infos-piece table thead tr th.col-xs-2 {
    padding-right: inherit;
    padding-left: inherit;
}

html.js body div.wrapper div.header div.header-holder div.container div.nav-holder div.container-fluid ul.nav.row ul.user-action li.shipping.col-xs-2.cart-block.active div#layerPanier.slide div#produit_associe.holder.pull-left div.row.familles-list div.inner div.familles.row div.famille.col-xs-6 div.image a {
    border-color: #eee;
    border-style: solid;
    border-width: 0 0 1px;
    cursor: pointer;
    display: inline-block;
    height: 135px;
    margin-bottom: 10px;
    padding-bottom: 30px;
    padding-top: 30px;
    text-align: center;
    width: 104px;
}

.modal-body div.tab-content div#ficheproduit-1.tab-pane.active div.container,
.productDetail.container {
    width: 100%;
}

.modal.fade.fiche-vehicule.in div.modal-dialog.popin22 div.modal-content {
    width: 1125px;
    margin-left: -45px;
}

.search-form-peinture .form-control, .search-form-pneu .form-control {
    border-color: #fff;
    border-image: none;
    border-style: solid;
    color: #8d8d8d;
    float: left;
    font-family: "Futura LT Book", sans-serif;
    font-size: 13px;
    font-style: italic;
    height: 54px;
    line-height: 46px;
    padding: 0 15px;
    width: 400px;
}

.search-form-peinture .button, .search-form-pneu .button {
    background-color: red;
}

.search-form-peinture .btn-wrap, .search-form-pneu .btn-wrap {
    border-color: red;
}

.search-form-pneu .lire, .search-form-pneu .lire {
    position: relative;
    background-color: #e7e7e7;
    width: 180px;
    height: 54px;
    margin: 0;
    padding-left: 15px;
    padding-top: 6px;
    font-size: 14px;
    outline: none;
    border: none;
}

.search-form-pneu .lire img {
    position: absolute;
    top: 15px;
    right: 26px;
    cursor: pointer;
}

.search-form-pneu .lire a {
    color: #333333;
}

.search-form-peinture, .search-form-pneu {
    margin: 25px 0;
}

.bloc-reference p strong {
    color: #0f5e96;
}

/*new border bottom red 22 */
.border_bottom_red_22:after {
    content: '';
    margin-bottom: 10px;
    padding-bottom: 5px;
    display: block;
    width: 80px;
    left: 0px;
    border-bottom: 4px solid #d80000;
}

.border_bottom_red_22 {
    color: #0f5e96;
}

/*end border bottom red 22*/

.product div.productDetail.container div.row div.col-xs-4,
.product div.productDetail.container div.row div.col-xs-4.disponibilite,
.prix_ht_ttc {
    background: #f6f6f6;
}

.col-xs-4.prix_ht_ttc {
    border-bottom-width: 0;
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 15px;
}

a {
    color: #0f5e96; }

.affectation-origine .filtreDateMec label {
    color: #0f5e96;
}

.table-detail-price > thead > tr > td {
    background: #0f5e96 none repeat scroll 0 0;
}

.marque_without_logo {
    margin: 10px;
}

.form-search-modele .selection-criteres .brand {
    /*line-height: 30px;*/
}

.dispo-details .tooltip-new {
    margin: -2px 0 0 -163px;
}

.remise_22,
.productDetail.container div.row div.col-xs-9.infos-piece p.title.border_bottom_red_22,
.productDetail.container div.row div.col-xs-9.infos-piece p.title.first.border_bottom_red_22,
.productDetail.container div.row div.col-xs-4.prix_ht_ttc div.row div.col-xs-12 {
    color: #0f5e96;
}

.col-xs-4.family.open div.slide {
    left: -350px;
}

.col-xs-4.family.open div.slide div.holder {
    padding-left: 0;
    padding-right: 0;
    padding-right: 20px;
}

.col-xs-4.family.open div.slide div.holder div.famille {
    margin-bottom: 10px;
}

.col-xs-4.subfamily.open div.slide,
.col-xs-4.subsubfamily.open div.slide {
    left: -249px;
    width: 1000px;
}

.col-xs-4.family.open div.slide {
    width: 1000px;
}

#ficheproduit-2 {
    background: #ececec none repeat scroll 0 0;
    margin: -15px;
    padding: 15px;
}

html.js body.scrolled.modal-open div.wrapper div.main.container div#popinFicheProduit.modal.fade.fiche-vehicule.in div.modal-dialog div.modal-content.popin22 div.modal-body div.tab-content div#ficheproduit-2.tab-pane div.product div.items22 div.div_to_toggle div.row.bloc-vehicules-compatibles.details22 div.col-xs-16.vehicules {
    background: #fff;
}

.compatibilite.active.border_bottom_red:focus,
.compatibilite.active.border_bottom_red,
.performances_pneus.active.border_bottom_red:focus,
.performances_pneus.active.border_bottom_red {
    background: #ececec none repeat scroll 0 0;
}

.data_modele22, .name_modele22,
.bloc-vehicules-compatibles.details22 div.col-xs-16.vehicules ul.list_marques li ul.modele.hide {
    color: #0f5e96;
}

.item.non_grey_color22 {
    border-bottom-width: 0 none;
    background: #ececec;
}

.item.grey_color22 {
    border-bottom-width: 0 none;
    background: #f6f6f6;
}

.bloc-vehicules-compatibles.details22 div.col-xs-16.vehicules {
    min-height: inherit;
}

html.js body.scrolled.modal-open div.wrapper div.main.container div#popinFicheProduit.modal.fade.fiche-vehicule.in div.modal-dialog div.modal-content.popin22 div.modal-body div.tab-content div#ficheproduit-1.tab-pane.active div.container div.product div.productDetail.container div.row div.col-xs-4.disponibilite center div.dispo-details span a.dispo-info-bulle div.tooltip-new div.row div.col-xs-12 {
    border-bottom: 1px solid rgb(156, 197, 86);
    margin-bottom: 0;
    margin-right: -4px;
    padding-bottom: 0;
    padding-right: 15px;
    width: 70.5%;
}

html.js body div.wrapper div.main.container div#popinFicheProduit.modal.fade.fiche-vehicule.in div.modal-dialog div.modal-content.popin22 div.modal-body div.tab-content div#ficheproduit-1.tab-pane.active div.container div.product div.productDetail.container div.row div.col-xs-4.disponibilite center div.dispo-details span a.dispo-info-bulle div.tooltip-new div.row div.tooltip-new-arrow.col-xs-2 {
    border-top: 1px solid #fff;
    margin-top: 7px;
    transform: rotate(180deg);
}

html.js body div.wrapper div.main.container div#popinFicheProduit.modal.fade.fiche-vehicule.in div.modal-dialog div.modal-content.popin22 div.modal-body div.tab-content div#ficheproduit-1.tab-pane.active div.container div.product div.productDetail.container div.row div.col-xs-4.disponibilite center div.dispo-details span a.dispo-info-bulle div.tooltip-new div.row div.col-xs-6 {
    border-bottom: 1px solid rgb(156, 197, 86);
    width: 19.5%;
}

html.js body div.wrapper div.main div.main.container div.reference-produits-container div.references-produit-list div.produits table.table-condensed tbody tr.panier-input.border_space td.caracteristiques-details.topspace center div.dispo-details.dispoCPF span a.dispo-info-bulle div.tooltip-new {
    bottom: 0;
    height: 0;
    left: 330px;
    margin-left: 0;
    margin-top: 0;
    padding: 10px;
    right: 0;
    top: 155px;
    width: 307px;
}

div#ficheproduit-1.tab-pane.active div.container div.product div.productDetail.container div.row div.col-xs-4.disponibilite center div.dispo-details.dispoCPF span a.dispo-info-bulle div.tooltip-new {
    bottom: 0;
    height: 126px;
    left: 0;
    margin: 0 0 0 -163px;
    padding-bottom: 0;
    right: 0;
    top: 19px;
}

.modal-body div.tab-content div#ficheproduit-1.tab-pane.active div.container div.product div.productDetail.container div.row div.col-xs-4.disponibilite center div.dispo-details span a.dispo-info-bulle div.tooltip-new {
    bottom: 0;
    height: 66px;
    left: -130px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
    right: 0;
    top: 21px;
    width: 315px;
}

div.modal-body div.tab-content div#ficheproduit-1.tab-pane.active div.container div.product div.productDetail.container div.row div.col-xs-4.disponibilite center div.dispo-details.dispoCPF span a.dispo-info-bulle div.tooltip-new div.row div.tooltip-new-arrow.col-xs-2 {
    margin-top: 6px;
}

a.tooltips {
    position: absolute;
    display: inline;

    background-color: rgb(97, 195, 32);
    color: rgb(255, 255, 255);
}

a.tooltips img {
    padding-left: 5px;
    vertical-align: inherit;
}

a.tooltips span {
    position: absolute;
    width: 275px;
    color: #000000;
    background: #FFFFFF;
    border: 1px solid rgb(97, 195, 32);
    line-height: 18px;
    text-align: center;
    visibility: hidden;
    border-radius: 1px;
}

a.tooltips span:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 85%;
    margin-left: -12px;
    width: 0;
    height: 0;
    border-bottom: 12px solid rgb(97, 195, 32);
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
}

a.tooltips span:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 85%;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-bottom: 11px solid #FFFFFF;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
}

a:hover.tooltips span {
    visibility: visible;
    opacity: 1;
    font-size: 12px;
    padding: 5px;
    left: -221px;
    top: 30px;
    z-index: 999;
}

.productDetail .disponibilite center .DispoECPF,
.containerDispo center .DispoECPF,
td.caracteristiques-details center .DispoECPF {
    background-color: rgb(97, 195, 32);
    color: rgb(255, 255, 255);
    padding: 8px 30px 8px 20px;
    width: auto;
    position: relative;
    padding-left: 5px;
}
.productDetail .disponibilite.containerDispo{
    float: right;
}
.productDetail .disponibilite center .DispoE,
.containerDispo center .DispoE,
td.caracteristiques-details center .DispoE {
    background-color: rgb(97, 195, 32);
    color: rgb(255, 255, 255);
    padding: 8px 5px;
    min-width: 105px;
    position: relative;
    padding-left: 5px;
}

td.caracteristiques-details {
    center {
        .DispoE {
            .tooltips.tooltips-cora {
                top: -16px;
                right: 1px;
            }
        }
    }
    .tooltips.istyle.tooltips-sidexa {
        top: -24px;
        right: -11px;
    }
}

td.caracteristiques-details center .DispoE .tooltips {
    top: 0px;
    right: 5px;
}

td.caracteristiques-details center .DispoE .tooltips > img:nth-child(1) {
    position: absolute;
    right: 3px;
    top: 25px;
}

td.caracteristiques-details center .DispoE .tooltips > span:nth-child(2) {
    top: 50px;
    left: -245px;
}

div.dispo-details.dispoA {
    background-color: rgb(255, 142, 1);
    color: rgb(255, 255, 255);
    margin-left: -15px;
    margin-right: -15px;
    padding: 4px 0;
    width: 118%;
}

div.dispo-details.dispoA span{
    height: 30px;
}

div.tab-content div#ficheproduit-1.tab-pane.active div.container div.product div.productDetail.container
div.row div.col-xs-4.disponibilite center div.dispo-details.dispoA span input.enable-jquery-datepicker.hasDatepicker {
    background: rgba(0, 0, 0, 0) url("../../../images/icon-calendrier.png") no-repeat scroll 87px center;
}

div#ui-datepicker-div.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all.ui-helper-hidden-accessible {
    margin-left: -50px;
}

.see22 {
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
    color: #0f5e96;
}

div#layerPanier.slide div.holder {
    box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.32);
}

#produit_associe.holder.pull-left div.row.familles-list div.inner div.familles.row div.famille.col-xs-6 {
    width: 28%;
}

div#layerPanier.slide div#produit_associe {
    display: inline;
}

.border_bottom_red_header {
    content: '';
    /*margin-top:10px;*/
    display: block;
    /*width: 55px;*/
    left: 0px;
    height: 34px;
    border-bottom: 4px solid #d80000;
}

li.shipping.col-xs-2.cart-block.active {
    border-top: 0px;
    border-bottom: 0px;
    /*    margin-top: -2px;
        padding-bottom: 10px;
        padding-top: 23px;
        top: 0;
        width: 41px;*/
}

li.shipping.col-xs-2.cart-block.active div#layerPanier.slide div.holder {
    position: inherit;
}

/*Catalogue Pneu*/

.no-gutter {
    /*height : 220px;*/
}

/*entête*/
table.catalogue-pneu {
    margin-left: 9px;
    margin-top: 10px;
}

table.catalogue-pneu thead {
    height: 25px;
}

table.catalogue-pneu tbody {
    height: 150px;
}

.catalogue-pneu thead tr th {
    color: #0f5e96;
    text-align: center;
    min-width: 190px;
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: red;
}

.catalogue-pneu thead tr th:last-child {
    border-right-style: none;
}

/*body*/
.catalogue-pneu tbody {
    margin-top: 50px;
}

.catalogue-pneu tbody tr:first-child {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: grey;
}

.catalogue-pneu tbody tr td {
    color: #0f5e96;
    text-align: left;
    min-width: 190px;
}

.inputradioPneus input {
    min-height: 30px;
    width: 10px;
    margin-left: 20px;
}

.inputradioPneus label {
    color: black;
    margin-left: 7px;
    /*vertical-align: 11px;*/
    max-width: 90%;
    font-size: 11px;
}

.inputradioPneus label img {
    margin-left: 6px;
}

/*plus de marques*/

.plus-de-marques {
    margin-left: 400px;
}

#refMarque-Premium, #refMarque-Budget, #refMarque-Quality {
    padding-top: 1px;
    margin-left: 400px;
    max-width: 185px;
}

#refMarque-Quality {
    margin-left: 590px;

}

#refMarque-Budget {
    margin-left: 785px;
}

.marques {
    min-width: 220px;
}

.marques a {
    width: 190px;
    font-style: italic;
    color: #000;

}

.opt {
    background-color: #fff;
    border: medium none;
    color: black;
    display: block;
    font-family: "FuturaStdBold", sans-serif;
    margin: 3px 0 0;
    position: relative;
    width: 148px;
}

.opt label {
    background: transparent none repeat scroll 0 0;
    border: medium none;
    color: black;
    display: inline-block;
    font-family: "FuturaStdBold", sans-serif;
    font-size: 10px;
    line-height: normal;
    margin: 0;
    padding-left: 25px;
    position: relative;
    text-align: left;
    vertical-align: middle;
    width: 115px;
}

.opt .check {
    display: inline-block;
    margin: 0 5px 0 6px;
    position: relative;
    vertical-align: top;
}

.opt .js-val-providerPneus {
    width: 10px;
}

/*hA*/

html.js body.scrolled div.wrapper div#headernav div.nav-container div.nav-holder,
.nav-holder {
    background-color: inherit;
}

div.row.sub-nav-links div.col-xs-10.col-xs-offset-2 fieldset {
    width: 970px;
}

.search-form-pneu fieldset input#pneusAutocomplete.form-control {
    width: 505px;
}

form.search-form-pneu div.row.sub-nav-links div.col-xs-10.col-xs-offset-2 {
    height: 54px;
    margin-bottom: 20px;
    margin-left: -20px;
    margin-right: 0;
    width: 100%;
}

div.plus-de-marques.col-xs-12.row p.marques a {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: #ccc;
    border-image: none;
    border-style: solid;
    border-width: 0 thin thin 0;
    margin-right: 10px;
    margin-top: 0;
    padding: 5px;
}

div.sub-nav-container-pneu.container.pneus {
    margin-top: 5px;
}

.list-filtre-pneus {
    cursor: pointer;
    margin-left: 20px;
    position: relative;

}

tr.panier-input.border_space.containerDispo td.caracteristiques-details div.cart-option div.cart-action input.value {
    color: #565656;
}

.img-marke {
    padding-left: 30px;
}

.refMarque {
    display: block;
    left: -11px;
    padding-top: 1px;
    position: absolute;
    top: 320px;
    z-index: 9999;

}

div.refMarque.result div {
    /*background: #fff none repeat scroll 0 0;*/
    /*margin-top: 22px;*/
    /*width: 211px;*/
}

.list-filtre-pneus img {
    padding-left: 25px;
}

.refMarque.result {
    background: #fff;
}

table.catalogue-pneu tbody tr td {
    padding-top: 10px;
}

div.refMarque.result {
    display: block;
    left: -10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 11px;
    top: 320px
}

.liste_marque {
    top: 22px;
    background: #fff;
    padding-left: 10px;
    padding-right: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 300px;
}

.searchpneus {
    background: #fff url("../../../images/pneus/pneusSearch.png") no-repeat scroll 10px 8px;
}

span#afficher.btn.btn-default.see-all.see-all-pneus {
    background-color: red;
    color: #fff;
    text-transform: uppercase;
    height: 54px;
    padding-left: 11px;
    padding-right: 11px;
    width: 225px;
}
span#afficher.btn.btn-default.see-all.see-all-pneus.none {
    background-color: #CBCBCB;
    box-shadow: none;
    cursor: default;
}
span#displayCompatibleTiresTab.btn.btn-default.see-all.see-all-pneus {
    margin-top: 15px;
    background-color: white;
    border-color: #0c365d;
    color: #0c365d;
    text-transform: uppercase;
    height: 45px;
    padding-left: 11px;
    padding-right: 11px;
    padding-top: 15px;
    width: auto;
    width: 225px;
}
span#displayYourSelectionTab.btn.btn-default.see-all.see-all-pneus {
    margin-top: 15px;
    background-color: #0c365d;
    border-color: white;
    color: white;
    text-transform: uppercase;
    height: 45px;
    padding-left: 11px;
    padding-right: 11px;
    padding-top: 15px;
    width: auto;
    width: 225px;
}
#tires-tab {
    .tires-tab-item {
        display: none;
    }
    .tires-tab-item.active {
        display: block;
    }
}
.autocomplete-ui {
    background: #fff;
    color: #000;
    padding: 10px;
    position: absolute;
    width: 99%;
    z-index: 3;
    -webkit-box-shadow: 2px 2px 5px 0 #000000;
    box-shadow: 2px 2px 5px 0 #000000;
}

div.autocomplete-ui div.row div.col-xs-6 a {
    color: #000;

}

.resized {
    width: 25%;
    margin-bottom: 5px;
    margin-top: 5px;
}

.cart-btn, .cart-btn:hover {
    background: #d80000 none repeat scroll 30% center;
    color: #fff;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    text-indent: 30px;
}

.cart-btn span {
    background: rgba(0, 0, 0, 0) url("../../../images/panier_white.png") no-repeat scroll 0 0;
    margin-left: -32px;
    padding-left: 40px;
}

.catalogueHeader {
    color: #0f5e96;
    font-weight: bold;
    padding-bottom: 15px;
    padding-top: 15px;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
    -moz-box-shadow: 0px 1px 2px 0px #656565;
    -webkit-box-shadow: 0px 1px 2px 0px #656565;
    -o-box-shadow: 0px 1px 2px 0px #656565;
    box-shadow: 0px 1px 2px 0px #ccc;
    filter: progid:DXImageTransform.Microsoft.Shadow(color=#656565, Direction=180, Strength=2);
}

.modal-body table.table.table-hover.table-results thead tr th {
    color: #fff;
    border: 1px solid #ccc;
}

.imgCheckbox22{
    display: inline-block;
    padding-left:20px;
    height:17px;
    background-image:url("../../../images/pneus/red.png");
    background-repeat: no-repeat;
    background-position: -19px -12px;
    /*margin-right: 10px;*/
    cursor: pointer;
}

input[type=checkbox].checkBox22:checked + label.imgCheckbox22 {
    background-position: 0 -15px;
}

.inputradioPneus22 {
    margin: 5px;
    cursor: pointer;
}

div.container.resultatsPneus div.no-gutter div.row div.col-xs-4.types.input-group-addon.dropdown {
    background: #fff;
    /*border-left-width: 0;*/
    /*border-top-width: 0;*/
    /*float: right;*/
    /*margin-left: 10px;*/
    /*margin-right: 10px;*/
}

div.col-xs-4.types.input-group-addon.dropdown a.dropdown-toggle.selected span.inner {
    /*margin-left: 15px;*/
}

.resultatsPneus div.no-gutter div.row div.col-xs-4.col-sm-3 div.inputradioPneus22 span.fullsize img {
    margin-top: -25px;
}

.inputradioPneus22 span.fullsize {
    margin-top: 30px;
    /*position: relative;*/
    /*top: -10px;*/
}

div.inputradioPneus22 span.fullsize {
    display: inline-block;
    /*font-weight: bold;*/
    margin-bottom: 5px;
    max-width: 100%;
}

.saisonType {
    position: relative;
    top: -10px;
}

/*Ha*/

/* PRP */

.prp-table .prp-header th {
    text-align: center;
    vertical-align: middle;
    background: #0f5e96 none repeat scroll 0 0;
    border: 1px solid grey;
    width: 200px;
    height: 50px;
    color: white;
    font-size: 14px;
}

.prp-table td {
    text-align: center;
    vertical-align: middle;
    border: 1px solid grey;
    width: 250px;
    height: 40px;
    color: #4B4B4B;
    font-weight: bold;
    font-size: 13px;
    background-color: #ededed;
}

.prp-table td input:disabled {
    color: #cccccc;
    background-color: grey;
}

.videPrp {
    border-color: red;
    border-style: solid;
    border-width: 2px;
}

.prp-table {
    margin-bottom: 15px;
}

.bandeau .btn-action {
    background-color: #fd672b;
    border: medium none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    font-size: 11px;
    font-weight: bold;
    padding: 5px 15px;
}

.bandeau #btn-reset {
    background: #0c365d url("../../../images/pneus/fleche_reset_blanc.png") no-repeat scroll 4px 9px / 9px 9px;
    margin-right: 10px;
}

/* PRP */

#popinFpPneus .modal-dialog {
    width: 1100px;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 12px;
    .prices-sheet {
        padding: 0;
        background-color: $lightgrey;
        hr {
            margin: 0;
            border-color: $blue4;
            border-width: unset;
            &.disabledOnglet {
                border: none;
            }
        }
        .offer-prices {
            li {
                width: 45%;
                height: 50px;
                background-color: $white;
                &.offferDeadline {
                    width: 55%;
                    a {
                        padding: 15px 14px;
                    }
                }
                a {
                    font-size: 15px;
                    text-transform: none;
                    padding: 15px 16px;
                    border-radius: 22px 22px 0 0;
                    background-color: $lightgrey;
                    color: $black;
                    font-weight: bold;
                    border-bottom: none;
                    &.active {
                        background-color: $blue4;
                        border: none;
                        color: $white;
                        font-weight: 500;
                    }
                }
                a::after {
                    border-bottom: none;
                }
                &.disabledOnglet {
                    pointer-events: none;
                    a {
                        background-color: $grey80;
                        opacity: 0.7;
                    }
                }
            }
        }
        #offer-deadline:not(.active) {
            display: none;
        }
        #inventory-offer:not(.active) {
            display: none;
        }
        .tab-content-prices {
            padding: 10px;
        }
    }
}

#popinFpPneus ul a {
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 12px;
    font-weight: bold;
}

#popinFpPneus ul a.zoom-button {
    padding: 0;
}

#popinFpPneus p {
    font-size: 13px;
}

#popinFpPneus .modal-header {
    background-color: #0F5E96;
    padding: 0;
    margin-bottom: 50px;
    height: 68px;
}

#popinFpPneus .modal-header button.close {
    padding: 0;
    cursor: pointer;
    border: 0;
    -webkit-appearance: none;
    background: url("../../../images/close_button_white.png") no-repeat center center;
    height: 20px;
    width: 20px;
}

#popinFpPneus ul li {
    list-style: none;
}

#popinFpPneus .nav-tabs {
    border: none;
    padding: 0;
}

#popinFpPneus .nav-tabs li {
    height: 68px;
    margin: 0;
}

#popinFpPneus .nav-tabs li a {
    margin: 0;
    height: 100%;
    padding-top: 25px;
}

#popinFpPneus .nav-tabs li a#fichepneus-onglet1.fiche_produit {
    color: white;
    border: none;
}

#popinFpPneus .nav-tabs li a#fichepneus-onglet2.performances_pneus {
    color: white;
    border: none;
}

#popinFpPneus .nav-tabs li a#fichepneus-onglet1.fiche_produit:hover,
#popinFpPneus .nav-tabs li a#fichepneus-onglet2.performances_pneus:hover {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: white;
    color: #6E6E6E;
}

#popinFpPneus .nav-tabs li a#fichepneus-onglet1.fiche_produit.active.border_bottom_red:focus,
#popinFpPneus .nav-tabs li a#fichepneus-onglet1.fiche_produit.active.border_bottom_red,
#popinFpPneus .nav-tabs li a#fichepneus-onglet2.performances_pneus.active.border_bottom_red:focus,
#popinFpPneus .nav-tabs li a#fichepneus-onglet2.performances_pneus.active.border_bottom_red {
    color: #6E6E6E;
    background: white none repeat scroll 0 0;
}

#popinFpPneus .nav-tabs li a.border_bottom_red {
    border-bottom: 4px solid #d80000;
    width: 100%;
}

#popinFpPneus .nav-tabs li a.border_bottom_red:after {
    width: 100%;
}

#popinFpPneus .nav-tabs li.active {
    background-color: white;
}

#popinFpPneus .infos-title {
    color: #6E6E6E;
    font-size: 14px;
}

#popinFpPneus .carousel-product {
    margin-left: 30px;
}

#popinFpPneus .carousel-product,
#popinFpPneus .carousel-product ul,
#popinFpPneus .carousel-product li {
    width: 200px;
    height: 200px;

    line-height: 200px;
    list-style: none;
    background: none;
}

#popinFpPneus div.carousel-product.carouNav1 div.caroufredsel_wrapper {
    width: 200px;
    height: 200px;
}

#popinFpPneus .list_carousel {
    padding-left: 20px;
}

#popinFpPneus .carouselsZoom {
    position: absolute;

    top: 50px;
    left: 200px;
    text-align: center;
}

#popinFpPneus #carouselProduct {
    position: absolute;
}

#popinFpPneus .infos-value {
    color: black;
    font-size: 14px;
    text-transform: uppercase;
}

#popinFpPneus .cart-action input {
    border: 1px #d2cece solid;
    font-size: 16px;
    height: 27px;
    width: 27px;
    margin: 0;
    padding: 0;
}

#popinFpPneus .containerDispo {
    margin: 0 auto;
}

#popinFpPneus div.dispo-details.dispoA {
    background-color: rgb(255, 142, 1);
    color: rgb(255, 255, 255);
    margin: 0;
    width: 100%;
}

#popinFpPneus .pneu-carburant {
    margin: 0;
}

#popinFpPneus .pneu-adherence {
    margin: 0;
    margin-left: -3px;
}

#popinFpPneus .enable-jquery-datepicker,
#popinFpPneus .enable-jquery-datepicker:focus{
    background: rgba(0, 0, 0, 0) url("../../../images/icon-calendrier.png") no-repeat scroll 150px center !important;
    border: none;
    outline: none;
    width: 100%;
}

.suiviFL {
    margin-top: 35px;
    width: 100%;
    color: white;
}

.suiviFL .headerFL {
    background-color: #045E9D;
    height: 56px;
    text-transform: uppercase;
    padding: 20px;
}

.suiviFL .headerFL .numFiche,
.suiviFL .headerFL .raisDis {
    border-right: 1px #BABABA solid;
}

.suiviFL .headerFL .numFiche,
.suiviFL .headerFL .raisDis,
.suiviFL .headerFL .nom,
.suiviFL .headerFL .dateFiche,
.suiviFL .headerFL .check {
    padding: 0px 10px;
    float: left;
}

.suiviFL .headerFL .dateFiche {
    right: 150px;
}

.suiviFL .headerFL .check {
    width: 50px;
    right: 15px;
}

.suiviFL .contentFL {
    position: relative;
    background-color: white;
    border: 1px #D3D3D3 solid;
    color: black;
}

.suiviFL .contentFL div {
    float: left;
    width: 150px;
}

/*hA*/

div#accordion.ui-accordion.ui-widget.ui-helper-reset.ui-sortable div.group h3.ui-accordion-header.ui-corner-top.ui-state-default.ui-accordion-icons.ui-sortable-handle.ui-accordion-header-collapsed.ui-corner-all,
div#accordion.ui-accordion.ui-widget.ui-helper-reset.ui-sortable div.group h3.ui-accordion-header.ui-corner-top.ui-accordion-header-collapsed.ui-corner-all.ui-state-default.ui-accordion-icons.ui-sortable-handle,
div#accordion.ui-accordion.ui-widget.ui-helper-reset.ui-sortable div.group h3.ui-accordion-header.ui-corner-top.ui-state-default.ui-accordion-icons.ui-sortable-handle.ui-accordion-header-active.ui-state-active,
div#accordion.ui-accordion.ui-widget.ui-helper-reset.ui-sortable div.group h3.ui-accordion-header.ui-corner-top.ui-state-default.ui-accordion-icons.ui-sortable-handle.ui-accordion-header-active.ui-state-active {
    background: #fff none repeat scroll 0 0;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 0;
    border-color: #0f5e96;
}

div#accordion.ui-accordion.ui-widget.ui-helper-reset.ui-sortable div.group div#ui-id-2.ui-accordion-content.ui-corner-bottom.ui-helper-reset.ui-widget-content.ui-accordion-content-active {
    overflow: inherit;
}

div#accordion.ui-accordion.ui-widget.ui-helper-reset.ui-sortable div.group h3#ui-id-3.ui-accordion-header.ui-corner-top.ui-state-default.ui-accordion-icons.ui-sortable-handle.ui-accordion-header-active.ui-state-active {
    margin-top: -1px;
}

#accordion .ui-accordion-content {
    height: auto;
}
.iconOpen  {
    background-image:url("../../../images/icon_down_arrow.png") !important;
    -ms-transform: rotate(180deg); /* IE 9 */
    -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
    transform: rotate(180deg);
    left: 896px !important;
    right: 0px !important;
    top: 10px !important;
}

.iconClosed {
    left: 900px !important;
    right: 0px !important;
    top: 20px !important;
    background-image:url("../../../images/icon_down_arrow.png") !important;
}

.step5 {
    margin-top: 0;
    padding: 5px 10px;
}

/*hA*/

/* devis-constructeur */
.devisConstruct {
    background: #fff none repeat scroll 0 0;
    border: 0 solid red;
    padding: 5px 30px;

    .dataTables_wrapper{
        overflow-x: auto;
    }
}

.devisForm-container {
    border: 0px solid black;
    background: $white;
    margin: 0 -15px;
}

.devisBase {
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 14px;
    color: black;
    border: 0px solid green;
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
    padding-left: 15px;
}

.devisConstruct .search-piece, .search-consommable, .search-type {
    width: 100%;
}

#devis.btn {
    background-color: #ddd;
}

#publish.btn {
    background-color: #E2061B;
    color: #FFF;
}

.devisConstruct .average_capacity {
    width: 55px;
}

/* commandes */
#resultSuivi {
    background-color: #fff;
}

.titreBloc {
    color: black;
    font: bold;
}

#resetFiltreSuivi {
    text-transform: uppercase;
    padding: 7px 4px 4px 4px;
    height: 35px;
    float: right;
    margin-top: -35px;
    margin-right: -55px;
    background-color: #7A7C7E;
    color: white;
}

#resetFiltreSuivi:hover {
    background-color: #e6e6e6;
    color: black;
}

.btn-suivi input {
    margin: 1px 4px 0px 5px;
    padding: 4px;
    height: 35px;
    border: 1px solid #ccc;
}
#filtreSuiviCommand{
    margin-bottom: 20px;
}

#resultSuivi h1 {
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 30px;
}

#resultSuivi h1 span {
    color: #565656;
}

#resultSuiviHead th {
    color: #0f5e96;
}

#resultSuiviHead th:first-child {
    border-left: 1px solid;
}

#detailsTab td {
    vertical-align: middle;
}

#validFiltreSuivi {
    margin-left: -20px;
    left:45%;
}

#transmitionCommand {
    margin-left: -27px;
}

#numeroCommand{
    margin-left:-12px;
}

#numeroCommand #numcde::placeholder{
   white-space: pre-line;
   position: absolute;
   top: 20%;
}
#numeroCommand #numcde{
    height: auto;
    resize: none;
}


#rsltFindSuivi strong {
    /*color: #0f5e96;*/
    text-transform: uppercase;
    margin: 10px;
}

.sub-heading #backToSuivi {
    float: right;
}

.sub-heading #backToSuivi a span {
    margin: 100%;
    color: #fff;
}

.sub-heading #backToSuivi a:hover {
    color: black;
}

#popinMsgCmdForm fieldset p {
    border: 1px solid #000;
    min-height: 30px;
    width: 90%;
    padding: 0px 0px 3px 5px;
}

#headerDetail {
    width: 1000px;
}

.detail-commande-resume h3 {
    color: #565656;
}

.detail-commande-resume h3 span {
    color: #0f5e96;
}

#tableResultsCommande th {
    color: #0f5e96;
}

/* Nouveau header V3 */

.header{
    margin-bottom: 15px;
    position: relative;
}
.header-index {
    z-index: 20;
}
.no-padding{
    padding: 0;
}
.header .header-holder .container{
    background: none;
}
.header-holder .logo{
    padding-bottom: 0;
}
.header-holder .logo img {
    width: 57%;
    height: auto;
}
.header-holder .action-submenu{
    padding-left: 0;
}
.header-holder .action-submenu .shipping{
    margin: 0 10px;
    width: 25px;
}
.user-action .setting strong {
    padding: 0;
}
.nav-holder{
    font-family: Arial, sans-serif;
}
.nav-holder .user-action li.cart-block.active{
    width: 25px;
}
.nav-holder .action-submenu .active {
    border: none;
    padding-top: 0;
    height: 31px;
}
.nav-holder .user-infos {
    font-size: 14px;
    padding-top: 14px;
}
.nav-holder .user-infos .user-name img{
    margin-right: 8px;
}
.nav-holder .user-infos .user-name span {
    text-transform: none;
}
.nav-holder .user-infos .user-name a{
    font-weight: bold;
    color: #000;
}
.nav-holder .nav li.vertical-divider {
    border-left:1px #edebeb solid;
    height: 70px;
    width:auto;
    padding-left:0px;
    padding-right:0px;
    margin: 0 13px;
}
.nav-holder .nav .user-action{
    padding-top: 18px;
    padding-right: 0;
}
.nav-holder .nav .user-action .open .dropdown-menu{
    right: 0;
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus ,
.nav .open > a:active{
    background-color: inherit;
}
.nav-holder .nav .user-action .badge{
    top: -5px;
    right: -5px;
    font-size: 10px;
}

/* sous-menu navigation bleu */
.submenu-navigation {
    .navigation-menu-list {
        padding:0;

        li {
            list-style-type: none;
            padding:0;
        }

        > li {
            a {
                div.quotation{
                    border-right: 1px solid #fff;
                }

                div.nav-last-of-type{
                    border-right: none;
                }
            }

            > a {
                div {
                    span.text {
                        color: #fff;
                        text-transform: uppercase;
                        padding-left: 5px;
                    }
                }

                div.nav-icon{
                    vertical-align: middle;
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    background-size: 18px 18px;
                    background-repeat: no-repeat;
                    left: 5px;
                    top: 10px;
                }

                > div {
                    padding:15px 0;
                    /*Nécessaire de préciser la height pour le calcul du box-sizing*/
                    height: 49px;

                    div.nav-plus{
                        background-image: url("../../../images/picto_plus_white.png");
                        background-size: 15px 15px;
                    }
                    div.nav-alert{
                        background-image: url("../../../images/picto_alert_white.png");
                    }
                    div.nav-infos-techniques{
                        background-image: url("../../../images/picto_infos_blanc.png");
                        background-size: 10px 16px;
                    }
                    div.nav-devis{
                        background-image: url("../../../images/devis_nav_white.png");
                    }
                    div.package{
                        background-image: url("../../../images/package_white.png");
                    }
                    div.search{
                        background-image: url("../../../images/search_icon_white.png");
                    }
                }

                > div:hover {
                    background-color: #fff;
                    border-bottom: 5px solid #e10f21;
                    margin-left: -1px;

                    span.text{
                        color: #0f5e96;
                        font-weight: bold;
                    }
                }

                > div:hover, > div.active {
                    div.nav-plus {
                        background-image: url("../../../images/picto_plus_blue.png");
                    }
                    div.nav-alert{
                        background-image: url("../../../images/picto_alert_blue.png");
                    }
                    div.nav-infos-techniques{
                        background-image: url("../../../images/picto_infos_techniques_blue.png");
                    }
                    div.nav-devis{
                        background-image: url("../../../images/devis_nav_blue.png");
                    }
                    div.package{
                        background-image: url("../../../images/package_blue.png");
                    }
                    div.search{
                        background-image: url("../../../images/search_icon_blue.png");
                    }
                }
            }
        }
        &.glass_makers_param_activated {
            li {
                &.col-xs-3 { width: 13%; }
                &.col-xs-4 { width: 17%; }
                div {
                    .folders-ad {
                        border-right: 1px solid #fff;
                        i.icon-folder {
                            color: white;
                            font-size: 19px;
                        }
                    }
                    &.active, &:hover {
                        i.icon-folder {
                            color: #0F5E96 !important;
                        }
                    }
                }
            }
        }
    }
}

/* sous-menu navigation bleu dropdown menus */

.submenu-navigation .navigation-menu-list .dropdown-menu,
.user-action ul.dropdown-menu{
    padding: 10px 6px;
    text-transform: none;
    min-width: 150px;
}
.user-action ul.dropdown-menu{
    list-style: disc;
    left: inherit;
    width: 230px;
}
.submenu-navigation .navigation-menu-list .dropdown-menu > li > a,
.user-action ul.dropdown-menu > li > a{
    padding: 4px 20px;
    clear: both;
    font-weight: bold;
    color: #0f5e96;
    white-space: normal;
    line-height: initial;
}
.user-action ul.dropdown-menu > li.sous_titre > a{
    font-size: 14px;
    list-style-type: none;
}
.user-action ul.dropdown-menu > li.sous_titre > a.unavailable{
    opacity: 0.3;
}
.user-action ul.dropdown-menu div{
    padding-left: 35px;
}
.user-action ul.dropdown-menu div:first-of-type{
    margin-bottom: 10px;
}
.user-action ul.dropdown-menu div > li.sous_sous_titre > a{
    font-size: 11px;
    font-weight: normal;
    display: list-item;
    padding: 0;
    line-height: initial;
    color: #0f5e96;
}
.submenu-navigation .navigation-menu-list .dropdown-menu > li > a span:not(.badge) {
    padding:0;
}
.submenu-navigation .navigation-menu-list .dropdown-devis{
    max-width: 100px;
}
.submenu-navigation .navigation-menu-list .dropdown-infos{
    width: 200px;
    max-width: 200px;
}
.submenu-navigation .navigation-menu-list .dropdown-alert{
    width: 200px;
    max-width: 200px;
}
.submenu-navigation .navigation-menu-list .dropdown-plus{
    max-width: 100px;
}

.submenu-navigation .navigation-menu-list .badge-alerts {
    position: absolute;
    top: 6px;
    padding: 1px 7px 0 6px;
    font-size: 9px;
}
.submenu-navigation .navigation-menu-list .dropdown-menu .icon-sprite-external-link-black{
    background-image: url("../../../images/icon_external_link.png");
    background-position-y: 3px;
}

/* modif. navHolder for new Header */

.peinture.active,
.consommables.active{
    padding-top: 10px;
}
.nav-holder .nav .pieces_background {
    margin-left: 0;
}

/*new footer*/

.general-footer{
    display: block;
    background-color: $blue;
    width: 1000px;
    margin: 0 auto;
    .footer-holder {
        color: $white;
        p {
            margin: 0;
            padding: 20px;
            a{
                color: #fff;
                &:hover {
                    color: #b2b2b2;
                    text-decoration: none;
                }
            }
        }
    }
}

.close-btn-modal {
    display: inline-block;
    font-size: 20px;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 50%;
    position: absolute;
    top: -40px;
    right: 0;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    cursor: pointer;
}
.switch-btn {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 26px;
    .slider-btn {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        &:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 0;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }
    }
    .slider-btn.round {
        border-radius: 25px;
    }
    .slider-btn.round:before {
        border-radius: 50%;
        border: 1px solid #ccc;
    }
    input {
        opacity: 0;
        width: 0;
        height: 0;
        &:checked + .slider-btn {
            background-color: #56c374;
        }
        &:disabled + .slider-btn {
            opacity: 0.6;
        }
        &:checked + .slider-btn:before {
            -webkit-transform: translateX(30px);
            -ms-transform: translateX(30px);
            transform: translateX(30px);
        }
    }
    &-save {
      background: #0f5e9b;
      cursor: pointer;
      color: #fff !important;
    }
}

.scrolled .header {
    position: fixed;
    width: 100%;
    top: 0;
}
.scrolled .wrapper {
    padding-top: 120px;
}

/*** New historic style ***/
/* Pièces resultats */

div.row.bloc-historique div.row div.toggle-btn-historic a.btn.btn-default.see-all,
div.row.bloc-historique div.row div.toggle-btn-historic a.btn.btn-default.close-all{
    padding: 15px 30px;
    font-weight: bold;
    margin:15px;
    background-color: red;
    color:#fff;
    text-transform: uppercase;
}
.bloc-historique .bloc-historique-list .space_btn_tr td:last-of-type{
    padding-left: 12px;
}
.table-result-search .entete{
    color: #000;
    font-size: 12px;
}
.table-result-search .entete td{
    padding: 0;
}
.table-result-search .entete > td{
    border-left: #fff 1px solid;
    border-top: #fff 1px solid;
    border-right: #fff 1px solid;
}
.table-result-search .space_btn_tr td,
.table-result-search .space_btn_tr .histo-result-date a{
    color: #000;
    padding-top: 8px;
    padding-bottom: 15px;
}
.table-result-search .space_btn_tr .name{
    width: 290px;
    border-left:#ececec 2px solid;
    border-right:#ececec 0px solid;
}
.table-result-search .space_btn_tr .fiche-vehicule div:last-of-type{
    padding-top: 4px;
}
.table-result-search .space_btn_tr .name a,
.table-result-search .space_btn_tr .devis{
    color: #0F5E96;
    font-weight: bold;
}
.table-result-search .space_btn_tr .devis{
    font-size: 12px;
}
.table-result-search tbody .services a:first-of-type{
    margin-left: 0;
}
.table-result-search .pubtech-open-catalogue {
    color: #000;
    font-size: 12px;
}
.table-result-search tbody > tr > td {
    vertical-align: bottom;
}
.table-result-search .tbodyc .services span.icon-autodata {
    margin-left: 0;
}
.table-result-search .tbodyc .services span.icon-esi{
    margin-left: 10px;
}
.table-result-search .tbodyc .services a{
    margin-left: 0;
}

/* Si catalogue déployé */

.table-result-search .pubtech-open-famille td,
.table-result-search .pubtech-open-famille .histo-result-date a{
    color: #000;
    padding-top: 8px;
    padding-bottom: 15px;
}
.table-result-search .pubtech-open-famille .name{
    width: 290px;
    border-left:#ececec 2px solid;
    border-right:#ececec 0px solid;
}
.table-result-search .pubtech-open-famille .fiche-vehicule div:last-of-type{
    padding-top: 4px;
}
.table-result-search .pubtech-open-famille .name a,
.table-result-search .pubtech-open-famille .devis{
    color: #0F5E96;
    font-weight: bold;
}
.table-result-search .pubtech-open-famille .devis{
    font-size: 12px;
}


/* Résultats models véhicules */
.result-model-vehicle tbody .fiche-vehicule .right-arrow{
    height: 22px;
}
.result-model-vehicle tbody .fiche-vehicule a {
    padding: 0;
}
.table-result-search .table .spacer td {
    border-left-color:#fff;
    border-right-color: #fff;
}
.table-result-search .table:last-of-type tbody tr.spacer td {
    border-bottom-color: #fff;
}
.table-result-search tbody > tr.catalogue > td {
    padding-top: 0;
}

/*Filter select IHM Pièces*/

#shelter22 .bootstrap-select.btn-group .dropdown-menu {
    min-width: 220px;
    font-size: 11px;
}

.triEquips .bootstrap-select.btn-group .dropdown-menu {
    min-width: 220px;
    font-size: 11px;
}

#shelter22 .bootstrap-select.btn-group .dropdown-menu li.odd,
.triEquips .bootstrap-select.btn-group .dropdown-menu li.odd,
.filtreEquipementiersEquiv .bootstrap-select.btn-group .dropdown-menu li.odd {
    background: #fff;
}

#shelter22 .bootstrap-select.btn-group .dropdown-menu > li > a,
.references-produit-list .bootstrap-select.btn-group .dropdown-menu > li > a,
.triEquips .bootstrap-select.btn-group .dropdown-menu > li > a,
.filtreEquipementiersEquiv .bootstrap-select.btn-group .dropdown-menu > li > a,
.references-produit-list .bloc-reference .title > th
{
    white-space: inherit;
}

#shelter22 .bootstrap-select.btn-group .dropdown-menu > li > a,
.triEquips .bootstrap-select.btn-group .dropdown-menu > li > a,
.filtreEquipementiersEquiv .bootstrap-select.btn-group .dropdown-menu > li > a,
.filtreEquipementiers .bootstrap-select.btn-group .dropdown-menu > li > a {
    padding-left: 36px;
}

#shelter22 .bootstrap-select.btn-group .dropdown-menu li.disabled a,
.triEquips .bootstrap-select.btn-group .dropdown-menu li.disabled a,
.filtreEquipementiersEquiv .bootstrap-select.btn-group .dropdown-menu li.disabled a,
.filtreEquipementiers .bootstrap-select.btn-group .dropdown-menu li.disabled a {
    color: #ccc;
}

.glyphicon-ok:before {
    content: "\e013";
    color: #fff;
    padding: 2px;
    border-radius: 50%;
    border: 1px solid #e2e2e2;
}

.selected .glyphicon-ok:before {
    background-color: #e10f21;
    border: 1px solid #e10f21;
}

.glyphicon {
    position: relative;
    top: 0;
    display: inline-block;
    font-family: 'Glyphicons Halflings', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.sub_result .bootstrap-select.btn-group .dropdown-menu li a span.check-mark,
#shelter22 .bootstrap-select.btn-group .dropdown-menu li a span.check-mark,
.triEquips .bootstrap-select.btn-group .dropdown-menu li a span.check-mark,
.filtreEquipementiersEquiv .bootstrap-select.btn-group .dropdown-menu li a span.check-mark {
    position: absolute;
    display: inline-block;
    top: 12px;
    left: 10px;
    margin-top: 0;
}

.sub_result .bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark,
#shelter22 .bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark,
.triEquips .bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark,
.filtreEquipementiersEquiv .bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
    position: absolute;
    display: inline-block;
    top: 12px;
    left: 10px;
    margin-top: 0;
}

.sub_result .bootstrap-select.btn-group .dropdown-menu li a span.text,
#shelter22 .bootstrap-select.btn-group .dropdown-menu li a span.text,
.triEquips .bootstrap-select.btn-group .dropdown-menu li a span.text,
.filtreEquipementiersEquiv .bootstrap-select.btn-group .dropdown-menu li a span.text {
    margin-right: 0;
    margin-left: 0px;
}

.sub_result .bootstrap-select.btn-group .dropdown-menu li.selected a span.text,
#shelter22 .bootstrap-select.btn-group .dropdown-menu li.selected a span.text,
.triEquips .bootstrap-select.btn-group .dropdown-menu li.selected a span.text,
.filtreEquipementiersEquiv .bootstrap-select.btn-group .dropdown-menu li.selected a span.text {
    font-weight: bold;
}

.references-produit-list .bootstrap-select.btn-group {
    margin-bottom: 0;
}

.search-car-sort,
.ref-equip-sort {
    text-align: right;
}

.search-car-sort > .blue_text
.ref-equip-sort > .blue_text {
    margin-left: 15px;
}

.affectation-origine .reference-produits-container {
    margin: 0;
}

.reference-produits-container .brand {
    padding: 10px;
    background-color: #0f5e96;
    color: #fff;
    display: inline-block;
    font-style: italic;
}

.reference-produits-container .ref {
    font-weight: bold;
    padding-left: 15px;
    color: #565656;
}

.reference-produits-container .ref-description {
    margin-top: 10px;
    min-height: 85px;
}

.reference-produits-container .bloc-reference .caracteristiques-details div.quantity {
    padding-top: 0;
}

.reference-produits-container .cart-option input {
    width: 100%;
}

.reference-produits-container .cart-option a {
    width: 100%;
}

.reference-produits-container .cart-option .btn-cart,
.reference-produits-container .cart-option .btn-blue {
    padding-left: 5px;
    padding-right: 5px;
    width: 110px;
}

.reference-produits-container .cart-option .btn-blue {
    padding: 6px 5px;
    background: #0f5e96;
    color: #FFF;
    text-indent: 43px;
    float: right;
}

.reference-produits-container .caracteristiques-details > .row > div {
    padding-top: 0;
}

.replacements-ref{
    margin-top: -7px;
    a{
        cursor: pointer;
        color: #565656;
        font-size: 13px;
        font-weight: 700;

        &.product-link{
            text-decoration: underline;
        }
    }
}

.affectation-origine .references-produit-list .reference-produits-container .caracteristiques-details,
.reference-produits-container .produits .table-condensed .caracteristiques-details {
    padding-top: 10px;
    padding-bottom: 10px;
    max-width: inherit;
    .replacements-ref{
        margin-top: 0;
        margin-bottom: 3px;
        color: #565656;
        a{
            font-size: 11px;
        }
    }
}

.reference-produits-container .detail-price {
    display: none;
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 10;
    padding-top: 35px;
}
.reference-produits-container .ht:hover .detail-price {
    display: block;
    cursor: auto;
}

.reference-produits-container .ht,
.reference-produits-container .cart-action {
    margin-top: 20px;
}

.reference-produits-container .panier-input {
    margin-top: 12px;
}

.reference-produits-container .cart-option .minus,
.reference-produits-container .cart-option .plus {
    color: #FFF;
    text-align: center;
    height: 20px;
    text-indent: 0;
    background-image: none;
    line-height: 20px;
    font-weight: bold;
    font-size: 14px;
}

.reference-produits-container .basket {
    margin-top: 31px;
    margin-left: 0px;
}

.reference-produits-container i.picto-panier:not(.picto-panier-tyre) {
    background: url(../../../images/panier_white.png) no-repeat;
    background-size: contain;
    position: absolute;
    top: 10px;
    left: 10px;
    height: 100%;
    width: 17%;
}

.reference-produits-container i.picto-devis {
    background: url(../../../images/devis_white.png) no-repeat;
    background-size: contain;
    position: absolute;
    top: auto;
    left: 5px;
    height: 20px;
    width: 12px;
}

.reference-produits-container .cart-separator {
    clear: both;
    height: 2px;
}

body .reference-produits-container .panier-input .panier-details {
    padding-right: 25px;
}

.choix-equipementiers .loaderrore,
.result.sub_result {
    display: none
}

.choix-equipementiers .see_result{
    cursor: pointer;
    display: block;
    text-transform: uppercase;
    float: right;color: #FFF;
    background: #0f5e96 none repeat scroll 0 0;
    padding: 0px 10px 0 21px;
    margin-left: 15px;
    width: 185px;
    text-align: center;
}

.choix-equipementiers .see_result .message {
    color: #FFF;
    margin-left:-15px;
    display : block;
}

.choix-equipementiers .see_result .waiting img {
    position: absolute;
    right: 13px;
    top: 14px;
}

/*Sidexa*/

.sidexa2 .main {
    width: 950px;
    padding: 0;
    font-family: "Futura LT", sans-serif;
}

.sidexa2 .scrolled .wrapper > .header {
    z-index: 777;
}

.sidexa2 .scrolled .wrapper .header-nav {
    z-index: 777;
}

.sidexa2 .scrolled .sub-nav-block {
    margin-top: 0px;
}

.sidexa2 .scrolled .maintenance-banner {
    margin-top: 0px;
}

.sidexa2 .popin .fiche-vehicule {
    margin-top: -50px;
}

.sidexa2 .popin .modal-body {
    font-size: 14px;
}

.sidexa2 .popin .modal-body ul {
    font-size: 14px;
    list-style: none;
}

.sidexa2 .reference-produits-container .produits {
    margin-top: 0;
    padding-left: 0;
}

.sidexa2 .reference-produits-container .produits .table-bordered {
    border-bottom: 1px solid #ececec;
}

.sidexa2 .reference-produits-container .bloc-reference {
    margin-bottom: 7px;
    background-color: #fff;
}

.sidexa2 .reference-produits-container .title {
    padding-top: 0;
}

.sidexa2 .reference-produits-container .head h4 {
    margin: 0;
    padding: 0;
    font-size: 12px;
}

.sidexa2 .reference-produits-container .head h4 strong {
    font-family: "Futura LT Heavy", sans-serif;
    font-size: 16px;
    padding-left: 10px;
}

.sidexa2 .reference-produits-container .closed .head {
    background-color: #b5b5b5;
}

.sidexa2 .reference-produits-container .closed .reload {
    background: url("../../../images/reload_picto.png") 7px 12px no-repeat; }
.sidexa2 .table-bordered .chekbox-col {
    padding: 10px;
    width: 25px;
}

.sidexa2 .modele {
    width: 950px;
    margin-top: 2px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 0;
    padding-right: 0;
    background: #1355A1;
    clear: both;
    height: 50px;
    position: relative;
    font-family: "Futura LT", sans-serif;
    -webkit-box-shadow: 0 1px 3px -2px #232323;
}

.sidexa2 .modele .fiche-container {
    float: left;
    display: inline-block;
    width: 10%;
    background-color: #fff;
    height: 50px;
}

.sidexa2 .modele .fiche {
    text-align: center;
    width: 100%;
    height: 80px;
}

.sidexa2 .modele .fiche img {
    margin-top: 7px;
}

.sidexa2 .modele .designation-vehic {
    width: 90%;
    font-size:14px;
    height:80px;
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
}

.sidexa2 .modele .designation-vehic .vehic {
    padding-left: 10px;
    padding-top: 15px;
    float: left;
    width: 100%;
    height: 50px;
}

.sidexa2 .modele .marque {
    color: #fff;
}

.sidexa2 .modele .marque strong {
    color: #fff;
    font-size: 17px;
}

.sidexa2 .modele .immat {
    position: absolute;
    display: inline-block;
    margin-left: 10px;
    height: 35px;
    padding-top: 8px;
    top: 7px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #E6E6E6;
    color: #0F5E96;
}

.sidexa2 .modele .immat:before {
    content: '';
    width: 15px;
    height: 35px;
    position: absolute;
    top: 0px;
    left: -1px;
    background-color: #82ADFF;
}

.sidexa2 .modele .immat:after {
    content: '';
    width: 15px;
    height: 35px;
    position: absolute;
    top: 0px;
    right: -1px;
    background-color: #82ADFF;
}

.sidexa2 .import-resume {
    margin: 10px 0px;
}

.import-resume > div {
    display: inline-block;
    vertical-align: top;
}

.import-resume .color-blue {
    color: #1355A1
}

.import-resume .detail h3 {
    font-size: 19px;
    margin: 0;
    padding: 7px 0 5px 0;
    text-transform: uppercase;
    line-height: 0.8;
}

.import-resume .detail p {
    font-size: 16px;
    margin: 0;
    padding-top: 8px;
    line-height: 1.1;}

.import-resume .import-montant {
    font-size: 16px;
    margin-left:5px;
}

.sidexa2 .resetbutton {
    font-family: Arial, sans-serif;
}

.footer-references{
    background-color: #1355A1;
    color: white;
    width:100%;
    height: 95px;
    margin-top: 50px;
    font-family: "Futura LT", sans-serif;
    position: fixed;
    bottom: 0;
}
.footer-references .detail{
    margin-top: 10px;
    color: white;
    display: inline-block;
    font-size: 14px;
    vertical-align: top;
    width:70%;
}
.footer-references .detail strong{
    font-weight: bold;
}

.footer-references .container-footer{
    width: 950px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 0;
}

.sidexa2 .references-produit-list {
    width: 950px;
    margin-right: auto;
    margin-left: auto;
    clear:both;
    position:relative;
    font-family: "Futura LT", sans-serif;
}

.sidexa2 .bloc-reference .infos .head {
    padding-top: 5px;
}
.sidexa2 .infos .head {
    min-height: 52px;
}
.sidexa2 .bloc-reference .head {
    position: relative;
    background: #fff;
    margin: 0;
    padding: 6px 20px 5px;
    font-family: 'Futura LT', sans-serif;
    font-size: 14px;
}

.sidexa2 .bloc-reference .color-blue {
    color: #1355A1;
    font-size: 18px;
    text-transform: uppercase;
}
.sidexa2 .bloc-reference .color-blue-small {
    color: #1355A1;
}

.sidexa2 .bloc-reference{
    border: 1px solid lightgrey;
}

.sidexa2 .bloc-reference .head .close {
    bottom: 0;
    position: absolute;
    right: 40px;
    height:20px;
    top: 20px;
}

.sidexa2 .reference-close .bloc-reference .head .close {
    background-image: url("../../../images/plus.png");
    border-left: 0px solid #fff;
    background-size: 17px 17px;
    bottom: 10px;
    padding:5px;
    right: 10px;
    left: 5px;
    top: 10px;
}

.sidexa2 .produits{
    background-color: #fff;
}

.sidexa2 .produits .refequip{
    font-size: 14px;
    text-align:right;
    padding-right:20px;
    padding-top:10px;
    padding-bottom: 10px;
    cursor: pointer;
}
.sidexa2 .produits .refequip .color-bleu{
    color: #1355A1;
}

.sidexa2 .produits .color-bleu{
    color: #1355A1;
}

.sidexa2 .produits .head-table-sidexa{
    display:inline-block;
    width:18%;
    font-size: 14px;
}

.sidexa2 .produits .head-table-sidexa .lower-head{
    font-size: 14px;
    color: #9c9c9c;
    margin-left:15px;
}

.sidexa2 .produits .head-table-sidexa .autre-lower-head{
    padding-left:15px;
    border-left: 1px solid grey;
}

.sidexa2 .produits .trierpar{
    width:60%;
    font-size: 16px;
    padding-left:20px;
    padding-top: 20px;
}

.sidexa2 .produits .arrows {
    width: 11px;
    height: 14px;
    display: inline-block;
    margin-left: 5px;
    padding-top: 5px;
}

.sidexa2 .produits .arrows a.bottom {
    background: rgba(0, 0, 0, 0) url("../../../images/icon_down_arrow.png") no-repeat scroll 0 0;
    cursor: pointer;
    display: block;
    height: 6px;
    width: 13px;
}

.sidexa2 .produits .arrows a.up {
    background: rgba(0, 0, 0, 0) url("../../../images/icon_up_arrow.png") no-repeat scroll 0 0;
    cursor: pointer;
    display: block;
    height: 6px;
    width: 13px;
}

.sidexa2 .bloc-sub-references{
    background-color: #e1e1e1;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: normal;
}

.sidexa2 .bloc-sub-references .masquer-ref{
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
}

.sidexa2 .bloc-sub-references .masquer-ref span{
    cursor: pointer;
    font-size: 13px;
}

/*    .sidexa2 .bloc-sub-references > table{*/
.sidexa2 .bloc-sub-references table{
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom:0;
    width: auto;
}

.sidexa2 .bloc-sub-references .panier-input{
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px -2px #232323;
}

.sidexa2 .bloc-sub-references .panier-input td{
    border: none;
    font-size: 12px;
    color: #565656;
    padding: 0;
}

.sidexa2 .bloc-sub-references .spacing-line{
    height:6px;
    background-color: transparent;
}
.sidexa2 .bloc-sub-references .panier-input .head-table-ref{
    font-size: 12px;
    padding-top : 5px;
    padding-bottom: 12px;
    color: #9c9c9c;
    font-style: normal;
    font-weight: normal;
}

.sidexa2 .bloc-sub-references .panier-input .td-sidexa2-ref-sized{
    width: 18%;
}
.sidexa2 .bloc-sub-references .panier-input .td-sidexa2-ref td{
    padding:0;
    padding-bottom: 15px;
}

.sidexa2 .bloc-sub-references .panier-input .chekbox-col{
    width: 10%;
}

.sidexa2 .bloc-sub-references .panier-input .dispo-details-2{
    width: 13%;
}

.sidexa2 .bloc-sub-references .panier-input .dispo-details-2 table{
    width: 80%;
}

.sidexa2 .cart-option .value {
    height: 30px;
}

.sidexa2 .cart-option .minus, .sidexa2 .cart-option .plus {
    height: 30px;
    background-color: #c5c5c5;
}

.sidexa2 div.dispo-details.dispoA{
    margin:0;
    width: 100%;
}
.sidexa2 .bloc-sub-references .panier-input .pad-bottom{
    padding: 0;
    padding-bottom: 7px;
}

/*.sidexa2 .bloc-sub-references .panier-input .equipementier-details .ref{
    text-decoration: underline;
}*/

.sidexa2 div.dispo-details strong{
    font-style: normal;
    font-weight: normal;
}

.sidexa2 .checkbox1 input[type="radio"] {
    display:none;
}

.sidexa2 .checkbox1 input[type="radio"] + label:before {
    content:"";
    display:inline-block;
    width:30px;
    height:30px;
    background: white;
    border: 1px solid grey;
    cursor:pointer;
}

.sidexa2 .checkbox1 input[type="radio"]:checked + label:before {
    background : rgba(0, 0, 0, 0) url("../../../images/checkbox-sidexa-new-red.png") no-repeat scroll 0 0;
    border: 0;
    cursor:pointer;
}

.import-resume .import-total {
    margin: 0;
    padding-top: 4px;
    color: #b3b3b3;
    font-weight: 600;
    font-size: 15px;
    padding-left: 230px;
}
.submenu-navigation .navigation-menu-list{
    padding:0;
}
.submenu-navigation .navigation-menu-list li {
    list-style-type: none;
    padding:0;
}
.submenu-navigation .navigation-menu-list a div.quotation{
    border-right: 1px solid #fff;
}
.submenu-navigation .navigation-menu-list a div.nav-last-of-type{
    border-right: none;
}
.submenu-navigation .navigation-menu-list > li > a > div,
.submenu-navigation .navigation-menu-list > li > div.disabled{
    padding:15px 0;
    /*Nécessaire de préciser la height pour le calcul du box-sizing*/
    height: 49px;
}
.submenu-navigation .navigation-menu-list > li > a div span.text,
.submenu-navigation .navigation-menu-list>li>div.disabled span.text {
    color: #fff;
    text-transform: uppercase;
    padding-left: 5px;
}
.submenu-navigation .navigation-menu-list > li > a > div:hover,
.submenu-navigation .navigation-menu-list > li > a > div.active{
    background-color: #fff;
    border-bottom: 5px solid #e10f21;
    margin-left: -1px;
}
.submenu-navigation .navigation-menu-list > li > div.disabled{
    background-color: #aabed3;
    border-right: 1px #ffffff solid;
}
.submenu-navigation .navigation-menu-list > li > a > div:hover span.text,
.submenu-navigation .navigation-menu-list > li > a > div.active span.text {
    color: #0f5e96;
    font-weight: bold;
}
.submenu-navigation .navigation-menu-list > li > a div.nav-icon,
.submenu-navigation .navigation-menu-list > li > div.disabled div.nav-icon{
    vertical-align: middle;
    display: inline-block;
    width: 18px;
    height: 18px;
    background-size: 18px 18px;
    background-repeat: no-repeat;
    left: 5px;
    top: 10px;
}
.submenu-navigation .navigation-menu-list > li > a > div div.search{
    background-image: url("../../../images/search_icon_white.png");
}
.submenu-navigation .navigation-menu-list > li > a > div:hover div.search{
    background-image: url("../../../images/search_icon_blue.png");
}
.submenu-navigation .navigation-menu-list > li > a > div div.package,
.submenu-navigation .navigation-menu-list > li > div.disabled > div div.package{
    background-image: url("../../../images/package_white.png");
}
.submenu-navigation .navigation-menu-list > li > a > div:hover div.package{
    background-image: url("../../../images/package_blue.png");
}
.submenu-navigation .navigation-menu-list > li > a > div div.nav-devis,
.submenu-navigation .navigation-menu-list > li > div.disabled div.nav-devis{
    background-image: url("../../../images/devis_nav_white.png");
}
.submenu-navigation .navigation-menu-list > li > a > div:hover div.nav-devis,
.submenu-navigation .navigation-menu-list > li > a > div.active div.nav-devis{
    background-image: url("../../../images/devis_nav_blue.png");
}
.submenu-navigation .navigation-menu-list > li > a > div div.nav-infos-techniques,
.submenu-navigation .navigation-menu-list > li > div.disabled div.nav-infos-techniques{
    background-image: url("../../../images/picto_infos_blanc.png");
    background-size: 10px 16px;
}
.submenu-navigation .navigation-menu-list > li > a > div:hover div.nav-infos-techniques{
    background-image: url("../../../images/picto_infos_techniques_blue.png");
}
.submenu-navigation .navigation-menu-list > li > a > div div.nav-alert,
.submenu-navigation .navigation-menu-list > li > div.disabled div.nav-alert{
    background-image: url("../../../images/picto_alert_white.png");
}
.submenu-navigation .navigation-menu-list > li > a > div:hover div.nav-alert{
    background-image: url("../../../images/picto_alert_blue.png");
}
.submenu-navigation .navigation-menu-list > li > a > div div.nav-plus,
.submenu-navigation .navigation-menu-list > li > div.disabled div.nav-plus{
    background-image: url("../../../images/picto_plus_white.png");
    background-size: 15px 15px;
}
.submenu-navigation .navigation-menu-list > li > a > div:hover div.nav-plus{
    background-image: url("../../../images/picto_plus_blue.png");
}

.import-resume .import-montant .prix {
    font-size: 19px;
}

.import-resume .import-montant .fonce {
    color: black;
    font-size: 17px;
}

.import-resume .import-montant-bottom {
    margin-bottom: 20px;
}

.import-resume .import-total-bottom {
    padding: 0;
    padding-top: 3px;
}

.sidexa2 .ligne-grise {
    border-bottom: 1px solid #bfbfbf;
}

.sidexa2 .ligne-grise2 {
    border-bottom: 1px solid lightgrey;
}

.sidexa2 {
    .btn-action {
        clear: both;
        float: right;
        text-align: right;
        display: inline-block;
        width: 30%;
        margin-top: 15px;
        a {
            font-size: 14px;
            background-color: #d92c2c;
            color: #fff;
            padding: 12px 18px 12px 50px;
            span {
                background: rgba(0, 0, 0, 0) url("../../../images/panier_white.png") no-repeat scroll 0 0;
                width: 27px;
                height: 25px;
                margin: -1px -33px;
                display: inline-block;
                vertical-align: top;
                position: absolute;
            }
        }
    }
}

.btn-action-popin {
    margin-top: -20px;
    text-align: center;
    a {
        font-size: 14px;
        background-color: #d92c2c;
        color: #fff;
        padding: 5px 15px;
        margin-left: 15px;
    }
}

.footer-references .btn-action a {
    padding: 12px 18px 12px 50px;
}

.footer-references {
    background-color: #1355A1;
    color: white;
    width: 100%;
    height: 95px;
    margin-top: 50px;
    font-family: "Futura LT", sans-serif;
    position: fixed;
    bottom: 0;
}

.footer-references .detail {
    margin-top: 5px;
    color: white;
    display: inline-block;
    font-size: 14px;
    vertical-align: top;
    width: 70%;
}

.footer-references .detail strong {
    font-weight: bold;
}

.footer-references .container-footer {
    width: 950px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 0;
}

.sidexa2 .references-produit-list {
    width: 950px;
    margin-right: auto;
    margin-left: auto;
    clear: both;
    position: relative;
    font-family: "Futura LT", sans-serif;
}

.sidexa2 .bloc-reference .infos .head {
    padding-top: 5px;
}

.sidexa2 .infos .head {
    min-height: 52px;
}

.sidexa2 .bloc-reference .head {
    position: relative;
    background: #fff;
    margin: 0;
    padding: 6px 20px 5px;
    font-family: 'Futura LT', sans-serif;
    font-size: 14px;
}

.sidexa2 .bloc-reference .color-blue {
    color: #1355A1;
    font-size: 18px;
    text-transform: uppercase;
}

.sidexa2 .bloc-reference .color-blue-small {
    color: #1355A1;
}

.sidexa2 .bloc-reference {
    border: 1px solid lightgrey;
}

.sidexa2 .bloc-reference .head .close {
    bottom: 0;
    position: absolute;
    right: 40px;
    height: 20px;
    top: 20px;
}

.sidexa2 .reference-close .bloc-reference .head .close {
    background-image: url("../../../images/plus.png");
    border-left: 0px solid #fff;
    background-size: 17px 17px;
    bottom: 10px;
    padding: 5px;
    right: 10px;
    left: 5px;
    top: 10px;
}

.sidexa2 .produits {
    background-color: #fff;
}

.sidexa2 .produits .refequip {
    font-size: 14px;
    text-align: right;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.sidexa2 .produits .refequip .color-bleu {
    color: #1355A1;
}

.sidexa2 .produits .color-bleu {
    color: #1355A1;
}

.sidexa2 .produits .head-table-sidexa {
    display: inline-block;
    width: 18%;
    font-size: 14px;
    margin-bottom: 5px;
}

.sidexa2 .produits .head-table-sidexa .lower-head {
    font-size: 14px;
    color: #9c9c9c;
    margin-left: 15px;
}

.sidexa2 .produits .head-table-sidexa .autre-lower-head {
    padding-left: 15px;
    border-left: 1px solid grey;
}

.sidexa2 .produits .trierpar {
    width: 60%;
    font-size: 16px;
    padding-left: 20px;
    padding-top: 10px;
}

.sidexa2 .produits .arrows {
    width: 11px;
    height: 14px;
    display: inline-block;
    margin-left: 5px;
    padding-top: 5px;
}

.sidexa2 .produits .arrows a.bottom {
    background: rgba(0, 0, 0, 0) url("../../../images/icon_down_arrow.png") no-repeat scroll 0 0;
    cursor: pointer;
    display: block;
    height: 6px;
    width: 13px;
}

.sidexa2 .produits .arrows a.up {
    background: rgba(0, 0, 0, 0) url("../../../images/icon_up_arrow.png") no-repeat scroll 0 0;
    cursor: pointer;
    display: block;
    height: 6px;
    width: 13px;
}

.sidexa2 .bloc-sub-references {
    background-color: #e1e1e1;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: normal;
}

.sidexa2 .bloc-sub-references .masquer-ref {
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
}

.sidexa2 .bloc-sub-references .masquer-ref span {
    cursor: pointer;
    font-size: 13px;
}

.sidexa2 .bloc-sub-references table {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 0;
    width: auto;
}

.sidexa2 .bloc-sub-references .panier-input {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px -2px #232323;
}

.sidexa2 .bloc-sub-references .panier-input td {
    border: none;
    font-size: 12px;
    color: #565656;
    padding: 0;
}

.sidexa2 .bloc-sub-references .spacing-line {
    height: 6px;
    background-color: transparent;
}

.sidexa2 .bloc-sub-references .panier-input .head-table-ref {
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 12px;
    color: #9c9c9c;
    font-style: normal;
    font-weight: normal;
}

.sidexa2 .bloc-sub-references .panier-input .td-sidexa2-ref-sized {
    width: 18%;
}

.sidexa2 .bloc-sub-references .panier-input .td-sidexa2-ref td {
    padding: 0;
    padding-bottom: 15px;
}

.sidexa2 .bloc-sub-references .panier-input .chekbox-col {
    width: 10%;
}

.sidexa2 .bloc-sub-references .panier-input .could-have-class-no-border-bottom {
    padding-top: 15px;
    text-align: center;
    width: 13%;
    vertical-align: middle;
    position: relative;
}

.sidexa2 .bloc-sub-references .panier-input .could-have-class-no-border-bottom img {
    cursor: pointer;
}

.sidexa2 .bloc-sub-references .panier-input .dispo-details-2 {
    width: 13%;
}

.sidexa2 .bloc-sub-references .panier-input .dispo-details-2 table {
    width: 80%;
}

.sidexa2 .panier-details .cart-option .value {
    height: 30px;
}

.sidexa2 .panier-details .cart-option .minus {
    height: 30px;
    background-color: #c5c5c5;
}

.sidexa2 .panier-details .cart-option .plus {
    height: 30px;
    background-color: #c5c5c5;
}

.sidexa2 div.dispo-details.dispoA {
    margin: 0;
    width: 100%;
}

.sidexa2 .bloc-sub-references .panier-input .pad-bottom {
    padding: 0;
    padding-bottom: 7px;
}

.sidexa2 div.dispo-details strong {
    font-style: normal;
    font-weight: normal;
}

.sidexa2 .bloc-reference-disabled {
    text-align: right;
    font-size: 20px;
    color: white;
    background-color: #1355A1;
    opacity: 0.8;
    height: 60px;
    width: 100%;
    line-height: 60px;
    position: absolute;
    z-index: 2;
    display: none;
    span {
        opacity: 1;
        padding-right: 25px;
    }
    img {
        opacity: 1;
        vertical-align: middle;
    }
    .action-close-reference {
        margin-right: 30px;
        cursor: pointer;
    }
}

.sidexa2 .checkbox1 {
    margin-top: 16px;
    margin-right: 20px;
}

.sidexa2 .checkbox1 input[type="radio"] {
    display: none;
}

.sidexa2 .checkbox1 input[type="radio"] + label:before {
    content: "";
    display: inline-block;
    width: 30px;
    height: 30px;
    background: white;
    border: 1px solid grey;
    cursor: pointer;
}

.sidexa2 .checkbox1 input[type="radio"]:checked + label:before {
    background: rgba(0, 0, 0, 0) url("../../../images/checkbox-sidexa-new-red.png") no-repeat scroll 0 0;
    border: 0;
    cursor: pointer;
}

.sidexa2 .HelpClient > div {
    vertical-align: top;
    width: 20%;
    display: inline-block;
}

.sidexa2 .HelpClient .inputForm {
    width: 50%;
}

.sidexa2 .HelpClient .labelForm > div {
    margin-top: 10px;
    height: 30px;
}

.sidexa2 .HelpClient .inputForm > div {
    margin-top: 10px;
    height: 30px;
}

.sidexa2 .HelpClient .inputForm input {
    width: 200px;
}

.sidexa2 .HelpClient .inputForm textarea {
    width: 200px;
}

.sidexa2 .panier-input .zoom-button {
    background: rgba(0, 0, 0, 0) url("../../../images/zoom-button.png") no-repeat;
    display: block;
    height: 13px;
    position: absolute;
    right: 15px;
    bottom: 15px;
    width: 13px;
}

.sidexa2 td.caracteristiques-details center .DispoECPF {
    background-color: rgb(97, 195, 32);
    color: rgb(255, 255, 255);
    padding: 8px 20px 8px 20px;
    width: auto;
}

.sidexa2 td.caracteristiques-details center .DispoE {
    background-color: rgb(97, 195, 32);
    color: rgb(255, 255, 255);
    padding: 8px 20px 8px 20px;
    width: auto;
}

.sidexa2 td.caracteristiques-details a.tooltips {
    position: relative;
    display: inline;
    background-color: rgb(97, 195, 32);
    color: rgb(255, 255, 255);
}

.hors-catalogue .detail {
    width: auto;
}

.hors-catalogue .reference-produits-container {
    margin-top: 15px;
}

.sidexa2 .reference-produits-container .cart-action, .sidexa2 .reference-produits-container .ht{
    margin-top: 0px;
}

.sidexa2 .reference-produits-container .cart-option a, .sidexa2 .reference-produits-container .cart-option input {
    width: 30%;
}


#main_frame {
    margin-top : 20px;
}

/* Popin ajout devis */
#popinDevis .modal-dialog {
    top: -50px !important;
}

#popinDevis .modal-dialog .modal-content {
    height: 640px;
}

#popinDevis .modal-dialog .modal-header {
    padding: 10px 30px 2px 15px;
    background: #0f5e96;
}

#popinDevis .modal-dialog .modal-header .close {
    opacity: 1;
    top: 5px;
}

#popinDevis .modal-dialog .modal-header .close button {
    background: none;
}

#popinDevis .modal-dialog .modal-header .close button span {
    color: #fff;
    font-size: 20px;
    font-weight: normal;
}
#popinDevis .modal-dialog .modal-body {
    height: 90%;
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    font-size: 12px;
    opacity: 0;
    filter: alpha(opacity=0);
}
.tooltip.in {
    opacity: 0.9;
    filter: alpha(opacity=90);
}
.tooltip.top {
    margin-top: -3px;
    padding: 5px 0;
}
.tooltip.right {
    margin-left: 3px;
    padding: 0 5px;
}
.tooltip.bottom {
    margin-top: 3px;
    padding: 5px 0;
}
.tooltip.left {
    margin-left: -3px;
    padding: 0 5px;
}
.tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: #ffffff;
    text-align: center;
    background-color: #000000;
    border-radius: 4px;
}
.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}
.tooltip.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000000;
}
.tooltip.top-left .tooltip-arrow {
    bottom: 0;
    right: 5px;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000000;
}
.tooltip.top-right .tooltip-arrow {
    bottom: 0;
    left: 5px;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000000;
}
.tooltip.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #000000;
}
.tooltip.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: #000000;
}
.tooltip.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000000;
}
.tooltip.bottom-left .tooltip-arrow {
    top: 0;
    right: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000000;
}
.tooltip.bottom-right .tooltip-arrow {
    top: 0;
    left: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000000;
}
.img-vignette{
    max-width: 100px;
    max-height: 100px;
}
.affected-image-failed{
    cursor: default;
}

/* Popin package */

#popinPackage {
    .modal-dialog {
        width: 900px;
        .modal-content {
            top: 0;
            .modal-header {
                padding: 10px 30px 10px 15px;
                border-bottom: none;
                .close {
                    position: absolute;
                    right: 9px;
                    top: 12px;
                    button {
                        background: url("../../../images/close_button.jpg") no-repeat center center;
                        height: 24px;
                        width: 21px;
                    }
                }
            }
            .modal-body {
                padding: 0 20px 20px;
                p {
                    font-size: 16px;
                }
                .btn-action {
                    text-align: center;
                    margin-top: -20px;
                    cursor: pointer;
                }
            }
        }
    }
}

input.input-quantity {
    -moz-appearance:textfield;
}

input.input-quantity::-webkit-outer-spin-button,
input.input-quantity::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.references-produit-list {
    .hide_detail {
        width: 188px !important;
        span {
            float: left;
            margin-left: 9px;
        }
        img {
            right: 50px !important;
        }
    }
}

.vehicle-band{
    padding-right: 5px !important;
}

#infos-generales {
    .bandeau-alerte {
        margin: -10px -10px -1px;
    }
    .show_space {
        height: 15px;
    }
}
.icon_down_arrow, .icon_down_arrow_2 {
    background: url(../../../images/sprite.png) no-repeat -298px -307px;
    width: 16px;
    height: 8px;
    display: inline-block;
    margin-top: 10px;
}
.icon_down_arrow {
    position: absolute;
}
.icon_up_arrow {
    background: url(../../../images/sprite.png) no-repeat -300px -323px;
    width: 13px;
    height: 8px;
    display: inline-block;
    margin-bottom: 3px;
}
.family {
    .icon_down_arrow_2, .icon_up_arrow {
        margin-top: 22px;
        margin-left: 160px;
    }
}

.subfamily {
    .icon_down_arrow_2, .icon_up_arrow {
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -3px;
    }
}

.popinFicheLiaison {
    .form_fiche_liaison {
        .form-upload-image {
            .input-file {
                cursor: pointer;
                background: #e6e6e6;
                text-align: center;
                padding-top: 5px;
                padding-bottom: 5px;
                line-height: 50px;
                height: 60px;
                overflow: hidden;
                position: static;
                width: 30%;
                opacity: 1;
                display: inline-block;

                .after-file-upload {
                    width: 0;
                    height: 0;
                }
            }
            .block-btn-image {
                text-align: center;
                width: 30%;
                button {
                    width: 100%;
                }
            }
        }
    }
}
.has-no-iam  {
    .loadGetPrice {
        display: none;
    }
    .hide_detail  {
        display: none;
    }
    span.refequip22 {
        margin-right: 15px;
    }
}

.opisto-nav-tab,
.opisto-nav-tab.active {
    padding: 0;
}

.opisto-nav-tab a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: white;
}