.out-of-store , .advertising-banner-limitation-label {
  height: 22px;
  background: $cyberYellow;
  width: 19%;
  float: right;
  position: relative;
  bottom: 15px;
  left: 15px;
  &.grid-block-store {
    top: 214px;
    left: 10px;
  }
  &.grid-advertising-banner {
    position: absolute;
    top: 223px;
    left: 0;
    .content-desc {
      left: -67px;
    }
  }
  &.hideStore {
    visibility: hidden;
    &.list-block-store {
      display: none;
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 35px 0;
    border-color: transparent transparent $white transparent;
  }
  span.content-store , span.limitation-label{
    font-weight: bold;
    color: black;
    font-size: 12px;
    margin-top: 3px;
    margin-left: 20px;
    display: inline-block;
    &.text-center {
      margin-left: 30%
    }
  }
  &.portal-customer-bloc-drop {
    width: 94%;
    .content-desc {
      left: -67px;
    }
  }
  &.portal-customer-bloc-distrib , &.portal-customer-bloc-drop {
    left: 5px;
  }
}
.section_cart--body-item--info_price {
  .out-of-store {
    width: 60%;
    margin: 2px 2px 0 0;
    & + .pricing_dispo {
      display: contents;
    }
  }
  .advertising-banner-limitation-label {
    width: 75%;
    .content-desc {
      left: -63px;
    }
  }
}
.ps-out-of-store , .ps-advertising-banner{
  width: 91%;
  left: 15px;
  top: 6px;
  float: unset;
  &::before {
    border-color: transparent transparent $lightgrey transparent;
  }
}

#popinFpPneus {
  .ps-out-of-store , .ps-advertising-banner {
    left: 31px;
    top: 0;
    margin-top: 10px;
  }
  .ps-advertising-banner {
    margin-bottom: 10px;
    .content-desc {
      width: 248px;
    }
  }
}

#popinFicheProduit {
  .eco {
    padding-top: 10px ;
  }
  .ps-advertising-banner {
    margin-top: 5px;
    margin-bottom: 5px;
    top: 20px;
    z-index: 10;
  }
}