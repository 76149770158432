html {
  -webkit-tap-highlight-color: black(0);
  /* Prevent tap highlight on iOS/Android */
  -webkit-text-size-adjust: 100%;
  /* Prevent automatic scaling on iOS */ }

.login-page {
  background: #FFFFFF;
  color: #000000;
  line-height: 1;
  font-family: Arial,sans-serif;
  font-size: 13px;
}

html,
body,
img,
fieldset,
abbr,
acronym {
  border: 0; }

th,
code,
cite,
caption {
  font-weight: normal;
  font-style: normal;
  text-align: left; }

address {
  font-style: normal; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

img {
  display: block;
  max-width: 100%; }

ol,
ul {
  list-style: none; }

q:before,
q:after {
  content: ''; }

input:focus,
input[type="search"]:focus {
  outline-offset: -2px; }

input[type="search"] {
  -webkit-appearance: none;
  /* 1 */
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* 2 */
  box-sizing: border-box; }

/* All Stuff to hide */
.hidden-D {
  display: none; }

.hidden-L {
  left: -2000px;
  position: absolute;
  width: 200px;
  float: left; }

.hidden-T {
  text-indent: -200em; }

/* width fix layout */
.home-container {
  min-width: 1000px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto; }

/* all stuff to hide */
.home-container header form button, .home-container section.presentation .button-wrapper form .affiliate {
  background-color: #E50616;
  border: none;
  color: #FFF;
  text-transform: uppercase;
  cursor: pointer; }

.home-container section.presentation .button-wrapper #video-pres {
  margin-right: 15px;
  background-color: #FFF;
  border: none;
  color: #045C9D;
  text-transform: uppercase;
  cursor: pointer; }

  .home-container header:after {
    content: "";
    display: table;
    clear: both; }
  .home-container header img {
    float: left;
    width: 345px;
    margin: 7px 45px 0px 0; }
  .home-container header form {
    position: relative; }
    .home-container header form:after {
      content: "";
      display: table;
      clear: both; }
    .home-container header form .form-box {
      float: left;
      width: 205px;
      margin-right: 20px; }
    .home-container header form label {
      text-transform: uppercase;
      color: #000;
      font-size: 16px;
      font-weight: bold;
      display: block;
      padding: 10px 0; }
    .home-container header form input {
      display: block;
      width: 195px;
      height: 48px;
      color: #000;
      background: #fff;
      border: 1px solid #E5E5E5;
      padding-left: 10px; }
    .home-container header form button {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 50px;
      width: 160px; }
    .home-container header form ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #E5E5E5;
      font-style: italic; }
    .home-container header form :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #E5E5E5;
      opacity: 1;
      font-style: italic; }
    .home-container header form ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #E5E5E5;
      opacity: 1;
      font-style: italic; }
    .home-container header form :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #E5E5E5;
      font-style: italic; }
    .home-container header form .error {
      position: absolute;
      bottom: -78px;
      right: 0;
      width: 610px;
      background-color: #FE9900;
      color: #FFF;
      font-weight: bold; }
      .home-container header form .error .error-wrapper {
        padding: 20px;
        display: block; }
        .home-container header form .error .error-wrapper .error-2 {
          display: block;
          font-weight: normal;
          padding-top: 5px; }
    .home-container header form .no-right {
      bottom: -110px;}
  .home-container section {
    margin-top: 30px; }
    .home-container section h3 {
      font-size: 21px;
      margin-bottom: 12px; }
    .home-container section:after {
      content: "";
      display: table;
      clear: both; }
    .home-container section.presentation {
      height: 330px;
      background: url("../images/img_homepage.png");
      border-top: 4px solid #E50616;
      text-align: center;
      color: #FFF; }
      .home-container section.presentation .pres-wrapper {
        width: 555px;
        margin: 100px auto 0; }
      .home-container section.presentation h1.text-1 {
        font-size: 42px;
        margin-bottom: 15px; }
      .home-container section.presentation h3.text-2 {
        margin-bottom: 15px;
        font-size: 20px; }
      .home-container section.presentation .text-3 {
        font-size: 12px; }
      .home-container section.presentation .button-wrapper {
        margin-top: 15px;
        display: inline-block; }
        .home-container section.presentation .button-wrapper button {
          font-weight: bold;
          height: 50px;
          padding: 0 25px;
          width: 235px; }
        .home-container section.presentation .button-wrapper form {
          display: inline-block; }
    .home-container section.reassurance {
      text-align: center;
      color: #6D6D6D; }
      .home-container section.reassurance h3 {
        color: #045C9D; }
      .home-container section.reassurance .rea-text {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px; }
      .home-container section.reassurance .rea-point {
        font-size: 16px;
        width: 250px;
        padding: 0 10px;
        float: left;
        position: relative; }
        .home-container section.reassurance .rea-point:after {
          content: '';
          background-color: #E5E5E5;
          position: absolute;
          height: 190px;
          width: 1px;
          top: 42px;
          right: 0; }
        .home-container section.reassurance .rea-point:last-child:after {
          content: none; }
        .home-container section.reassurance .rea-point .point::after {
          background: url("../images/sprite.png") no-repeat;
          overflow: hidden;
          text-indent: -9999px;
          text-align: left;
          content: '';
          width: 135px;
          height: 135px;
          display: block;
          margin: 5px auto 0; }
        .home-container section.reassurance .rea-point .precis::after {
          background-position: -10px -5px; }
        .home-container section.reassurance .rea-point .rapide::after {
          background-position: -10px -154px; }
        .home-container section.reassurance .rea-point .simple::after {
          background-position: -10px -303px; }
        .home-container section.reassurance .rea-point .complet::after {
          background-position: -10px -452px; }
          .home-container section.reassurance .rea-point .complet::after:after {
            content: "";
            display: table;
            clear: both; }
    .home-container section.platform {
      background-color: #045C9D;
      color: #FFF !important;
      text-align: center;
      padding-top: 1px;
    }
    .home-container section.platform h3 {
      background-color: #045C9D !important;
      color: #FFF !important;
      padding-top: 1px !important;
    }
    .home-container section.platform .thumbnail h3 {
    text-align: left;
    }
    .home-container section.platform .thumbnail {
      width: 313.33333px;
      float: left;
      padding: 0 10px;
    }
    .home-container section.platform .thumbnail .platform-list {
      padding: 0;
    }
    .home-container section.platform .thumbnail ul {
      margin-bottom: 0;
    }
    .home-container section.platform .thumbnail ul li {
      list-style-type: disc;
      text-align: left;
      width: 230px;
      margin: auto;
      padding-bottom: 10px;
      font-size: 16px;
    }
    .video-container {
      display: none;
    }

  .home-container header form .session-max .error-wrapper {
    padding: 12px;
    display: block;
  }

  .general-footer {
    display: block;
    position: relative;
    background-color: #0f5e96;
    width: 1000px;
    margin: 0 auto;
  }
  .general-footer .footer-holder p {
    margin: 0 0 8.5px;
    padding: 20px;
  }

  .footer-holder {
    font: 13px/16px Arial,Helvetica,sans-serif;
    color: #fff;
  }
  .general-footer .footer-holder p a {
    color: #fff;
    background: 0 0;
    text-decoration: none;
  }
  .general-footer .footer-holder p a:hover {
    color: #b2b2b2;
    text-decoration: none;
  }
/*# sourceMappingURL=home.css.map */
.login-page .h1, .login-page .h2, .login-page .h3, .login-page .h4,
.login-page .h5, .login-page .h6, .login-page h1, .login-page h2,
.login-page h3, .login-page h4, .login-page h5, .login-page h6 {
  font-family: inherit;
  font-weight: 700;
  line-height: inherit;
  color: #fff;
}
.to-login-container {
  display: none;
}

#forgotPassword {
  bottom: -18px;
  display: block;
  position: absolute;
  text-decoration: underline;
  font-size: 1.1em;
}

.pic-forgot-password {
  background-image: url(../../../../images/pic-forgot-password.png);
  background-size: 120px;
  display: block;
  width: 150px;
  height: 150px;
  background-repeat: no-repeat;
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
}
.modalForgotPasswordSuccess .close {
  font-size: 30px;
}
.popin .modal.modalAccountForgotPassword.modalForgotPasswordSuccess .modal-dialog .modal-header {
  padding: 0;
  padding-bottom: 15px;
}
.popin .modal.modalForgotPasswordSuccess .modal-dialog .modal-body {
  display: flex;
}
.popin .modal.modalAccountForgotPassword.modalForgotPasswordSuccess .modal-dialog {
  width: 790px;
}
.modalForgotPasswordSuccess .text-modal-container h4 {
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  line-height: 35px;
}
.modalForgotPasswordSuccess .text-modal-container p {
  font-size: 17px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 35px;
  color: #333;
}

.captcha-google-privacy {
    color: grey;
    font-size: 0.8em;
    margin-top: 10px;
}