#popinProductStatusAlert {
    z-index: 100000;
    .modal-header {
        h4 {
            text-transform: uppercase;
            width:170px;
        }
    }
    .modal-body {
        text-transform: initial;
        #close-popin {
            background-color: $redE;
        }
        a.tooltips.status-tooltips {
            height: 17px;
            left: 9px;
            background-color: transparent;
            cursor: pointer;
            &:not(.status-popin-tooltip) {
                width: 17px;
            }
            &.status-popin-tooltip {
                z-index: 99;
                cursor: unset;
                padding: 0;
                &.left-35-percent {
                    left: 35%;
                }
                &.left-55-percent {
                    left: 55%;
                }
            }
        }
        a:hover.tooltips.status-tooltips span {
            left: -10px;
            border-color: $greyBC;
            &.status-popin-tooltip {
                left: 0px;
            }
        }
        a.tooltips.status-tooltips span:before {
            left: 6%;
            border-bottom-color: $greyBC;
        }

        a.tooltips.status-tooltips span:after {
            left: 6%;
        }
        .ml-15 {
            margin-left: 15px;
        }
        .ellipsis {
            text-overflow: ellipsis;
        }
        .status-popin {
            font-size: 11px;
            white-space: nowrap;
            overflow: hidden;
        }
        .text-bold {
            font-weight: bold;
        }
    }
}
