@import '../vendor/colors';
/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 12 mai 2017, 12:16:37
    Author     : jeacharr
    US         : 1314
*/


.search-pieces .dropdown {
    margin-top: 22px;
    padding: 19px 0;
    background:#e10f21;
    position: relative;
    z-index: 3;
}
.search-pieces .input-group-addon {
    border: 1px solid #e10f21;
    color : #fff;
    font-size: 14px;
}
.search-pieces .input-group-addon #select-choice{
    font-weight:bold;
    color: #fff;
}
.search-pieces {
    .form-search-vehicule {
        .input-group {
            .form-control{
                font-family: Arial, sans-serif;
                color: #b2b2b2;
                font-size: 13px;
                font-weight: bold;
                height: 50px !important;
                margin-top: 0 !important;
            }
            .has-immatriculation{
                padding-left: 135px;
            }
            .search-parts-bg.not-dirty {
                background: url(../../../images/sprite.png) no-repeat 0px -467px;
                width: 519px;
                height: 36px;
                position: absolute;
                z-index: 3;
                display: block;
                margin: 7px 0 0 7px;
                cursor: text;
            }
        }
        .input-group-btn {
            background: #fff none repeat scroll 0 0;
            .btn {
                padding: 5px 2px 5px 12px;
                border-left: 2px solid #e2e2e2;
                height: 36px;
                cursor: initial;
            }
        }
    }
}
.form-search-express .input-group {
    border-top: 2px solid #e2e2e2;
    border-right: 2px solid #e2e2e2;
    border-bottom: 2px solid #e2e2e2;
}
.form-search-express .input-group .delete-search{
    background: url(../../../images/sprite.png) no-repeat -252px -289px;
    width: 27px;
    height: 26px;
    display: inline-block;
    position: absolute;
    z-index: 3;
}
.search-pieces ul.dropdown-menu {
    width:100%;
/*    display:none;*/
    top: 47px;
}

.search-piece-icon {
    height: 50px;
    display: flex;
    background-color: #FFF;
    padding: 0;
}

.search-piece-icon .separator{
    margin-top: 5px;
    height: 40px;
    display: block;
    border-left: 2px solid #e2e2e2;
}

.search-piece-icon img {
    margin: auto;
}
.ariane .no-padding{
    padding : 0;
}

.ariane .form-control:focus{
    border-color: #cccccc;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.autocomplete_block {
    box-shadow: 0px 0px 1px rgba(184, 173, 184, 1);
    height: 52px;
}

#form_autocomplete_breadcrumb {
    right: -1px;
}

.wrapper-relative .autocomplete_sm_size{
    width : 500px !important;
}

.wrapper-relative .autocomplete_md_size{
    width : 600px !important;
}

.wrapper-relative .autocomplete_lg_size{
    width : 700px !important;
}

.pieces_1.search{
    left: 90px;
    font-size: 13px;
}

.pieces_1_2.search{
    margin-left: -10px;
}

.reference-produits-container{
   margin-top: 5px;
}

.produits.result{
    margin-top: 10px;
}

.grey-table{
    background: #f7f7f7;
}

.produits.grey-table {
    padding: 15px;
}

a.all-equiv {
    float: right;
    background: #0f5e96;
    padding: 0 10px;
    height: 50px;
    line-height: 50px;
    margin: 0 !important;
}

.equiv-product a.all-equiv {
    display: none !important;
}

.reference-produits-container .produits > table .complements-produit,
.reference-produits-container .produits > table .complements-produit.hover tr{
    background-color: white !important;
}

table.equiv-product{
    width: 100%;
}

table td.equiv-product-td{
    padding: 0 !important;
}

table.equiv-product tr td:not(.fiche){
    background-color: white;
}

.references-produit-list.search-list{
    background-color:#f7f7f7; 
    margin-left: -30px; 
    margin-right: 0;
}

.table-result-search-equi .loading-block{
    border: none;
}

.reference-produits-container .produits.origin-equivalence {
    padding: 0 15px;
}

.references-produit-list table.table-condensed tr,
.reference-produits-container table.table-condensed tr.panier-input.containerDispo.hover{
    background-color: white;
}

.reference-produits-container div.bloc-reference div.produits{
    padding: 0px 15px 0px 15px;
}

.grey_line.origin{
    margin-bottom: 20px;
}

.references-produit-list.equiv{
    margin-left: -15px;
    margin-right: -15px;
}

div.btn-classic {
    a.init-filter {
        padding: 0;
        .reset-bg {
            background: url(../../../images/sprite.png) no-repeat -254px -206px;
            width: 28px;
            height: 27px;
            display: inline-block;
        }
    }
}

.img-vinoe-verfied{
  height: 20px;
  width: 100px;
  margin-left: 5px;
  margin-top: -4px;
}

.img-vinoe-verfied-global{
  height: 20px;
  width: 100px;
  margin-left: 5px;
  margin-top: -4px;
}

.inputradioPneus22 img.img-pneu-onglet-marques{
  max-width: 130px;
  max-height: 80px;
}

div.affectation-origine {
    &.matched-sidexa {
        div.results {
            div.results-container {
                div.result{
                    &.verified{
                        .img-vinoe-verfied{
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
    div.results {
        div.results-container {
            div.result {
                .img-vinoe-verfied{
                    display: none;
                }
            }
        }
    }
}


div.affectation-origine {
    div.results {
      div.results-container {
        div.result{
          &.verified{
            .img-vinoe-verfied-global{
              display: inline-block;
            }
          }
        }
      }
    }
}

.modal-body {
    .v3_pricing {
        .bloc-contact-your-distributor {
            a.tooltips {
                img + span.peinture-span.orange-span {
                    margin-left: 26px !important;
                }
            }
        } 
        .tooltips {
            span {
                margin-left: 2.3em !important;
            }
        }
    }
    #ficheproduit-1 {
        .v3_pricing {
            .bloc-contact-your-distributor{
                a.tooltips {
                    span.peinture-span.orange-span {
                        margin-left: 26px !important;
                    }
                }
            }
        }
    }
    .istyle {
        bottom: 18px;
        left: 127px;
    }
    .textalignleft {
        text-align: left;
    }
}
.scrolled {
    .portal-reference-page {
        &.wrapper {
            padding-top: 72px;
        }
        .reference-produits-container {
            margin-top: 11px;
        }
        .promo-img-container {
            margin-bottom: 11px;
        }
    }
}

.mec-filter-popup {
    background-color: $white;
    z-index: 10;
    padding: 15px;
    border: 1px solid $black;
    position: absolute;
    width: 500px;
    color: $black;
    top: 55px;
    right: -10px;
    font-size: 13px;
    &:before, &:after {
        left: 85%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    &:before {
        border-color: transparent;
        border-bottom-color: $black;
        border-width: 22px;
        margin-left: -22px;
        top: -51%;
    }
    &:after {
        border-color: transparent;
        border-bottom-color: $white;
        border-width: 22px;
        margin-left: -22px;
        top: -50%;
    }
}
