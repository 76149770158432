@import '../../vendor/colors';
@import '../../advertisingBanner';
$arial:  Arial,"Helvetica Neue",Helvetica,sans-serif;
.p-list{
  padding: 15px !important;
}
.product-content{
  border: 1px solid #dfdfdf;
  padding: 15px;
  margin-top:25px;
  background: #fff;
  //.block-info{
  //  background: #61c320;
  //  margin-top: -15px;
  //  padding: 5px;
  //  color: #fff;
  //  font-family: $arial;
  //}
  .replacements-ref, .tooltips-text{
    margin-top: 7px;
    line-height: 1.7;
    a{
      cursor: pointer;
      font-size: 11px;
      font-weight: 700;

      &.product-link{
        text-decoration: underline;
      }
    }
  }
  .promo-band{
    background: #ff0018;
    color: #ffd200;
    position: absolute;
    top: 40px;
    z-index: 1;
    transform: rotate(-45deg);
    width: 210px;
    left: -55px;
    text-align: center;
    padding: 3px 0px 3px 0px;
    font-weight: bold;
  }
  .p-description{
    color: #6e6e6e;
    .p-title {
      font-family: $arial;
      font-weight: bold;
      color: #000;
      padding-top: 6px;
      padding-bottom: 6px;
      cursor: pointer;
    }
    a{
      cursor: pointer;
      color: #6e6e6e;
    }
  }
  .p-ref{
    font-family: $arial;
    font-weight: bold;
    color: #6e6e6e;
    margin-left: 10px;
  }
  .p-brand{
    color: #0f5e9b;
    font-style: italic;
  }
  .p-block-btn {
    a {
      float: left;
      max-width: 98px;
      position: relative;
      display: block;
      color: #fff;
      padding: 10px 22px 10px 33px;
      margin-top: 9px;
    }
    .btn-cart {
      background-color: #eb212e;
      background-image: url("../../../../images/panier_white.png");
      background-repeat: no-repeat;
      background-position: 8px center;
      width: 109px;
      float: left;

      &:before {
        background: url("../../../../images/panier_white.png") no-repeat;
      }
    }

    i.picto-panier {
      position: relative !important;
    }
  }
  .btn-cart-selected {
    width: 68px;
    background: url(../../../../images/coche-panier.png) no-repeat 44%;
    height: 37px;
    margin-top: 9px;
    margin-left: 17px;
  }
    .product-btn{
      &.add-quo-btn{
        background: #0f5e9b;
        cursor: pointer;
        position: relative;
        padding: 10px 30px 10px 36px;
        color: #fff;
        margin-top: 10px;
        &:before{
          background: url(../../../../images/devis_white.png) no-repeat;
          background-size: 14px;
          height: 24px;
          top: 6px;
          content: '';
          width: 20px;
          display: inline-block;
          position: absolute;
          left: 10px;
        }
      }
    }


  .block-quantity{
    float: left;
    height: 84px;
    margin-top: 10px;
    width: 48px;
    .cart-action{
      margin-top: 0;
      height: 100%;
      width: 40px;
      border: 2px solid $lightgrey2
    }
    input{
      width: 34px;
      text-align: center;
      border: 0;
      outline: none !important;
      font-weight: bold;
      margin-left: 1px;
    }
    a{
      color: $darkGrey;
    }
    .qty-btn{
      display: block;
      width: 36px;
      text-align: center;
      height: 32px;
      position: relative;
      i{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
    .minus {
      height: 30px;

      i {
        transform: translate(-50%, -70%);
      }
    }
  }
  .p-thumbnails{
    position: relative;
    height:120px;
    img{
      position: absolute;
      top: 50%;
      left:50%;
      transform: translate(-50%,-50%);
      &.brand-image{
        top: 90%;
      }
    }
  }
}
.bg-white{
  background-color: #ffffff;
}
.zoom-button{
  width: 18px;
  height: 18px;
  background: url(../../../../images/zoom_button.png) top left no-repeat;
  display: block;
  position: absolute;
  right: 4px;
  bottom: -5px;
}
.pricing-image{
  overflow: hidden;
  margin-top: -15px;
  .zoom-button{
    bottom: 2px;
  }
  .fav-btn{
    top: 6px;
  }
}
#popinFicheProduit{
  .fav-btn{
    z-index: 10;
  }
}
.reference-produits-container table.table-condensed tr.panier-input.containerDispo.hover, .references-produit-list table.table-condensed tr{
  background: #ffffff;
}
.reference-produits-container .produits>table>tbody>tr>td:not(.fiche){
  background: #ffffff;
}
.v3-pricing-part {
  div.dispo-details.dispoA {
    width: 100%;
  }

  .dispo-price-bloc {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
  &.v3_pricing {
    .bloc-contact-your-distributor strong{
      margin-left: 0px;
      margin-right: 0px;
      vertical-align: 1px;
    }
    .relative .dispo-details .datepicker-pricing {
      background-position-x: 100px!important;
      margin-right: 0;
      width: 130px;
      margin-left: 6px;

    }
    .availability-pricing{
      padding: 0;
      a.pfpt{
        margin-left: -190px;
      }
    }
    .text-description-green{
      margin-left: 2px;
      margin-right: 15px;
      vertical-align: 1px;
    }
    .loader-dispo-price{
      padding: 0 15px;
    }
    &.search-without-vehicle{
      .dispo-container-part{
        margin: 0;
      }
      .relative{
        padding: 0;
      }
      .disponibility-column{
        padding: 0;
        margin: 0;
      }
      .bloc .dispo-details{
         height: auto;
      }
      .bloc .update-bloc-pricing{
        height: auto;
      }
      .bloc .update-bloc-pricing img {
        vertical-align: -3px;
      }
    }
    .available-conditions{
      .tooltips{
        position: absolute !important;
        top: 0;
        right: -4px;
      }
      .text-description-green{
        margin-left: -5px;
        margin-right: 13px;
      }
    }
    .with-contact-us {
      .text-description-green {
        display: block;
        height: auto;
        margin-right: 0;
        &.inline-span {
          display: inline;
        }
      }
    }
  }
}
.block-informatons{
  min-height: 119px;
}
.block-informatons.col-xs-4 .p-ref {
  white-space: pre-line;
}
.ref-equi{
  border: 1px solid #dfdfdf;
  border-top: 0;
  overflow: hidden;
  color: #fff;
  a{
    color: #fff;
  }
}
.productsheet-link{
  line-height: 22px;
  padding-left: 15px;
  padding-right: 15px;
  border-right: 1px solid #eb212e;
  display: inline-block;
  margin-top: 14px;
  color: #444 !important;
  cursor: pointer;
}
.associated-family{
  border: 1px solid #dfdfdf;
  padding: 15px 15px 15px 25px;
  border-top: 0;
  overflow: hidden;
  .productsheet-link{
    margin-top: 0px;
  }
  .famille_associee{
    color: #000;
  }
}

.ref-equi, .ref-equi a > span.ft-links {
  color: #0f5e96;
}

.sidexa2{
  .popInCoraSidexa{
    .btn-action{
     text-align: center;
     margin-top: -20px !important;
     margin-right: 298px !important;
    }
  }
}

.remise-red {
  color : #eb212e !important;
}

.discount-black {
  color : black !important;
}
.must-choice-models {
  font-weight: bold;
  padding-bottom: 15px;
  color: $redDark;
  font-size: 11px;
}

.modal-content {
  .modal-header {
    .repairTimeTitle, .models-selection-title {
      text-align: center;
      margin: 0px;
      font-size: large;
      color: white;
      text-transform: initial;
    }
  }
  .modal-body {
    .repairTimeRow, .models-selection-row {
      text-align: center;
      font-size: 20px;
      label, span.model-selection-mid {
        clear: both;
        float: left;
        width: 100%;
      }
      span.model-selection-mid {
        cursor: pointer;
        color: $blue;
        line-height: 2;
        &.selected-model {
          pointer-events: none;
          color: $grey56;
        }
      }
    }
    .alertMessage {
      color: $red_dark;
      text-align : center;
      font-size: 15px;
      margin-bottom: 18px;
    }
    a#optionModels {
      font-weight: bold;
      margin-left: 17px;
      font-size: 15px;
    }
  }
}
div.componentRepairTime {
  background: $white;
  border: 1px solid #0f5e96;
  border-top: none;
  position: absolute;
  width: 60%;
  padding: 4px;
}
.popin
.popinTempsBaremes {
  #optionsRepairTime {
    margin-left: 17px;
    font-size: 15px;
  }
  .row {
    margin-bottom: 10px;
  }
}
.folder-disabled-btn {
  a {
    opacity: 0.5;
  }
  .add-cart-btn:hover ~ .folder-disabled-btn-tooltip {
    display: block;
    bottom: -5px;
  }
  .add-quo-btn:hover ~ .folder-disabled-btn-tooltip {
    display: block;
    bottom: -51px;
  }
  .ps-cart-btn:hover + .folder-disabled-btn-tooltip {
    display: block;
    bottom: 13px;
  }
  .btn-devis:hover + .folder-disabled-btn-tooltip {
    display: block;
    bottom: -40px;
  }
  .folder-disabled-btn-tooltip {
    display: none;
    width: 190px;
    position: absolute;
    background: white;
    z-index: 1;
    border: 1px solid;
    bottom: -50px;
    text-align: center;
    left: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    padding: 5px;
    border-radius: 3px;
    .chevron-up {
      position: absolute;
      top: -10px;
      right: 40%;
    }
  }
}
