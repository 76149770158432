/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 7 sept. 2016, 11:37:24
    Author     : hafamri
*/

div.main.container div.main div.affectation-origine div.row div.centre div.results div.result div.main.container
div.references-produit-list div.reference-produits-container div.bloc-reference div div.produits table.table-condensed tbody .panier-input.border_space
td.caracteristiques-details center a.tooltips {
    left: -20px !important;
}

.dispo-details input {
    width: 178px !important;
    margin-right: -77px !important;
}

.range-from,
.range-to
{
    display:none;
}

span.range-ie9{
    display: inline !important;
    font-size: 13px;
    margin-left: 10px;
}

.mileage{
    width: 150px;
    border: 1px solid #dedede;
    padding: 5px;
}