@import '../../vendor/colors';

$arial:  Arial,"Helvetica Neue",Helvetica,sans-serif;

.pricing-block {
  padding-left: 0;
  padding-right: 0;
  .links-container{
    padding-top: 15px;
    a{
      text-decoration: underline;
    }
  }
  .block-availability{
    padding-left: 5px;
    padding-right: 5px;
  }
  .block-prices{
    padding-left: 5px;
    padding-right: 15px;
    margin-top: 8px;
    .links-container{
      margin-top: 10px;
      .pfpt{
        line-height: 25px;
      }
      .load-pfpt{
        height: 25px;
        width: 25px;
      }
    }
  }
  .title-rate{
    font-family:$arial;
    font-size: 12px;
    color: #6a6a6a;
  }
  .rate-ttc{
    font-family: $arial;
    font-weight: bold;
    font-size: 18px;
    color: #0f5e9b;
  }
  .rate-ht{
    font-family: $arial;
    font-size: 14px !important;
    color: #000;
  }
  // fix font-size for when pricing is activated
  .rate-ht.complete-pricing-ht{
    font-family: $arial;
    font-size: 14px !important;
    color: #000;
  }
  .rate-type{
    font-size: inherit;
  }
  center{
    text-align: left;
    .dispo-details{
      text-align: center;
    }
  }
  div.dispo-details.dispoA{
    width: 100%;
    text-align: center;
  }

  .discount-container,
  .net-price-container {
    display: none;
  }

  &.complete-price {
    .discount-container {
      display: block;
      padding-left: 12px;
      position: relative;

      .brd-left {
        height: 38px;
        width: 1px;
        display: inline-block;
        border-left: 1px solid #999;
        line-height: 0;
        position: absolute;
        left: 8px;
        top: 11px;
      }
    }

    .remise-pourcent {
      font-weight: bold;
      color: #111;
      font-size: 12px;
      float: left;
      padding-top: 3px;
      margin-right: 5px;
    }

    .discount-complementary-container {
      font-weight: bold;
      color: $redE;
      float: right;
      padding-top: 5px;
      max-width: 50px;
    }
    .discount-complementary,.discount-complementary-2 {
      clear: both;
      font-size: 0.84em;
    }  
    .rate-ttc {
      font-size: 14px;
      .rate-type{
        font-size: inherit !important;
      }
    }
    .pull-left {
      width: 98px;
    }
    .net-price-container {
      display: block;
      padding-bottom: 10px;


      .net-price-rate-ttc, .net-price-rate-ht {
        color: #eb212e;
        font-weight: bold;
        font-size: 18px;
        font-family: $arial;
      }
    }
  }
}
.ht-price {
  .rate-ht {
    font-size: 14px;
  }
}
.ht-price.rate-ht.complete-pricing-ht {
  font-size: 14px !important;
}

.price-level-bloc {
  .selectPriceLevel {
    width: 220px;
    margin-top: 5px;
    margin-bottom: 6px;
    padding: 2px;
  }
  .bootstrap-select.btn-group {
    .dropdown-toggle {
      .filter-option {
        color: $redDark;
        font-weight: 700;
        font-size: 16px;
        small {
          font-size: 14px;
        }
        &.price-level-li-web-price {
          small {
            background-color: #ffd200;
            padding: 3px;
            margin-left: 5px;
          }
        }
        &.smaller {
          font-size: 14px;
          small {
            font-size: 13px;
          }
        }
      }
      .caret {
        top: 40%;
        right: 5px;
      }
    }
    .dropdown-menu {
      li a span.check-mark {
        display: none;
      }
      li:not(.disabled) {
        >a:hover {
          small , span {
            color : $redDark;
            font-size: 14px;
          }
          small {
            font-size: 12px;
          }
        }
        >a.based:hover {
          small , span {
            color: $black;
          }
        }
        >a.price-level-li-web-price:hover {
          small {
            background-color: #ffd200;
            padding: 3px;
            margin-left: 5px;
          }
        }
        >a.smaller:hover {
          span {
            font-size: 12px;
          }
          small {
            font-size: 11px;
          }
        }
      }
      li.selected {
        > a {
          small , span.text {
            color : $redDark;
            font-size: 14px;
            font-weight: normal;
          }
          small {
            font-size: 12px;
          }
        }
        > a.based {
          small , span {
            color: $black;
          }
        }
        > a.smaller {
          span {
            font-size: 12px;
          }
        }
      }
      li {
        > a small , small {
          font-size: 12px;
        }
      }
      > li {
        > a {
          color : $redDark;
          font-size: 14px;
          padding-top: 5px;
          padding-bottom: 5px;
        }
        > a.based {
          color: $black;
        }
        > a.price-level-li-web-price {
          small {
            background-color: #ffd200;
            padding: 3px;
            margin-left: 5px;
          }
        }
        > a.smaller {
          font-size: 12px;
          small {
            font-size: 11px;
          }
        }
      }
    }
  }
  .bootstrap-select > .btn {
    min-height: 0px;
    padding: 4px;
  }
  .btn-default.active , .btn-default:active , .btn-default:focus , .btn-default:hover , .open>.btn-default.dropdown-toggle {
    background-color: $white;
    border-color: $greyD3 !important;
  }
}

.ps-price-block{
  padding-left: 2px;
  padding-right: 0px;
  padding-top: 20px;
  padding-bottom: 15px;
  span,div{
    font-family:$arial;
    font-size: inherit;
  }
  .ttc-price{
    font-weight: bold;
    font-size: 18px;
    color: #0f5e9b;
    .rate-type{
      font-size: inherit !important;
    }
  }
  .rate-ht{
    color: #000;
  }
  .discount-rate{
    border-left: 1px solid #d7d7d7;
    padding-left: 10px;
  }
  .discount-rate-value{
    color: #000;
    font-weight: bold;
  }
  .links-container{
    padding-left: 15px;
    padding-top: 15px;
    .eco{
      position: relative;
    }
    .load-pfpt{
      position: absolute;
      top: -10px;
      margin-left: 12px;
    }
    a{
      text-decoration: underline;
    }
  }

  .discount-container,
  .net-price-container {
    display: none;
  }

  &.complete-price {
    .discount-container {
      display: block;
      padding-left: 12px;
      position: relative;

      .brd-left {
        height: 38px;
        width: 1px;
        display: inline-block;
        border-left: 1px solid #999;
        line-height: 0;
        position: absolute;
        left: 8px;
        top: 11px;
      }

      .title-rate-container{
        font-size: 0.9em;
      }
    }
    .remise-pourcent {
      font-weight: bold;
      color: #111;
      font-size: 13px;
      float: left;
      padding-top: 4px;
      margin-right: 5px;
    }
    .discount-complementary-container {
      font-weight: bold;
      color: $redE;
      float: right;
      padding-top: 5px;
      max-width: 50px;
    }
    .discount-complementary,.discount-complementary-2 {
      clear: both;
      font-size: 0.84em;
    } 
    .rate-ttc {
      font-size: 14px;
      .rate-type{
        font-size: inherit !important;
      }
    }
    .net-price-container {
      display: block;
      padding-bottom: 10px;

      .net-price-rate-ttc, .net-price-rate-ht {
        color: #eb212e;
        font-weight: bold;
        font-size: 18px;
        font-family: $arial;
      }
    }
    .price-level-bloc {
      margin-bottom : 5px;
      .price-level-input {
        display: none;
      }
      .price-level-label {
        position: relative;
        width: 20px;
        left: 10px;
      }
      .price-level-label:before {
        width: 15px;
        height: 15px;
        display: inline-block;
        content: '';
        border: 1px solid;
        border-radius: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
      }
      .price-level-label:after {
        width: 10px;
        height: 10px;
        content: '';
        background: $red;
        border-radius: 50%;
        position: absolute;
        display: none;
        transform: translate(-50%, -50%);
      }
      .price-level-input:checked + label:after {
        display: block;
      }
      label.price_level_radio {
        color: $red;
        font-weight: 600;
        font-size: 16px;
        span {
          font-size: 12px ;
        }
      }
      div:last-child {
        .price_level_radio {
          color : $grey56;
        }
        .price-level-label:after {
          background: $grey56;
        }
      }
    }
  }
}
h2.pricing-title{
  font-size: 22px;
  color: #000;
  font-family:$arial;
}
.ps-pricing-block{
  padding-bottom: 15px;
  padding-left: 15px;
  .availability-pricing{
    padding-left: 0;
    a.pfpt{
      text-decoration: underline;
    }
  }
  .block-quantity{
    .cart-action{
      margin-top: 0;
      height: 100%;
      width: 40px;
      border: 2px solid $lightgrey2;
    }
    input{
      width: 34px;
      text-align: center;
      border: 0;
      outline: none !important;
      font-weight: bold;
      margin-left: 1px;
    }
    .block-btn-action {
      float: left;
      a{
        color: $darkGrey;
        display: block;
        text-align: center;
        background: #fff;
        height: 32px;
        position: relative;
        margin-left: 1px;
        i{
          position: absolute;
          top: 50%;
          left:50%;
          transform: translate(-50%,-50%);
          pointer-events: none;
        }
      }
      .minus{
        i{
          transform: translate(-50%,-70%);
        }
      }
    }
    .qty-btn{
      display: block;
      width: 36px;
      text-align: center;
    }
  }
  .ps-product-btn{
    .btn-cart{
      background: #eb212e;
      .add_panel{
        margin-left: 0;
        margin-right: 0;
        pointer-events: none;
      }
      &:before{
        background: url("../../../../images/panier_white.png") no-repeat;
        pointer-events: none;
      }
    }
    a{
      overflow: hidden;
      position: relative;
      width: 60%;
      margin-left: 10px;
      padding: 10px 22px 10px 20px;
      text-align: right;
      color: #fff;
      cursor: pointer;
    }
    a:before{
      content: '';
      width: 20px;
      height: 20px;
      display: inline-block;
      position: absolute;
      left: 10px;
    }

    .btn-devis{
      background: #0f5e9b;
      margin-top: 12px;
      &:before{
        background: url("../../../../images/devis_white.png") no-repeat;
        background-size:13px;
      }
    }
  }
  .btn-cart-selected{
    width: 68px;
    background: url(../../../../images/coche-panier.png) no-repeat 44%;
    height: 37px;
    left: 6px;
  }
}
.fav-btn-pricing {
    .fa_star_2 {
      top: 10px !important;
      z-index: 999;
  }
}

#popinFicheProduit {
  .fav-btn-pricing {
    right: 10px;
    position: absolute;
    font-size: 20px;
  }
}

#ficheproduit-1 .ps-pricing-block .dispo-details.dispoA {
  height: auto !important;
  width: auto !important;

  &.with-contact-us {
    .tooltips {
      span {
        left: -247px;
      }
    }
  }
}
.discount-autover {
  font-weight: bold;
}
.disabled-change-qty {
  pointer-events: none;
}

.disabled-change-link {
  cursor: default;
}

.rft-rfa {
  font-weight: bold;
}