@import '../vendor/colors';
@import '../vendor/_font-family';

.popin {
  .modal.modalAccount {
    .modal-header {
      padding: 0px 14px;
    }
    z-index: 15 !important;
    .modal-dialog {
      width: 700px;
      .modal-body {
        .explain_message {
          padding: 14px;
          a {
            text-decoration: underline;
            color: $grey56;
          }
        }
      }
    }
    .border_bottom_blue {
      text-transform: uppercase;
      padding: 5px 18px 0px 0;
      font-weight: bold;
    }
    .border_bottom_blue:after {
      content: "";
      margin-top: 10px;
      margin-bottom: 10px;
      display: block;
      width: 80px;
      left: 0px;
      border-bottom: 4px solid $blue;
    }
    #validatePopinAccount {
      background: $blue;
      color: $white;
      display: inline-block;
      border: none;
      font-size: 12px;
      font-family: Arial, sans-serif;
      font-weight: bold;
      float: right;
      width: 20%;
    }
    input.validate {
      background: $blue;
      color: $white;
      display: inline-block;
      border: none;
      font-size: 12px;
      font-family: $family-arial-sans-serif;
      font-weight: bold;
      float: right;
      width: 20%;
    }
    .form_error {
      display: block;
      margin: 4px 0px 0px 5px;
      color: $red_dark;
      ul {
        li {
          list-style: none;
          background: none;
        }
      }
    }
    .row {
      margin: 2px auto;
      padding-top: 5px;
      font-family: $family-helvetica;
      font-size: 14px;
      input[type="text"] {
        border: 1px solid $greyC;
        padding: 0 11px 0 13px;
        height: 37px;
        margin-left: 0;
        width: 400px;
      }
    }
    .countMessage {
      color: $blue;
      padding: 10px;
      margin: 10px;
      &.alert-message {
        color: $red_dark;
        margin-top: -15px;
        margin-left: -9px;
        margin-bottom: 20px;
      }
    }
    .account-alert-success-validate-mail {
      color: $successGreen;
      background-color: $backgroundSucess;
      p {
        font-size: 22px;
      }
    }
    .account-alert-error-validate-mail {
      color: $redAlertError;
      background-color: $backgroundAlert;
      p {
        font-size: 22px;
      }
    }
  }
  .modal.modalUserInf {
    .modal-dialog {
      width: 440px;
    }
    .modal-header {
      margin: 10px
    }
    #validateUserInformation , #cancelUserInformation {
      background: $blue;
      color: $white;
      display: inline-block;
      border: none;
      font-size: 12px;
      font-family: Arial, sans-serif;
      font-weight: bold;
      float: right;
      width: 20%;
    }
    .form_error {
      display: block;
      margin: 4px 0px 0px 5px;
      color: $red_dark;
      ul {
        li {
          list-style: none;
          background: none;
        }
      }
    }
    .row {
      margin: 2px auto;
      padding-top: 5px;
      font-family: $family-helvetica;
      font-size: 14px;
      input[type="text"] {
        width: 100%;
      }
      .col-xs-4 {
        padding: 4px 0 0 13px;
      }
    }
    #cancelUserInformation {
      margin-left: 10px;
      background: $red_dark;
    }
  }
}

.layout_peinture , .consumable-body, .quotes_layout{
  .modalAccount , .modalUserInf{
    .modal-dialog {
      margin-top: 0px;
    }
  }
}
.account-alert-success, .flash-message-success {
  color: $successGreen;
  background-color: $backgroundSucess;
  border-color: $borderSuccess;
  font-size: 24px !important;
  font-weight: bold;
  text-align: center;
}
.account-alert-warning, .flash-message-warning {
  color: $warningOrange;
  background-color: $backgroundWarning;
  border-color: $backgroundWarning;
  font-size: 24px !important;
  font-weight: bold;
  text-align: center;
}
.account-alert-error {
  color: $redAlertError;
  background-color: $backgroundAlert;
  border-color: $borderAlert;
  font-size: 30px !important;
  font-weight: bold;
}
.account-alert, div[class*="flash-message-"] {
  position: relative;
  padding: 10px 10px 0px;
  margin-bottom: 5px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 14px;
}

#popinValidateAccount, #popin-password-updated {
  margin-top: 25.7vh;
  .border_bottom_red_180:after {
    border-bottom: 4px solid #0f5e96;
  }
  .modal-dialog{
    width: 715px;
  }
  .modal-content{
    .modal-body {
      .message-mail {
        margin-top: 26px;
        text-align: center;
        font-size: 20px;
        span.email {
          font-weight: bold;
        }
      }
      .btn-action {
        margin-top: 20px;
        .popin-btn {
          background-color: #0f5e96;
          cursor: pointer;
        }
      }
    }
  }
}

.account-alert .consult-mail {
  font-weight: 500;
  color: #0f5e96;
}
.message-validate-email:hover, .consult-mail {
  text-decoration: revert;
}

#popinInfoPaintCollection,
#popinInfoTreeCodeNotAllowed{
  color: $grey56;
  .modal-dialog {
    width: 800px;
    margin: 265px auto;
    .modal-body {
      width: 97%;
      margin: auto;
      padding: 20px 0 20px 0;
      p {
        line-height: 30px;
        font-size: 17px;
      }
      .btn-action {
        margin-top: 20px;
      }
    }
  }
}

.modalAccount #formCoordonnes #email-verification-resend-row {
  display: grid;
  grid-template-columns: calc(30% + 15px) 1fr;
}