div.cart.recap{
    background-color: #e6e6e6;
}

div.cart.recap .container{
    width: 1000px;
}

div.cart.recap div.results.commande{
    text-transform:uppercase;
    background-color: #e6e6e6;
}

div.cart.recap div.results.commande div.panier-resume{
    margin: 15px 0 25px 0;
}

div.cart.recap div.results.commande div.panier-resume strong{
    font-size: 16px;
    font-family: Arial, sans-serif;
    font-weight: bold;
}

div.cart.recap div.panier-resume h3{
    font-weight:normal;
    font-size: 15px; 
    width: 100%;
    margin-top: -18px;
    font-family: inherit;
    line-height: 20px;
}

div.cart.recap div.panier-resume h3 span.price{
    color: #0f5e96; 
    font-size:15px;
    font-weight: bold;
}

div.cart.recap div.centre div.btn-action{
    margin-bottom: 0px;
}

div.cart.recap div.infos-commande div.btn-action{
    margin-bottom: 20px;
}


div.cart.recap div.btn-action a.action-open-popin, div.cart.recap div.btn-action a.action-delete-all{
    background-color:#d80000; 
    border:solid 1px #d80000; 
    color:#fff;  
    margin-left: 0px;
    padding: 10px;
}

table.table.table-results.table-commande{
    background-color: #fff;
}

div.cart table.table.table-results.table-commande > thead > tr{
    border:none; 
    background-color:#e6e6e6;
}

div.cart table.table.table-results.table-commande > thead > tr > td{
    border:none; 
    background-color:#e6e6e6;   
}
div.cart table.table.table-results.table-commande > tbody {
    border:none;
    box-shadow: 0 6px 6px 0 rgba(0, 1, 1, 0.11); 
    -webkit-box-shadow: 0 6px 6px 0 rgba(0, 1, 1, 0.11);
    -moz-box-shadow:  0 6px 6px 0 rgba(0, 1, 1, 0.11);
    -o-box-shadow:  0 6px 6px 0 rgba(0, 1, 1, 0.11);
}
div.cart table.table.table-results.table-commande > tbody tr.head {
    border: 0px solid #fff;
    color: #6e6e6e;
}

div.cart table.table.table-results.table-commande > tbody th{
    text-transform:none; 
    color: #6e6e6e; 
    font-weight:normal;
    border:none;
    text-align:left; 
    padding-top: 15px;
}

div.cart table.table.table-results.table-commande > tbody th.first{
    text-align:initial; 
}

div.cart table.table.table-results.table-commande div.dispo-details.dispoA{
    width: 100%;
}

div.cart table.table.table-results.table-commande center a{
    position: absolute;
    left: 9px;
    text-decoration: none;
    bottom: -25px;
}

div.cart table.table.table-results.table-commande center .DispoECPF a{
    bottom: -2px;
} 

div.cart table.table.table-results.table-commande center h5{
    font-size: 13px;
}

td div.reference-orgine{
    position: absolute;
    bottom: 10px;
}

div.cart table.table.table-results.table-commande center a.tooltips{
    position: relative;
}

div.footer-cart{
    padding: 0 !important;
}

a.technical-file{
    background: url('../../../images/fiche_technique.png') no-repeat;
    height: 18px;
    padding-left: 18px;
    text-transform: none;
    text-decoration: none !important;
    display: inline-block;
    margin-left: 15px;
}

.return_subject_button {
    text-decoration: none !important;
    color: #0f5e96 !important;
    z-index: 3;
}

.return_condition.v3-pricin {
    position: absolute;
    top: 112px;
    left: 210px;
    z-index: 3;
}

.bloc-contact-your-distributor.float-left.bottom-auto {
    margin-top: -10px;
    margin-bottom: 3px;
    color: #009B00;
    white-space: nowrap;
    .fa-exclamation-circle {
        color: #009B00;
    }
    a.tooltips {
        bottom: auto !important;
        span {
            border: solid 1px #009B00 !important;
            &:before{
                border-bottom: 12px solid #009B00 !important;
            }
        }
    }
}
.tyre-troublshouting {
    .tire-detail-availability.v3_pricing {
        .bloc-contact-your-distributor {
            white-space: nowrap;
            margin-bottom: 0;
            margin-left: 0px !important;
            float: left;
        }

        .DispoECPF {
            margin-left: 0 !important;
        }
    }
    .dispo-details.dispoA{
        margin-left: 30px !important;
    }
}

.tire-classic-display {
    .dispo-details.dispoA {
        margin-left: auto !important;
        float: left;
    }

    .tire-detail-availability.v3_pricing {
        .DispoECPF {
            margin-left: auto !important;
        }
    }
}

.troubl-shouting {
    background-color: #61c320;
    margin: 5px 0px;
    padding: 5px;
    color: white;
    text-align: center;
    a.tooltips {
        background-color: #61c320 !important;
        color: white !important;
        padding: 2px 6px;
        span {
            border: solid 1px #61c320 !important;
        }
    }
}

.v3_pricing {
    .bloc-contact-your-distributor.green-bg {
        color: #009B00;
        .fa-exclamation-circle {
            color: #009B00;
        }
        a.tooltips {
            span {
                border: 1px solid #009B00;
                &:before{
                    border-bottom: 12px solid #009B00 !important;
                }
            }
        }
    }
}

.dropshipping-availability {
    .v3_pricing{
        .green-bg + center {
            .dispo-details.dispoA {
                float: left;
            }
        }
    }
}

form[name="off_catalog_add_article"].cart {
    table {
        border-collapse: separate;
        border-spacing: 3px;
    }
    .label {
        width: 130px;
        &.vat {
            width: 80px;
            padding-left: 10px;
        }
    }
    .field {
        input {
            width: 400px;
            border: 1px solid #ccc;
            padding: 0 11px 0 13px;
            height: 37px;
            margin-left: 5px;
        }
    }
    .errors {
        color: $red;
        font-style: italic;
    }
    button {
        float: right;
        padding: 5px 79px;
        background: #d93f01;
        border: 0px;
        color: $white;
        font-family: Arial,sans-serif;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .fiche-produit-image-failed-container{
        cursor: default;
    }
}

span#collectBasketFormatting {
    border-radius: 30px;
    background-color: #DCEAF7;
    padding: 10px;
    margin: 10px;
    font-size: 1.2em;
}

#collectBasketFormatting > input {
    margin: 1px;
}

#collectBasketFormatting .fa-exclamation-circle {
    color: #2438DF;
    font-size: 20px;
    margin-left: 5px;
}