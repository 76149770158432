@import "assets/styles/scss/vendor/colors";
@import 'https://fonts.googleapis.com/css?family=Roboto:300,400,600,700';

/* Start Bloc "chains socks index page style" */
.sub-nav-container-consumables {
  padding-right: 15px;
}

#floatingBtns {
  bottom: 7%;
}

.nav-holder {
  .chains-socks-nav {
    padding: 0 !important;
    a, .beneteau-user-nav {
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;
      color: $white;
      line-height: 47px;
      .chains-socks-span-nav {
        background: url(../../../../images/sprite.png) no-repeat -245px -427px;
        width: 30px;
        height: 31px;
        vertical-align: -8px;
        display: inline-block;
      }
    }
  }
}
.search-form-chains-socks {
  width: 100%;
  .sub-nav-links {
    background: $blue;
    padding-top: 15px;
    margin-left: 0;
    height: 150px;

    .bloc-chains-socks {
      width: 100%;

      .searchChainsSocks {
        padding-left: 40px;
        font-family: "Futura LT Book", sans-serif;
        font-size: 13px;
        font-style: italic;
        height: 54px;
        line-height: 46px;
        background: $white url(../../../../images/pneus/pneusSearch.png) no-repeat scroll 10px 8px;
      }

      .content-tire-equipment {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        text-transform: uppercase;
        color: $white;
      }
      .delete-search {
        top: 36%;
        left: 95%;
        z-index: 5;
      }

      .btn_search_block {
        top: 30px;
        right: 8px;
        .btn:active {
          box-shadow: none !important;
        }
        .loader {
          margin-right: 10px !important;
          margin-top: 0;
          cursor: default !important;
        }
      }
      .text-bottom-form-socks {
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          color: $white;
        }
        .text-left-socks {
          width: 70%;
          float: left;
          line-height: 20px;
        }
        .read-tire {
          cursor: pointer;
          line-height: 26px;
          text-decoration-line: underline;
          width: 15%;
          float: left;
        }
      }
      #btnExpress span {
        cursor: default;
        color: $greyB2 !important;
        border: none !important;
      }
    }
  }
}

.mountain-law-bloc {
  background: $white;
  padding: 25px 0 0 25px;
  min-height: 480px;
  width: 1000px;
  font-family: 'Roboto', sans-serif;
  h1, h2, p {
    font-family: 'Roboto', sans-serif;
  }

  .bloc-mountain-law-details {
    font-size: 13px;
    .mountain-law {
      span.rectangle {
        font-weight: 600;
        text-transform: uppercase;
        border-radius: 5px;
        width: 108px;
        height: 33px;
        display: inline-block;
        text-align: center;
        line-height: 33px;
        color: $white;
        background: $blue8;
        font-size: 16px;
      }

      p.text {
        color: $black;
        margin: 17px 0 30px 0;
        line-height: 22px;

        .see-the-map {
          cursor: pointer;
        }
      }
    }
  }
}
/* End Bloc "chains socks index page style" */

/* Start Bloc "popping style" */
#poppingSeeTheMap {
  .modal-dialog {
    width: 608px;
    .modal-content {
      top: 95px;
      height: 500px;
      background-image: url(../../../../images/chainsSocks/carte_loi_montagne.png);
      background-size: 606px;
    }
  }
  .close {
    top: 2px;
    right: 0;
  }
}
/* End Bloc "popping style" */

/* Start Bloc "autocomplete style" */
.autocomplete-ui-chains-socks {
  width: 97%;
  padding: 0;
  top: 98px;
  left: 1.5%;
  box-shadow: none;
  .results-autocompletion {
    padding: 10px 2px 0 10px;
    border: 1px solid $grayd10;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 15px;
    font-family: "Futura LT", sans-serif;
    span.suggested-reference {
      margin-top: 15px !important;
    }
    ul.ui-autocomplete {
    position: relative;
    border: 1px solid $white !important;
    }
    .list-references {
      display: flex;
      flex-direction: column;
    }
    .search-chains-socks-autocomplete {
      padding: 0 !important;
      left: 0 !important;
      width:  100% !important;
      column-count: 5;
      border-top: none;
      li.ui-menu-item.dimension {
        &:hover {
          background: none !important;
        }
        .product-name {
          font-size: 15px;
          width: 100% !important;
          margin: 0;
          padding: 5px 0 2px 12px;
          &:hover {
            background: $grayF2;
          }
        }
      }
    }
    span.text {
      text-transform: uppercase;
      margin-top: -3.5px;
      padding: 5px 18px 18px 0;
      font-weight: bold;
      &:after {
        content: "";
        display: block;
        margin: 10px 0;
        padding-bottom: 5px;
        width: 80px;
        left: 0;
        border-bottom: 4px solid $red_dark;
      }
    }
    .dimension a.ui-menu-item {
      font-size: 14px !important;
      padding: 0 !important;
    }
    .product-reference {
      .reference-span-bloc, .supplier-label {
        width: 30% !important;
      }
      .supplier-label {
        color: $gray22;
        text-transform: uppercase;
      }
      .equipment-type {
        color: $blue;
      }
    }
  }
  .autocomplete-no-dimension-result {
    color: $white;
    background: $yellow url(../../../../images/pneus/pneusSearch.png) no-repeat scroll 9px center;
    text-indent: 40px;
    line-height: 60px;
    font-weight: 700;
    font-size: 13px;
  }
}
/* End Bloc "autocomplete style" */

/* Start Bloc "list results" style" */
span.no-products-chains-socks-results {
  display: block;
  font-size: 12px;
  color: #565656;
  background: white;
  text-indent: 18px;
  line-height: 30px;
  font-weight: 500;
}
/* End Bloc "list results" style" */

.sort-choices {
  li {
    a {
      padding-left: 45px !important;
    }
  }
}

.partner-link {
  width: 87px;
  height: 56px;
}

.partner-link > img {
  width: 78px;
  height: 52px;
}

.rounded {
  border-radius: 10px;
}

#mountain-law-sticker {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: -75px -140px;
}

#mountain-law-dyk {
  width: 522.88px;
  height: 535.308px;
  transform: rotate(27.386deg);
  border-radius: 30px;
  background: #11538B;
  bottom: -284px;
  left: -95px;
  > * {
    transform: rotate(-27.386deg);
  }
  h1, h2 {
    font-size: 23px;
  }
  p {
    font-size: 14px;
  }
  img {
    margin-top: 25px;
    margin-left: 8px;
  }
  .mountain-law-dyk-text {
    width: 60%;
    padding-right: 15px;
    margin-left: 60px;
    transform: translateY(-65px) rotate(-27.386deg);
    font-size: 14px;
  }
}

.mountain-law {
  p {
    font-size: 16px;
  }
}