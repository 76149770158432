// _var.scss

// Breakpoints

$bp: 1000px;

$max-content-width: $bp;

// Colors

$c-black: #000;
$c-light-gray: #E5E5E5;
$c-gray: #6D6D6D;
$c-red: #E50616;
$cc-red: #cc0914;
$c-white: #FFF;
$c-blue: #045C9D;
$c-yellow: #FE9900;
$btn-radius: 4px;
$roboto : 'Roboto', sans-serif