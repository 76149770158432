.ecotaxe_container {
  position: relative;
  &--link {
    color: $blue;
    font-family: Arial, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    text-decoration: underline;

    &-value {
      display: inline !important;
    }
  }

  &--popin {
    position: absolute;
    top: 20px;
    left: 0;
    width: 298px;
    box-shadow: 0 1px 6px 1px rgba(0,0,0,.3);
    background-color: #fff;
    padding: 16px;
    z-index: 2;

    &-price {
      color: $blue;
      font-family: Arial, sans-serif;
      font-weight: 700;
    }

    h4 {
      color: $black;
      font-family: Arial, sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      text-transform: uppercase;
      margin: 0 0 15px;
    }

    h5 {
      color: $black;
      font-family: Arial, sans-serif;
      font-size: 13px;
      font-weight: 400;
      line-height: 19px;
      text-transform: uppercase;
      margin: 0 0 5px;
    }

    p {
      color: $black;
      font-family: Arial, sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 19px;
      margin: 0 0 15px;
    }

    ul {
      padding: 0;
      font-size: 13px;
      color: $black;
      list-style-type: none;
      li {
        position: relative;
        padding: 0 7px;
        &:before {
          content: '';
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background-color: $black;
          position: absolute;
          top: 8px;
          left: 0;
        }

        .strong {
          font-weight: bold;
        }
      }
    }

    a {
      color: $blue;
      font-family: Arial, sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      text-decoration: underline;
      &:hover {
        text-decoration: initial;
      }
    }
  }

}
