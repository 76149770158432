#store-bloc {
  padding: 0;
  .bootstrap-select {
    .dropdown-toggle {
      position: relative;
      color: $white;
      background: $blue;
      font-weight: bold;
      height: 37px;
      z-index: 1;
      max-width: 157px;
      option {
        font-size: 12px;
      }
    }
    .filter-option {
      width: 89%;
    }
    .dropdown-menu.open {
      > ul.inner {
        li {
          &.selected, &:hover {
            background: $blue;
            span.text {
              color: $white;
            }
          }
        }
      }
    }
    .dropdown-menu {
      li {
        text-transform: capitalize;
        font-size: 13px;
      }
    }
  }
}

#loading-multi-store-info {
  display: none;
  border: none;
  width: 0;
  img {
    width: 20px;
  }
}
#failed-loading-store {
  i {
    width: 0;
    color:$red;
    font-size: 20px;
    position: relative;
    top: 2px;
  }
}

#success-loading-store {
  i {
    width: 0;
    color:$green0;
    font-size: 20px;
    position: relative;
    top: 3px;
  }
}
.bold-bloc {
  font-weight: bold;
}