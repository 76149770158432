@import '../vendor/colors';
@import '../vendor/_font-family';
#pop_in_advertising_banner_warning {
  .modal-body {
    p {
      line-height: 30px;
    }
    .quantity-in-cart {
      color: $black;
      font-weight: bold;
      font-size: 14px;
    }
    .bloc-number-validated-promo {
      margin-left: 30px;
      margin-bottom: 10px;
    }
    .min-quantity {
      font-weight: bold;
      font-size: 14px;
    }
    .detail-content-info-before-list-reference {
      padding-bottom: 12px;
    }
    .text-overflow-ellipsis {
      left: 2%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      &.designation {
        color: $black;
      }
      &.reference-bloc {
        width: 10%;
      }
      &.quantity-in-cart {
        margin-left: 0;
      }
    }
    a.popin-tooltips {
      height: 17px;
      left: 9px;
      background-color: transparent;
      cursor: pointer;
      &:not(.content-popin-tooltips) {
        width: 17px;
      }
      &.content-popin-tooltips {
        z-index: 99;
        cursor: unset;
        padding: 0;
        left: 3%;
        text-transform: uppercase;
        &.left-38-percent {
          left: 38%;
        }
        &.left-56-percent {
          left: 56%;
        }
      }
    }
    a:hover.content-popin-tooltips span {
      left: -10px;
      border-color: $greyBC;
      &.content-popin-tooltips {
        left: 0;
      }
    }
    a.tooltips.content-popin-tooltips {
      span {
        &:before {
          left: 6%;
          border-bottom-color: $greyBC;
        }
        &:after {
          left: 6%;
        }
      }
    }
  }
}