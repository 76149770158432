/*
 * Correspond à templates/headerHolder.html.twig (header)
 * Inclus par templates/layoutAutossimo.html.twig
*/
/* 
    Created on : 23 févr. 2017, 15:29:43
    Author     : aleugoli
*/

.user-action li {/* menu setting (profil) */
    text-align: left;
    width: auto;
}
.user-action li>a {
    display: inline-block;
}
.user-action li>a>span.badge {/* puce (par ex. nb devis) */
    margin: 6px 12px 0 0;
    z-index: -1;
}
.header-help-icon{
    font-size: 25px;
    margin-top: 6px;
    margin-left: 20px;
    color: #0F5E9B!important;
    top: -3px;
}
a#devisIdg {/* sous-menu alertes / id garage */
    cursor: pointer;
}

div#popinDevisIdg {/* popup de connexion */
    background-color: white;
    border: black 1px solid;
    display: none;
    margin: 2.5em;
    padding: 1em;
    position: absolute;
    text-align: center;
    width: 300px;
    z-index: 10;
}
div#popinDevisIdg>h1 {
    color: #d80000;
    font-size: 1em;
    font-weight: bold;
    margin: 0 0 0.5em 0;
    text-align: left;
}
div#popinDevisIdg button {
    background-color: #d80000;
    border-radius: 2px;
    border-width: 0;
    color: white;
    font-size: 1.1em;
    margin: 0.6em 0;
    padding: 0.3em;
    font-variant: small-caps;
}

div#popinDevisIdg>span#nosync label {
    font-size: 0.8em;
    text-align: right;
    width: 75px;
}
div#popinDevisIdg>span#nosync input {
    background-color: #0f5e96 ;
    border-radius: 2px;
    border-width: 0;
    color: white;
    margin: 0.4em;
    padding: 0.4em 0.9em;
}
div#popinDevisIdg>span#nosync div.err {
    color: red;
    font-style: italic;
    text-transform: none;
}
div#popinDevisIdg>span#nosync>div {
    margin-top: 0.6em;
    text-transform: none;
}
div#popinDevisIdg>span#nosync>div.contact {
    clear: left;
    float: left;
    text-align: left;
}
div#popinDevisIdg>span#nosync>div.links>span {
    clear: left;
    color: #d80000;
    float: left;
    text-transform: none;
}
div#popinDevisIdg>span#nosync>div.links>span>a {
    color: #d80000;
}

div#popinDevisIdg>span#sync {
    display: none;
}
.disabled-link{
    opacity: 0.5;
}
.navigation-menu-list .menu-icon {
    font-size: 18px;
    color: $white;
}
.navigation-menu-list a:hover .menu-icon, .navigation-menu-list a:active .menu-icon, .navigation-menu-list a .active .menu-icon {
    color: $blue
}
.nav-holder {
    .message {
        .infobull {
            .tooltiptext {
                top: 100%;
                right: -5px;
                visibility: visible;
            }
        }
    }
}

.header-holder.submenu-navigation > * a#devisIdg[disabled] {
    pointer-events: none;
    cursor: default;
    color: lightgrey;
}
