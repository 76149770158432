.cart-block .slide .holder.cart{
    z-index: 20;
    width:500px;
    right: 0;
    height: 550px;
    padding: 20px 20px 20px 0;
}
.cart-block .slide .holder.cart span.close{
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 18px;
}
.cart-block .slide .holder.cart.empty {
    height: 80px;
}

.cart-block .slide div.cart p.header{
    color:#0f5e96;
    padding: 0px 0 0 20px;
    font-weight: normal;
    font-family: arial, sans-serif;
}

.cart-block .slide div.cart p.cart-art-parag{
    color:#0f5e96;
    padding: 0px 0 0 20px;
    font-weight: normal;
    font-family: arial, sans-serif;
}

.cart-block .slide div.cart strong{
    color:#0f5e96;
    display: inline-block;
    padding: 0;
}

.cart-block .slide div.cart .bandeau-alerte strong{
    color:#e60b0b;
    font-size: 1.3em;
    display: inline-block;
    margin-top: -4px;
}

.cart-block .slide div.cart .bandeau-alerte .alerte{
    text-align: center;
    margin-left: 80px;
}

.cart-block .slide div.cart p.total{
    color:#0f5e96;
    padding: 0px 0 0 20px;
}

.cart-block .slide div.cart p.empty_cart{
    padding: 10px;
}

.cart-block .slide div.cart a.cart_view_all{
    border:none;
    font-weight: bold;
    font-family: Arial, sans-serif;
    color:black;
    text-decoration: underline #999898 dotted;
}

.table-list-products .product {
    border-bottom: 0px solid #fff;
    cursor:pointer;
    color:black;
}

.table-list-products .product:hover {
    background: #f2f2f2 none repeat scroll 0 0;
}

.table-list-products .delete {
    border-bottom: 0px solid #fff;
}

.table-list-products td.delete a.delete-action{
    margin-left:20px;
}

.table-list-products .name{
    font-weight:bold;
    border-bottom: 0px solid #fff;
}

.table-list-products td.actions{
    border-bottom: 0px solid #fff;
    text-align:center;
}

td.actions strong{
    color: black !important;
}

table.table.table-list-products td.price{
    border-bottom: 0px solid #fff;
    min-width: 100px;
}

div.holder.cart a.btn.btn-border-black.action-delete-all{
    border:none;
    font-weight:bold;
    color:black;
    font-family: Arial, sans-serif;
    margin-top: 15px;
    text-decoration: underline dotted #999898;
    position: absolute;
    bottom: 40px;
    left:10px
}

.cart-block .slide .holder.pull-left.associated-families{
    z-index: 20;
    width:500px;
    right: 500px;
    height: 550px;
    background-color: #e6e6e6 ;
    position: absolute;
}

.associated-families p.block-title{
    text-transform: none;
    color:#0f5e96;
    padding-bottom:10px;
}

div.familles-list{
    overflow-x:hidden;
    overflow-y: scroll;
    height:98%;
}

div.familles-list div.inner{
    padding: 0 0 0 30px;
}

div.inner div.family{
    box-shadow: 0 4px 2px -3px grey;
    margin-right: 10px;
    position: relative;
    min-height: 220px;
    text-align: center;
    background-color: #FFF;
    margin-top: 10px;
}

div.inner div.family div.image{
    padding: 30px 10px;
}

div.inner div.family img{
    width: 100px ;
    height: 100px;
}

div.inner div.family p.title{
    font-weight:normal;
    position: absolute;
    bottom: 0;
    border-top: 1px solid #dedede;
    padding: 10px 0 0 0;
    width: 110px;
    min-height: 50px;
    font-size: 11px;
}

div.inner div.family p.title a{
    color: #0f5e96;
    font-size: 10px;
    font-weight: bold;
}

.user-action li>a>span.badge.nbPanier {
    margin: 0;
    z-index: 0;
}

.pricing_cart_layout {
    height: 276px!important;
    padding: 0!important;
    width: 720px !important;
    .close_layout {
        width: 30px;
        height: 30px;
        display: block;
        border: 2px solid gray;
        position: absolute;
        right: -15px;
        top: -15px;
        border-radius: 50%;
        background-color: white;
        color: black;
        text-align: center;
        line-height: 27px;
        cursor: pointer;
    }

    &.cart_empty {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &--header {
        height: 65px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        text-transform: initial;
        border-bottom: 3px solid rgba(0, 0, 0, .2);

        &-total_items {
            flex: 1.5;
            font-weight: bold;
            span {
                color: $blue1;
            }
        }

        &-total_price {
            flex: 2;
            display: flex;
            width: 100%;
            align-content: space-between;
            align-items: start;
            flex-direction: row;

            &-text {
                flex: 1;
                color: black;
                opacity: .7;
                text-align: right;
            }

            &--container {
                flex: 1;
                text-align: right;

                &-value {
                    color: $red;
                    font-weight: bold;
                    font-size: 18px;
                    .total_value {
                        font-size: 18px;
                    }
                    span {
                        font-size: 18px;
                    }
                }

                &-link {
                    font-size: 12px;
                    a {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &--body {
        height: 162px;
        overflow-y: scroll;
        overflow-x: hidden;
        border-bottom: 2px solid rgba(0,0,0,.2);
        &-item {
            padding: 16px 16px 5px;
            cursor: pointer;
            .delete_item {
                margin-right: 10px;
            }

            &.separator {
                border-bottom: 1px solid rgba(0, 0, 0, .2);
            }

            &--info {
                font-family: 'Gotham Book', sans-serif;


                &-constructor {
                    color: $blue;
                    font-weight: bold;
                    display: inline-block;
                    padding-right: 10px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 100%;
                    white-space: nowrap;
                }
                &-ref{
                    display: inline-block;
                    color: #6e6e6e;
                    font-size: 14px;
                    font-weight: 700;
                    font-family: Arial,sans-serif;
                }

                &-product {
                    font-weight: bold;
                    font-size: 12px;
                    color: $black;
                    text-overflow: ellipsis;
                    word-wrap: break-word;
                    overflow: hidden;
                    max-height: 36px;
                    line-height: 18px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }

            &--quantity {
                text-align: center;
                font-weight: bold;
                color: $black;
                text-transform: lowercase;
                position: relative;
            }

            &--price {
                flex: 1;
                text-transform: initial;
                text-align: right;

                &-value {
                    color: $red;
                    font-weight: bold;
                    font-size: 18px;
                    span {
                        font-size: inherit;
                    }
                }

                &-link {
                    font-size: 12px;
                    a {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &--footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        margin: 8px 16px;

        &--display {
            margin-right: 14px;

            a {
                color: black;
                text-transform: none;
                font-size: 12px;
                font-weight: 500;
            }
        }

        &--trash {
            flex: 1;

            a {
                color: black;
                text-transform: initial;
                font-size: 12px;
                font-weight: 500;
            }
        }

        &--submit {
            flex: 2;
            text-align: right;
            a {
                color: white;
                background-color: red;
                padding: 5px 10px;
                font-weight: bold;
                img {
                    margin-right: 8px;
                }
            }
        }
    }
    .folder-link {
        font-size: 12px;
        font-weight: bold;
        margin-left: 6%;
        span {
            i {
                font-size: 17px;
                position: relative;
                top: 8px;
            }
            &.folder-type {
                text-align: left;
                padding-left: 0px;
            }
        }
    }
}

.layer_ecotaxes {
    .ecotaxe_container--popin {
        position: fixed;
        top: 0;
        right: 0;

        p {
            font-weight: normal;
            font-size: unset;
            text-transform: none;
            margin: unset;
        }
        h5, h4, p, ul, li{
            text-align: left;
        }
        h4{
            font-weight: bold;
        }
        ul, li {
            font-weight: normal;
        }
        a.learn-more{
            color: #0f5e96;
            float: left;
            font-weight: 700;
            margin-top: 5px;
        }

        h5 {
            margin-top: 5px;
        }

        ul {
            padding: 0;
            font-size: 13px;
            color: $black;
            list-style-type: none;
            li {
                position: relative;
                padding: 0 7px;
                &:before {
                    content: '';
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    background-color: $black;
                    position: absolute;
                    top: 8px;
                    left: 0;
                }

                .strong {
                    font-weight: bold;
                    display: inline !important;
                }
            }
        }

    }
    .eco-investments{
        text-transform: none;
        font-weight: normal;
    }
}
#layerPanier{
  text-transform: none;
}
