/* 
    Bloc flottant droite
    Appelé par templates/layoutAutossimo.html.twig

    Created on : 7 mars 2017, 15:46:33
    Author     : aleugoli
*/

#floatingBtns {
    position: fixed;
    right: 0;
    z-index: 10;
}
#floatingBtns>a {
    clear: right;
    float: right;
    margin-bottom: 1em;
}
#btn-email {
    background: rgba(0, 0, 0, 0) url("../../../images/btn-fiche_de_liaison.png") no-repeat scroll 0 0;
    height: 148px;
    width: 41px;
}

#btn-oui-jai {
    margin-bottom: 64px;
}

#btn-oui-jai img {
    width: 60px;
}