.myStep3 {
    background: #fff;
}

.mySelect {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent url("../../../images/icon_down_arrow.png") no-repeat scroll 90% 10px;
    border: 1px solid #e6e6e6;
    padding: 5px 25px 5px 5px;
    width: 150px;
    height: 27px;
    font-size: 11px;
    color: #565656;
}
.blueLink {
    background: #0f5e9b none repeat scroll 0 0;
    color: white;
    padding: 7px 8px;
    text-transform: uppercase;
    cursor: pointer;
    text-align: center;
}
.DEtitle{
    text-transform: uppercase;
    font-weight: bold;
}
.DElink {
    text-transform: uppercase;
}
.DEsubtitle {
    color: #0f5e96;
    font-weight: bold;
}

.DEsubtitle span{
    text-transform: capitalize;
}

.DEPrice2{
    color: #0f5e96;
    cursor: pointer;
    font-family: "Futura LT Heavy", sans-serif;
    font-size: 18px;
    line-height: 20px;
    display: inline-block;
}

div.devis-header-right div.price-sub-total {
    line-height: 20px;
    overflow: hidden;
}

div.devis-header-right div.price-sub-total p {
    color: #8c8c8c;
}

div.devis-header-right div.price-sub-total div.devis-price-1{
    color: #0f5e96;
    cursor: pointer;
    font-family: arial, sans-serif;
    font-size: 13px;
}

div.devis-header-right div.price-sub-total div.devis-price-1 span{
    font-size: 25px;
    margin-right: 10px;
    font-weight: bold;
}

div.price-sub-total div.price-position{
    float: right;
    margin-right: 40px;
}

div.prix-vente {
    font-size: 11px;
}

div.prix-vente span{
    font-weight: bold;
    font-size: 14px;
    margin-right: 5px;
}

.devis-price-header {
    margin-top: 20px;
}

a.devis-save {
    background: #8c8c8c;
    color: white;
    cursor: pointer;
    padding: 8px 22px;
}
a.devis-save:hover {
    color: white;
}

.nextPrevious {
    text-transform: uppercase;
    margin-top: 15px;
}
.nextBtn {
    background: red none repeat scroll 0 0;
    color: #fff;
    padding: 7px 15px;
    text-transform: uppercase;
    /*width: 45%;*/
    float: right;
    text-align: right;
    cursor: pointer;
}
.previousBtn {
    background: #0f5e9b none repeat scroll 0 0;
    color: #fff;
    padding: 7px 15px;
    text-transform: uppercase;
    float: left;
    cursor: pointer;
}

.DEitem, .DEcheckbox, .DEpopin{
    display: inline-block;
}
i.devis-checkbox {
    background: transparent url("../../../images/devis/cercle_etape_validee.png") no-repeat scroll 0 0;
    height: 13px;
    width: 21px;
    margin-top: 11px;
    float: left;
}
div.devis-item{
    font-weight: bold;
    color: #6e6e6e;
    font-size: 13px;
    line-height: 35px;
    clear: both;
}

div.devis-item p{
    float: left;
}

div.devis-item a.devis-popin {
    cursor: pointer;
    text-decoration: underline;
    color: #8c8c8c;
    padding-left: 15px;
    font-size: 11px;
    font-weight: normal;
}

.myActions {
    cursor: pointer;
}

.DEdel, .DEactive, .DEactivMO {
    display: inline-block;
}

div.DEdel{
    margin-left: 10px;
    cursor: pointer;
}

.DEredBloc {
    color: red;
}

div.DEactivMO{
    margin-left: 7px;
}

.DEtb {
    border: thin solid #e6e6e6;
    height: 27px;
    text-align: left;
    padding: 5px 10px;
    display: block;
    color: #0f5e96;
    font-size: 12px;
    width: 60px;
    margin-left: 3px;
    font-weight: bold;
}

.DEserviceME {
    width: 550px;
    text-align: left;
}
.DEmyselect {
    display: inline;
    padding-left: 5px !important;
    padding-right: 5px !important;
    width: 55px !important;
}

.addRefManuelle, .addRefManuelle:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #e10f21;
}
.DECAP {
    border: thin solid #e6e6e6;
    padding: 3px 5px;
    text-align: left;
    color: #0f5e96;
    font-size: 12px;
}

tr.warning-ref td div.DECAP,
tr.warning-ref td input.DEprixVenteEF{
    color: #e3e3e3;
}

.DEinfosTech,
tr.deleted div.DEinfosTech{
    background: transparent url("../../../images/devis/picto_infos_techniques.png") no-repeat scroll 0 4px;
    display: inline-block;
    cursor: pointer;
    height: 18px;
    left: 0;
    margin-bottom: -2px;
    top: -5px;
    width: 21px;
    margin-top: 7px;
}
.DEiBleu {
    background: transparent url("../../../images/devis/picto_i_bleu.png") no-repeat scroll 0 0;
    display: inline-block;
    cursor: pointer;
    height: 15px;
    left: 0;
    margin-bottom: -2px;
    top: -6px;
    width: 20px;
}
.DEprinceMO {
    width: 100px;
}

table.table tr th{
    color: #0f5e96;
    vertical-align: middle;
}
.icon-entonnoir {
    width: 17px;
    height: 13px;
    line-height: 13px;
    display: inline-block;
}
.icon-entonnoir.app{
    background: transparent url("../../../images/devis/picto_entonnoir_half.png") no-repeat scroll 0 0;
}
.icon-entonnoir.vid{
    background: transparent url("../../../images/devis/picto_entonnoir.png") no-repeat scroll 0 0;
}
.icon-entonnoir.none{
    display: none;
}
.DEpictos {
    text-align: center;
    width: 40px;
    margin-top: 2px;
    height: 20px;
}
.DEnoref {
    background: transparent url("../../../images/devis/picto_alerte.png") no-repeat scroll 0 20px;
}

table.DEelementFacturee{
    width: 940px;
    table-layout: fixed;
    border: 0 !important;
}

table.DEelementFacturee th{
    border-top: 1px solid #e6e6e6 !important;
    font-size: 13px;
    padding: 5px 14px;
}

table.DEelementFacturee th span{
    font-size: 11px;
}

table.DEelementFacturee td{
    font-size: 13px;
    color: #6e6e6e;
}

table.DEelementFacturee tr.warning-ref td {
    color: #e10f21;
}

table.DEelementFacturee th.DEconfigLine{
    border-top: 0 !important;
}

table.DEelementFacturee td.DEconfigLine,
table.DEelementFacturee th.DEconfigLine{
    border: 0px;
    width: 28px;
}

table.DEelementFacturee td.name,
table.DEelementFacturee th.name{
    width: 155px;
}

table.DEelementFacturee td.reforigine,
table.DEelementFacturee th.reforigine{
    width: 100px;
}

table.DEelementFacturee td.equipementiers ,
table.DEelementFacturee th.equipementiers{
    width: 130px;
}

table.DEelementFacturee td.refequip,
table.DEelementFacturee th.refequip{
    width: 105px;
}

table.DEelementFacturee td.quantity{
    padding-left: 15px;
    padding-top: 15px;
}

table.DEelementFacturee td.dispo,
table.DEelementFacturee th.dispo{
    width: 76px;
}

table.DEelementFacturee td.prixAchat,
table.DEelementFacturee th.prixAchat{
    width: 75px;
}

table.DEelementFacturee td.contenance,
table.DEelementFacturee th.contenance{
    width: 50px;
}

table.DEelementFacturee td.contenance{
    padding: 15px 8px 0 8px;
}

table.DEelementFacturee td.myActions,
table.DEelementFacturee th.myActions{
    width: 80px;
}

table.DEelementFacturee td.myActions{
    text-align: center;
}

table.DEelementFacturee td.prixVente,
table.DEelementFacturee th.prixVente{
    width: 75px;
}

td.step5.equipementiers select{
    width: 115px !important;
    font-size: 9px;
}
.DEprixVente {
    width: 80px;
}
.DEprixVenteEF, .DEMainOeuvreItem {
    width: 60px;
    border: 1px solid #dfdfdf;
    height: 27px;
}

td.DEredBloc.text{
    font-size: 11px !important;
}

input.DEprixVenteEF{
    font-size: 12px;
    color: #0f5e96;
    font-weight: bold;
    padding: 5px 10px;
}

.DEMainOeuvreItem, .DEreference {
    display: inline-block;
}

.DEMainOeuvreItem{
    text-align: center;
    color: #0f5e96;
}

.DECenter {
    text-align: center;
}
.DEforfait {
    border: thin solid #e6e6e6;
    display: inline-block;
    margin: 10px;
    padding-top: 5px;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}
.DEforfaitSelect {
    background: red none repeat scroll 0 0;
    color: #fff;
    cursor: pointer;
    margin-top: 10px;
    padding-bottom: 8px;
    padding-top: 8px;
    text-transform: uppercase;
    font-weight: bold;
}
.DErefItem {
    background: #fff none repeat scroll 0 0;
    box-shadow: 1px 1px 2px #888888;
    margin-bottom: 6px;
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 10px;
    padding-right: 0;
    padding-top: 10px;
}
.DErefOriginItem{
    box-shadow: 1px 1px 7px #cecece;
    margin: 0 0 15px;
    padding-bottom: 20px;
    padding-top: 17px;
}
.DErefEqItem {
    background: #fff none repeat scroll 0 0;
    box-shadow: 0 0 1px #888888;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 5px;
    padding-right: 0;
    padding-top: 5px;
}
.DEpieceOrigine {
    font-weight: bold;
    color: #0f5e96;
}
.DErefOrigin {
    font-weight: bold;
    display: inline-block;
}
.DEdesignation {
    color: #0f5e96;
    text-transform: uppercase;
}
.header {
    z-index: 20;
}
#popinFpPneus .modal-content {
    top: 0;
}

.DEForfaitTitle {
    color: #0f5e9b;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 10px;
    padding-top: 25px;
}

.DEForfaitPrice {
    color: red;
    font-size: 30px;
    font-weight: bold;
    padding-bottom: 20px;
    padding-top: 15px;
}

.DEtag{
    background: #0f5e96 none repeat scroll 0 0;
    color: #fff;
    display: inline-block;
    cursor: default;
    padding: 3px 5px;
    font-style: italic;
    min-width: 50px;
    margin: 10px 5px 5px 0;
}

.DEfp {
    border-top: thin solid #e6e6e6;
    margin-left: 13px;
    margin-top: 10px;
    padding-top: 10px;
    width: 871px;
}

.DEselect {
    font-weight: bold;
    text-transform: uppercase;
}

.DEstep4Aft {
    display: none;
}

.DESG {
    display: inline-block;
    margin-left: 186px;
}

.DEstep4AftPrice {
    border: thin solid #e6e6e6;
}

.DEaffectation {
    font-weight: bold;
}

.refpopin.DEaffectation,
.refpopin.DEdesignation.DEpieceOrigine{
    margin-top: 10px;
}

.DEinputBtn {
    border: thin solid #e6e6e6;
    height: 37px;
    width: 100%;
}

div.mainOeuvre table{
    font-size: 13px;
}

div.mainOeuvre table > tbody > tr > td,
div.mainOeuvre .table > thead > tr > th,
table.DEelementFacturee > tbody > tr > td,
table.DEelementFacturee > thead > tr > th
{
    border: 1px solid #e6e6e6;
    vertical-align: middle;
}

div.mainOeuvre table > tbody > tr {
    text-align: center;
}


.align-center{
    text-align: center;
}

.align-left{
    text-align: left;
}

/*kevin*/
.table-devis-bordered, td {
    border: 1px solid #cbcbcb;
    margin-top: 30px;
}
.table-vente-bordered, td {
    border: 0 none;
    margin-top: 30px;
}
.tableDevisWorkforce {
    border: 0 none;
    border-collapse: collapse;
    margin-top: 30px;
    width: 60%;
}
.table-blue-header {
    color: #0f5e96;
    font-weight: bold;
}
.edit.btn, .edit.btn:hover, .save.btn, .save.btn:hover {
    background: #d80000 none repeat scroll 30% center;
    color: #fff;
    font-family: Arial,"Helvetica Neue",Helvetica,sans-serif;
    font-size: 12px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    text-align: center;
}
.reset.btn {
    background: #ddd none repeat scroll 0 0;
    color: #000;
    font-family: Arial,"Helvetica Neue",Helvetica,sans-serif;
    font-size: 12px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    text-align: center;
}
.update.btn {
    background: #0f5e96 none repeat scroll 0 0;
    color: #fff;
    font-family: Arial,"Helvetica Neue",Helvetica,sans-serif;
    font-size: 12px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    text-align: center;
}
#update_capacity.update.btn {
    float: right;
    width: 345px;
}
input#reinitialiser.btn.btn-default.reset-all.reset-all-devis {
    background-color: #8c8c8c;
    color: #fff;
    height: 45px;
    margin-top: 15px;
    text-transform: uppercase;
    width: auto;
}
input#seuil_ht.iradio_square-red {
    background: #ee1c25 none repeat scroll 0 0;
    border: medium none;
    cursor: pointer;
    display: inline-block;
    height: 40px;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    width: 22px;
}
#vente-piece-liquide input {
    float: right;
    width: 250px;
}
#vente-piece-liquide span {
    float: right;
}
#bloc-vente-piece-liquide {
    background-color: #fff;
    margin-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
}
.toggle-vente-piece-liquide .toggle {
    background: #aaaaaa none repeat scroll 0 0;
    border-radius: 10px;
}
.champs-obligatoires {
    color: #61c320;
    float: right;
}
.txtht {
    color: #8c8c8c;
    text-align: right;
}
.table-blue-header-generique {
    color: #0f5e96;
    float: left;
    font-weight: bold;
}
div#table-blue-title-generique.table-blue-header-generique {
    margin-bottom: 30px;
    margin-top: 30px;
}
.bloc-parametre {
    background-color: #fff;
    margin-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
}
.devis_parametre_breadcrumb {
    font-size: 13px;
    margin: 10px 0;
}
input.update_appoint, input.vente-piece-liquide {
    width: 250px;
}
.appoint_actions {
    float: right;
}
.manufacturer_section ul {
    display: inline-block;
}
.manufacturer_section li {
    color: #0f5e96;
    list-style-type: none;
    margin: 0 0 7px;
}
.manufacturer_section {
    font-weight: bold;
    min-height: 140px;
}
.manufacturer_section .category {
    padding: 0;
}
.worforceParameter td.table-blue-header div.radio-red > label {
    position: absolute;
    right: 20px;
}
.worforceParameter .radio-red {
    position: relative;
}

.radio-vehicle > label {
    position: absolute;
    right: 20px;
}
.radio-vehicle {
    position: relative;
}
.choose_manufacturer {
    margin-top: 20px;
}
.manualReferenceParameter .table_manual-reference .table-blue-header, .manualReferenceParameter .trash {
    text-align: center;
}
.manualReferenceParameter table input {
    display: block;
    margin: auto;
    width: 100px;
}
.manualReferenceParameter input.ref-price, .manualReferenceParameter .trash {
    width: 40px;
}
.manualReferenceParameter .trash {
    cursor: pointer;
    margin: 5px;
}
.manualReferenceParameter input.ref-type {
    width: 80px;
}
.manualReferenceParameter .wrapper-ios {
    display: block;
    margin: 5px auto;
}
.manualReferenceParameter .wrapper-ios input {
    display: none;
}
.manualReferenceParameter .ref_activation {
    overflow: hidden;
    width: 110px;
}
input.required {
    border: 1px solid red;
}
.required_succes img {
    width: 16px;
}
.required_error {
    color: #e2061b;
    display: none;
    float: right;
    margin: 10px;
}
.required_succes {
    color: #2da53e;
    float: right;
    margin: 10px;
}
.popin .modal-dialog.capacity {
    font-size: 14px;
    width: 550px;
}
.popin .modal-dialog.capacity input[type="text"] {
    width: 70px;
}

div.revisions-services.modal h1{
    text-indent: 0;
}

div.revisions-services.modal div.modal-header{
    padding: 15px 15px 0 15px;
}

div.revisions-services.modal div.modal-header .close{
    position: absolute;
    top: 10px;
    right: 10px;
}

ul.revisions-services-list{
    padding: 0;
    list-style: none;
}

ul.revisions-services-list li{
    line-height: 35px;
}

div.my-operations section.accordion{
    margin-bottom: 0;
}

div.my-operations section.accordion ul{
    display: none;
}

div.my-operations section.accordion div.operation-list-header{
    padding: 5px 15px;
    overflow: hidden;
}

div.my-operations section.accordion div.operation-list-header p{
    font-weight: normal;
}

div.priceSubTotal{
    overflow: hidden;
}

div.price-Sub-total-body{
    float: right;
}
div.DEPrice1{
    color: #0f5e96;
    font-size: 15px;
    margin-right: 5px;
}
div.DEPrice1 span{
    font-size: 26px;
    font-weight: bold;
    margin-right: 5px;
}
div.prixVente{
    margin-top: -8px;
    margin-left: 3px;
}

div.prixVente span{
    font-size: 14px;
}

tr.deleted td.DEserviceME,
tr.deleted div.pice-mo
{
    text-decoration: line-through;
}

tr.deleted td span.DEtb,
tr.deleted td input.DEMainOeuvreItem,
tr.deleted td select#mySelect,
tr.deleted div.pice-mo,
tr.deleted input.DEtb
{
    color: #ccc;
}

tr.deleted div.DEinfosTech {
    background: transparent url("../../../images/devis/picto_infos_techniques-grey.png") no-repeat scroll 0 0;
}

div.pice-mo {
    width: 67px;
    height: 27px;
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    color: #0f5e96;
    position: relative;
}

div.pice-mo span{
    position: absolute;
    top: 6px;
    left: 70px;
}

input:disabled {
    background: white !important;
}

.DEmyselect:disabled,
.mySelect:disabled{
    background: white;
    color: #ccc !important;
}

hr.bottom {
    border-top: 1px solid #e6e6e6;
}

table.DEstep4Bef tr,
table.DEstep4Bef th{
    font-size: 13px;
}

table.DEstep4Bef tr td,
table.DEstep4Bef tr th{
    border: 1px solid #e6e6e6;
}

.no-bill-actions{
    text-align: center;
}

.trush{
    background: transparent url("../../../images/devis/poubelle.png") no-repeat scroll 0 0;
    width: 12px;
    height: 15px;
    cursor: pointer;
    margin-right: 3px;
}

.euro{
    width: 13px;
    height: 13px;
    margin-right: 3px;
    cursor: pointer;
}
.unbilled .euro{
    background: transparent url("../../../images/devis/picto_euro.png") no-repeat scroll 0 0;
}
.DEelementFacturee .euro{
    background: transparent url("../../../images/devis/picto_euro_barre.png") no-repeat scroll 0 0;
}

.inline-block{
    display: inline-block;
}

tr.deleted td.no-bill-name{
    text-decoration: line-through;
}

tr.deleted td.no-bill-operation,
tr.deleted td.no-bill-name,
tr.deleted td.step5 div.ref_name {
    text-decoration: line-through;
    color: #ccc !important;
}

tr.deleted div.euro{
    background: transparent url("../../../images/devis/picto_euro_grey.png") no-repeat scroll 0 0;
    cursor: pointer;
}

tr.deleted div.trush{
    background: transparent url("../../../images/devis/picto_re.png") no-repeat scroll 0 0;
    width: 14px;
    height: 14px;
    cursor: pointer;
}

div.RevisionData span.fa-spinner{
    font-size: 40px;
}

.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th{
    background-color: white;
}

tr.free td,
tr.free td div.DECAP,
tr.delete-line td,
tr.delete-line td div.DECAP,
tr.free .capacity_value{
    color: #ccc !important;
}

tr.free td div.euro,
tr.delete-line td div.euro{
    background: transparent url("../../../images/devis/euro-grey.png") no-repeat scroll 0 0;
    width: 13px;
    height: 13px;
}

tr.free td div.trush{
    background: transparent url("../../../images/devis/trash-grey.png") no-repeat scroll 0 0;
    width: 12px;
    height: 15px;
}

tr.delete-line td div.trush{
    background: transparent url("../../../images/devis/picto_re.png") no-repeat scroll 0 0;
    width: 14px;
    height: 14px;
    cursor: pointer;
}

tr.free td div.DEinfosTech.operations,
tr.delete-line td div.DEinfosTech.operations{
    background: transparent url("../../../images/devis/picto_infos_techniques-grey.png") no-repeat scroll 0 0;
    width: 10px;
    height: 16px;
}

tr.delete-line td.name{
    text-decoration: line-through;
}

tr.delete-line td.reforigine,
tr.delete-line td.equipementiers,
tr.delete-line td.refequip div.DEreference{
    text-decoration: line-through;
    color: #ccc !important;
}

tr.delete-line td.refequip img,
tr.free td.refequip img,
tr.delete-line td.reforigine img,
tr.free td.reforigine img,
tr.delete-line .icon-autodata,
tr.delete-line .icon-entonnoir,
tr.free .icon-autodata,
tr.free .icon-entonnoir{
    display:none;
}

.list-operations .modal-content{
    text-align: left;
}

div.list-operations .modal-dialog p{
    line-height: 35px;
}

.no-bill-actions div.euro{
    margin-right: 10px;
}

.no-bill-operation ul{
    margin: 0;
    padding: 0 15px;
    list-style: none;
}

td.step5.dispo div.dispo-details.dispoA,
div.DEdisponibilite.refdispo div.dispo-details.dispoA{
    margin: 0;
    width: 100%;
    font-size: 10px;
}

td.step5.dispo .dispo-details input,
div.DEdisponibilite.refdispo .dispo-details input {
    width: 100%;
    margin-right: 0;
    background: rgba(0, 0, 0, 0) url(../../../images/icon-calendrier.png) no-repeat scroll 46px center;
}

td.step5.dispo div.dispo-details.dispoD,
div.DEdisponibilite.refdispo div.dispo-details.dispoD{
    font-size: 10px;
    padding: 10px 0px !important;
    background-color: white;
    .fa-check-circle {
        font-size: 15px;
        color: #009B00;
    }
    .text-description-green {
        margin-left: 2px;
        margin-right: 15px;
        color: #009B00;
        vertical-align: 1px;
        font-weight: 700;
    }
}
div.codref-dates{
    margin: 10px 0px 15px;
}

span.codref-head{
    font-size: 11px;
}

span.nbr{
    color: #0f5e96;
}

.btn-red.DEselect,
span.ficheprod{
    cursor: pointer;
    display: inline-block;
    width: 100%;

}
.img-ficheprod {
    margin-right: 8px;
}
.head-equ{
    min-height: 50px;
    padding-top: 15px;
    font-size: 11px;
}
.head-equ.nopadding{
    padding: 0;
}

.bill-action {
    margin-left: -10px;
}
.bill-action input {
    width: 55px;
    margin-right: 5px;
    height: 27px;
    text-align: center;
}
.bill-action input[type="text"] {
    padding: 0;
}

.bill-action a.minus,
.bill-action a.plus{
    float: left;
    width: 55px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background: darkgrey;
}

.btn-classic.select{
    float: right;
    margin: 0px 0 10px;
}

div.prices{
    font-weight: bold;
    color: #0f5e96;
}

div.prices span{
    color: #6e6e6e;
    font-weight: normal;
}

div.carousel-product{
    padding: 10px 15px;
    overflow: hidden;
}

.modal div.carousel-product{
    padding: 0;
}

.DEred {
    color: #ff8e01 !important;
}

.DEgreen {
    color: #61c320 !important;
}

td.step5.dispo div.dispo-details.delete-line, tr.free td.step5.dispo div.dispo-details{
    background-color: #e6e6e6 !important;
}

.info-oil.delete-line,
.unbilled .info-oil{
    pointer-events: none;
    cursor: default;
    opacity: 0.5;

}
.DEprixVenteEF.delete-line, .modal-equipref.delete-line, .modal_dErefOrigine.delete-line, tr.free td.step5.prixVente input.DEprixVenteEF {
    color: #ccc !important;
    pointer-events: none;
    cursor: default;
}
.quant-cart.delete-line, tr.free td.step5.quantity div.quant-cart{
    opacity: 0.4;
    pointer-events: none;
    cursor: default;
}
.trush.unbilled{
    pointer-events: none !important;
    cursor: default;
}
.delete-line .DEiBleu{
    background: transparent url("../../../images/icon_info.png") no-repeat scroll 0 0 !important;
}
.bill-action.quant-cart {
    margin-left: -10px !important;
}
.qte-refequip_popin{
    margin-top: -10px;
}
.icon-autodata.show-0{
    display: none !important;
}
.capacity_value{
    width: 34px;
    border: thin solid #e6e6e6;
    padding: 3px 5px;
    text-align: left;
    font-size: 12px;
}

.addRefManuelle {
    display: none;
}

.DEborderNull{
    border: 0 none;
}
.DErefManValider {
    background: red none repeat scroll 0 0;
    color: #fff;
    cursor: pointer;
    float: right;
    font-weight: bold;
    padding: 10px 20px;
    width: auto;
}
#DEMainOeuvreTotalOrigine{
    font-size: 14px;
    margin-right: 5px;
    text-decoration: line-through;
    display: none;
    color: #e6e6e6;
}

.remove_forfaits {
    float: left;
    margin-right: 5px;
    color: #0f5e9b;
    cursor: pointer;
    margin-top: 10px;
    padding-top: 8px;
    width: 159px;
    height: 31px;
    border: 1px solid #ccc;
    text-align: center;
    font-weight: 700;
    background: linear-gradient(to left,#9c9e9f 0,#9c9e9f 21%,#f6f6f6 21%,#f6f6f6 4%);
    display: none;
}
.remove_forfaits label{
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    max-width: 115px;
}
.remove_forfaits span {
    width: 34px;
    height: 32px;
    float: right;
    margin-top: -10px;
}
.remove_forfaits img {
    float: right;
    margin-right: 6px;
    margin-top: 6px;
    width: 20px;
    height: 22px;
}
#btnForfait{
    display: inline-block;
    margin-top: 10px;
}
.selectForfait{
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../../../images/icon_down_arrow.png) 90% 12px no-repeat;
    padding: 5px 25px 5px 5px;
    width: 213px;
    height: 34px;
    font-size: 11px;
    color: #0f5e9b;
    font-weight: 700;
    float: right;
    display: inline-block;
}
.labelSelectFotrfait{
    display: inline-block;
    margin-top: 12px;
    color: #0f5e9b;
    font-weight: 700;
}

#modalSaveDevis .modal-header .close, #modalValidateDevis .modal-header .close{
    margin-top: -55px !important;
}
#modalSaveDevis .DEiBleu{
    margin-left: -17px;
}
