@import 'vendor/colors';

.advertising-banner-limitation-label {
  left: 5px;
  text-align: center;
  span.limitation-label {
    font-size: 13px;
    margin-top: 2px;
    margin-left: 10px;
  }
  img {
    height: 17px;
    margin-bottom: 3px;
  }
  span.content-desc {
    visibility: hidden;
    padding: 10px;
    position: absolute;
    color: $black;
    width: 228px;
    left: -65px;
    top: 37px;
    text-align: center;
    background-color: #fff;
    z-index: 10;
    border: 1px solid $black;
    height: auto;
  }
  &.ps-advertising-banner {
    span.content-desc {
      left: -20px;
    }
  }
  span.content-desc::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 81%;
    margin-left: -12px;
    width: 0;
    height: 0;
    border-bottom: 14px solid $black;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
  }
  span.content-desc::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 81%;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-bottom: 13px solid $white;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }
  &:hover {
    span.content-desc {
      visibility: visible;
    }
  }
  &.consumableProduct {
    z-index: 5;
  }
}

#popinFicheProduit {
  .ps-advertising-banner {
    span.content-desc {
      left: -76px;
      width: 245px;
      padding: 12px;
    }
  }
}