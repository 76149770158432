@import '../vendor/quotes-packages';
/* Utility classes */

.no-padding {
    padding: 0;
}

.padding-right-15{
    padding-right: 15px;
}

.margin-right-12{
    margin-right: 12px;
}

.padding-15 {
    padding: 15px;
}

.inline-block{
    display: inline-block;
}

.box-selection-one-third{
    width: 303px;
    vertical-align: top;
}

.border-grey-no-bottom {
    border-top: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
}
.border-red-no-bottom, #devis-maintain-content li.selected{
    border-top: 1px solid #e10f21;
    border-right: 1px solid #e10f21;
    border-left: 1px solid #e10f21;
}

.border-grey-top-bottom {
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
}

.border-red-top-bottom, li.selected div.border-grey-top-bottom  {
    border-top: 1px solid #e10f21;
    border-bottom: 1px solid #e10f21;
}

.border-grey-top{
    border-top: 1px solid #e6e6e6;
}

.border-red-top, li.selected div.border-grey-top{
    border-top: 1px solid #e10f21;
}

.align-right{
    text-align: right;
}

.bg-blue{
    background-color: #0f5e96;
}

.bg-red, li.selected div.bg-blue{
    background-color: #e10f21;
}

.blue{
    color: #0f5e96;
}

.btn-quotes-comparative{
    background-color: $yellowLevel3;
    float: right;
    margin-top: 20px;
    color: $black;
}

.red, li.selected p.blue{
    color: #e10f21;
}

.relative{
    position: relative;
}

.float-right{
    float: right;
}

.float-left{
    float: left;
}


/* End Utility classes */



/*** Components classes ***/

/* Button */

div.btn-classic{
    display: inline-block;
}

div.btn-classic a{
    font-size: 12px;
    text-transform: uppercase;
    padding: 10px 15px;
    color: #fff;
}

div.btn-classic a.btn-blue{
    background: #0f5e96;
}

div.btn-classic a.btn-red{
    background: #e10f21;
}

div.btn-classic a.btn-grey{
    background: #b2b2b2;
}

/* Popin */

.modal{
    z-index: 15;
}

.modal h1 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    color: black;
    margin: 0;
    position: relative;
    text-indent: 25px;
}

.modal .modal-content .modal-header .close .close-btn {
    background: url(../../../images/devis/close_red.png) no-repeat center center;
    height: 24px;
    width: 21px;
}

/* Tables */

.table {
    width: 100%;
    border: 1px solid #e6e6e6;
}

.table .thead {
    font-weight: bold;
    color: #0f5e96;
}

.table .thead .th,
.table .tbody .tr .td {
    display: inline-block;
    padding: 10px;
}

.table .tbody .tr {
    border: 1px solid #e6e6e6;
    margin: 3px;
}

.table .tbody .tr.selected {
    border: 1px solid #e10f21;
}


/* End Components classes */


.devis-fiche{
    top: 20px !important;
}

div.devis-maintain{
    font-family: arial, sans-serif;
    padding: 15px 0;
}

div.devis-maintain section{
    margin-bottom: 30px;
}

div.change-vehicle h1 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    color: black;
    margin: 0;
    position: relative;
}

/*TODO à refacto*/
div.devis-maintain section h2 {
    font-size: 16px;
    text-transform: uppercase;
    color: black;
    margin-bottom: 20px;
    position: relative;
}

div.change-vehicle h1::after {
    content: '';
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 5px;
    display: block;
    width: 80px;
    left: 0px;
    border-bottom: 4px solid #e10f21;
}

div.devis-maintain section > p span {
    color: #0f5e96;
    font-weight: bold;
}

div.devis-maintain section .prestations-list {
    margin: 15px 0;
}

div.devis-maintain section .prestations-list > li > div > p {
    font-weight: bold;
    margin-bottom: 15px;
}

div.devis-maintain section .prestations-list > li > div > div span {
    font-size: 10px;
    color: #8c8c8c;
}

div.devis-maintain section .prestations-list > li >  div > div span:first-of-type {
    color: #9a9a9a;
    padding-right: 40px;
}

div.devis-maintain section .prestations-list > li .operation-list {
    position: relative;
    padding: 15px;
    cursor: pointer;
}

div.devis-maintain section .prestations-list > li .operation-list .picto_infos_tech,  h1 i.picto_infos_tech{
    background: url(../../../images/devis/picto_infos_techniques.png) no-repeat;
    position: absolute;
    top: 14px;
    left: 15px;
    height: 16px;
    width: 10px;
}

div.devis-maintain section .prestations-list > li .operation-list .picto_pieces,
.list-operations h1 i.picto_pieces
{
    background: url(../../../images/devis/picto_pieces.png) no-repeat;
    position: absolute;
    top: 14px;
    left: 15px;
    height: 16px;
    width: 16px;
}

div.devis-maintain section .prestations-list > li .operation-list .dropdown_arrow_down{
    background: url(../../../images/devis/arrow_down_blue.png) no-repeat;
    position: absolute;
    top: 20px;
    right: 15px;
    height: 6px;
    width: 12px;
}

div.devis-maintain section .prestations-list > li .operation-list .dropdown_arrow_down.rotate {
    background-image: url(../../../images/devis/arrow_up_blue.png) !important;
}

div.devis-maintain section .prestations-list > li .operation-list .picto_i_bleu,
.list-operations h1 i.picto_i_bleu{
    background: url(../../../images/devis/picto_i_bleu.png) no-repeat;
    position: absolute;
    top: 14px;
    left: 15px;
    height: 16px;
    width: 16px;
}

div.devis-maintain section .prestations-list > li .operation-list p {
    color: #9a9a9a;
    margin: 0;
    padding-left: 30px;
}

div.btn-select-full{
    width: 100%;
    padding: 10px 0;
    text-align: center;
}

div.btn-select-full a{
    color: white;
    text-transform: uppercase;
    padding: 10px 15px;
}


/***** Section Prestations secondaires (step 2) *****/

div.devis-maintain #prestations-secondaires > p {
    margin-bottom: 15px;
}

div.devis-maintain #prestations-secondaires .table {
    width: 100%;
    border: 1px solid #e6e6e6;
}

div.devis-maintain #prestations-secondaires .table .thead {
    font-weight: bold;
    color: #0f5e96;
}

div.devis-maintain #prestations-secondaires .table .thead .th,
div.devis-maintain #prestations-secondaires .table .tbody .tr .td {
    display: inline-block;
    padding: 10px;
}

div.devis-maintain #prestations-secondaires .table .thead .th:nth-of-type(1),
div.devis-maintain #prestations-secondaires .table .tbody .tr .td:nth-of-type(1){
    width: 200px;
}

div.devis-maintain #prestations-secondaires .table .thead .th:nth-of-type(2),
div.devis-maintain #prestations-secondaires .table .tbody .tr .td:nth-of-type(2){
    width: 110px;
}

div.devis-maintain #prestations-secondaires .table .thead .th:nth-of-type(3),
div.devis-maintain #prestations-secondaires .table .tbody .tr .td:nth-of-type(3){
    width: 450px;
}

div.devis-maintain #prestations-secondaires .table .thead .th:nth-of-type(4),
div.devis-maintain #prestations-secondaires .table .tbody .tr .td:nth-of-type(4){
    width: 120px
}

div.devis-maintain #prestations-secondaires .table .tbody .tr {
    border: 1px solid #e6e6e6;
    margin: 3px;
}

div.devis-maintain #prestations-secondaires .table .tbody .tr.selected {
    border: 1px solid #e10f21;
}

div.devis-maintain #prestations-secondaires .table .tbody .tr .td:nth-of-type(4) .picto_infos_tech,
div.other-operation i.picto_infos_tech {
    background: url(../../../images/devis/picto_infos_techniques.png) no-repeat;
    position: absolute;
    top: 8px;
    left: 10px;
    height: 16px;
    width: 10px;
    cursor: pointer;
}

div.devis-maintain #prestations-secondaires .table .tbody .tr .td:nth-of-type(4) .picto_pieces,
div.other-operation i.picto_pieces {
    background: url(../../../images/devis/picto_pieces.png) no-repeat;
    position: absolute;
    top: 9px;
    left: 40px;
    height: 16px;
    width: 16px;
    cursor: pointer;
}

div.devis-maintain #prestations-secondaires .table .tbody .tr .td:nth-of-type(4) .picto_i_bleu,
div.other-operation i.picto_i_bleu {
    background: url(../../../images/devis/picto_i_bleu.png) no-repeat;
    position: absolute;
    top: 9px;
    left: 70px;
    height: 16px;
    width: 16px;
    cursor: pointer;
}

div.devis-maintain #prestations-secondaires .table .tbody .tr .td:nth-of-type(4) input[type="checkbox"] {
    float: right;
}

div.devis-maintain .nav-bottom-btns div.btn-classic a.btn-blue{
    padding: 10px 20px 10px 30px;
}

div.devis-maintain .nav-bottom-btns div.btn-classic a.btn-red{
    padding: 10px 30px 10px 20px;
}

div.devis-maintain .nav-bottom-btns {
    margin: 20px 0 10px;
}

div.devis-maintain .nav-bottom-btns .btn-classic .arrow-before {
    background: url(../../../images/devis/arrow-white-p.png) no-repeat;
    position: absolute;
    top: 2px;
    left: 10px;
    height: 16px;
    width: 16px;
}

div.devis-maintain .nav-bottom-btns .btn-classic .arrow-after {
    background: url(../../../images/devis/arrow-white.png) no-repeat;
    position: absolute;
    top: 2px;
    right: 0;
    height: 16px;
    width: 16px;
}

/* Popin Choisir autre opération */

div.other-operation .modal-dialog{
    font-family: arial, sans-serif;
    font-size: 14px;
    width: 600px;
}

div.other-operation h1 i.picto_infos_tech,
div.list-operations h1 i.picto_infos_tech{
    background: url(../../../images/devis/picto_infos_techniques.png) no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 10px;
}

.popin .table {
    width: 100%;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
}

div.other-operation .modal-body .table .thead .th:nth-of-type(1),
div.other-operation .modal-body .table .tbody .tr .td:nth-of-type(1){
    width: 170px;
}

div.other-operation .modal-body .table .thead .th:nth-of-type(2),
div.other-operation .modal-body .table .tbody .tr .td:nth-of-type(2){
    width: 170px;
}

div.other-operation .modal-body .table .thead .th:nth-of-type(3),
div.other-operation .modal-body .table .tbody .tr .td:nth-of-type(3){
    width: 140px;
}


div.other-operation div.btn-classic {
    margin: 20px 0;
}

div.other-operation div.btn-classic a{
    font-weight: bold;
    padding: 18px 30px 18px 45px;
}

div.other-operation .modal-body i.i-validate{
    background-image: url(../../../images/devis/validate.png);
    height: 13px;
    width: 14px;
    position: absolute;
    top: 3px;
    left: 16px;
}

/* End Popin Choisir autre opération */


/* Popin Liste des opérations */

div.list-operations .modal-dialog{
    font-family: arial, sans-serif;
    width: 500px;
}

div.list-operations .modal-dialog .modal-body{
    padding: 0;
}

div.list-operations .modal-dialog p{
    font-size: 13px;
    font-style: italic;
    color: #8c8c8c;
}
div.list-operations .modal-dialog p span{
    color: black;
}

div.list-operations .modal-dialog .iconClosed {
    left: 457px !important;
    top: 20px !important;
    height: 6px !important;
    width: 12px !important;
    background-image: url(../../../images/devis/arrow_down_blue.png) !important;
}

div.list-operations .modal-dialog .iconOpen {
    background-image: url(../../../images/devis/arrow_down_blue.png) !important;
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    left: 457px !important;
    top: 20px !important;
    height: 6px !important;
    width: 12px !important;
}

div.list-operations .modal-dialog ul li{
    font-size: 13px;
    list-style-type: none;
    line-height: 1.4;
    background: none;
    color: #8c8c8c;
    position: relative;
    font-weight: normal;
}

div.list-operations .modal-dialog ul li .sub-operation-list{
    padding-left: 15px;
}

div.list-operations .modal-dialog ul li .sub-operation-list.hide {
    display: none;
}

div.list-operations .modal-dialog ul li i.menu_minus {
    background: url(../../../images/devis/menu_minus.png) no-repeat !important;
    position: absolute;
    top: 4px;
    left: -15px;
    height: 9px;
    width: 9px;
}

div.list-operations .modal-dialog ul li i.menu_plus {
    background: url(../../../images/devis/menu_plus.png) no-repeat;
    position: absolute;
    top: 4px;
    left: -15px;
    height: 9px;
    width: 9px;
}

div.list-operations .modal-dialog .modal-body .ui-state-default {
    border: none;
}

section.accordion div{
    font-size: 13px;
    margin: 0;
    border-top: 1px solid #b2b2b2;
    padding-left: 15px;
    background-color: #fff;
    position: relative;
    cursor: pointer;
}

section.accordion div p{
    margin: 0;
}

.accordion ul {
    padding: 0em 2.2em;
}

.accordion i.dropdown_arrow_down{
    background: url(../../../images/devis/arrow_down_blue.png) no-repeat;
    position: absolute;
    top: 20px;
    right: 15px;
    height: 6px;
    width: 12px;
}

.accordion i.dropdown_arrow_down.rotate{
    background-image: url(../../../images/devis/arrow_up_blue.png) !important;
}

.list-operations h1 i.picto_infos_tech,
.list-operations h1 i.picto_pieces,
.list-operations h1 i.picto_i_bleu{
    top: 0;
    left: 0;
}

li.parts{
    cursor: pointer;
}

p.info-text{
    padding-left: 15px;
}

div.devis-maintain section .prestations-list > li .operation-list p.operation-info{
    color: #565656;
    font-size: 12px;
    font-weight: normal;
    padding: 10px 0;
}

div.list-operations .modal-dialog .modal-body .ui-accordion-header{
    font-size: 13px;
    margin: 0;
    border-top: 1px solid #b2b2b2;
    padding-left: 15px;
    padding-top: 0;
    background-color: #fff;
}

li.pieces-text{
    padding: 5px 0px 0px 15px;
}

div.list-operations .modal-dialog .modal-body .ui-accordion-header:focus {
    outline: none;
}

div.list-operations .modal-dialog .modal-body .ui-accordion-header:first-of-type{
    border-top: none;
}

div.list-operations .modal-dialog .modal-body .ui-accordion-header p span{
    font-style: normal;
    color: #333333;
}

div.list-operations .modal-dialog .modal-body .ui-corner-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

div.list-operations .modal-dialog .modal-body .ui-state-active {
    color: #454545;
    border-top: 1px solid #8c8c8c;
    background-color: #fff;
}

div.list-operations .modal-dialog .modal-body .ui-state-active:focus {
    outline: none;
}

div.list-operations .modal-dialog .modal-body .ui-accordion-content {
    border: none;
    list-style-type: none;
    padding-top: 0;
}


/* End Popin Liste des opérations */



/***** End Section Prestations secondaires (step 2) *****/

div.steps div.step-1{
    background: url(../../../images/quote_sprite.png) no-repeat -10px -10px;
    width: 970px;
    height: 23px;
}

div.steps div.step-2{
    background: url(../../../images/quote_sprite.png) no-repeat -10px -53px;
    width: 970px;
    height: 23px;
}

div.steps div.step-3{
    background: url(../../../images/quote_sprite.png) no-repeat -10px -96px;
    width: 970px;
    height: 23px;
}

div.steps div.step-4{
    background: url(../../../images/quote_sprite.png) no-repeat -10px -139px;
    width: 970px;
    height: 23px;
}

div.vehicle ul{
    list-style: none;
    display: block;
    padding: 0;
    overflow: hidden;
    margin: 0;
}

div.vehicle{
    border: #e6e6e6 solid 1px;
    padding: 15px 0px 10px 15px;
    margin-top: 30px;
    margin-bottom: 15px;
}

div.vehicle ul li{
    width: 15%;
    color: #6e6e6e;
    font-size: 11px;
    float: left;
    padding-left: 10px;
}

div.vehicle ul li.first{
    width: 40%;
}

div.vehicle ul li.first.immat{
    width: 35%;
}

div.vehicle ul.details li.second{
    font-size: smaller;
    width: 20%;
    margin-top: 4px;
}

div.vehicle ul li.second{
    width: 20%;
}

div.vehicle ul li.date-vehicule{
    padding-left: 30px;
}

div.vehicle ul.header li{
    font-weight: normal;
}

div.vehicle ul.details li{
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    padding-right: 2px;
    color: black;
    padding-top: 5px;
}

i.arrow-red{
    background-image: url("../../../images/devis/arrow-red.png");
    height: 7px;
    width: 4px;
    position: absolute;
    top: 3px;
    left: -10px;
}

div.button-vehicle {
    text-align: right;
    font-size: 12px;
    a.add{
        color: white;
        background: #0f5e9b;
        text-transform: uppercase;
        padding: 10px 15px;
    }
    a.fiche{
        margin-right: 10px;
        color: #005fa7;
        position: relative;
    }
    span.selected{
        color: white;
        background: green;
        text-transform: uppercase;
        padding: 10px 15px;
    }
}

.main {
    a.autodata_load {
        color: #0f5e96;
    }
    .picto-red {
        color: #e10f21;
    }
    .blue-text{
        color: #0f5e96;
        cursor: default;
    }
}

div.content-bottom a.devis-next{
    background: #e10f21;
    padding: 8px 50px 8px 15px;
    color: white;
    text-transform: uppercase;
    position: relative;
}

i.arrow-next-step{
    background-image: url("../../../images/devis/arrow-white.png");
    height: 12px;
    width: 6px;
    position: absolute;
    top: 9px;
    right: 20px;
}

i.right-spinner,
i.right-spinner-blue{
    font-size: 16px;
    position: absolute;
    top: 7px;
    right: 20px;
}
i.left-spinner{
    position: absolute;
    left: 10px;
    font-size: 16px;
}
i.right-spinner-blue{
    top: 0px;
    right: 10px;
}

i.spinner-operation{
    display: none;
}

div.devis-error{
    text-align: right;
    margin-top: 10px;
    color: #e10f21;
}
.toggle-condition{
    width: 50px;
}

.toggle-condition .toggle-blob {
    background: #f8f8f8;
    border-radius: 10px;
    margin-left: -15px !important;
}

.toggle-condition .toggle-blob {
    background: #0f5e96;
}

.toggle-condition .toggle-off.active{
    margin-left: -13px !important;
}

.toggle-condition .toggle-on{
    width: 45px !important;
}
.conditions .row{
    font-size: 13px;
    color: #6e6e6e;
    font-weight: bold;
    margin-bottom: 20px;
}

.question-text {
    float: left;
    display: block;
    text-align: left;
    font-weight: normal;
}

.question-input input{
    width: 140px;
    margin-right: 15px;
    margin-top: -3px;
}

.question-left {
    float: right;
    padding-right: 15px;
    padding-left: 5px;
}

.conditions div.wrapper-ios{
    float: right;
}

.conditions [type="checkbox"]:not(:checked) + label:before{
    content: '';
    position: absolute;
    left: 2px;
    top: 3px;
    width: 1.25em; height: 1.25em;
    background: #0f5e9b;
}

.conditions .ios-label:before{
    /*box-shadow*/
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none;
}

.conditions [type="checkbox"]:checked + label:before {
    border: none;
}

.checkbox-ios:checked + .ios-label{
    /*box-shadow*/
    -webkit-box-shadow:inset 0 0 0 26px #0f5e96,0 0 0 2px #0f5e96;
    -moz-box-shadow:inset 0 0 0 26px #0f5e96,0 0 0 2px #0f5e96;
    box-shadow:inset 0 0 0 26px #0f5e96,0 0 0 0px #0f5e96;
}

.question-toggle {
    float: right;
}

.question-right {
    float: right;
    text-align: left;
    padding-right: 10px;
}

.question-right.active, .question-left.active{
    color: #0f5e9b;
}

div.schedules{
    text-align: left;
    font-size: 13px;
    line-height: 25px;
}

div.range-from{
    float: left;
    width: 40px;
    font-size: 13px;
}

div.range{
    float: left;
    width: 430px;
    margin-top: 5px;
    margin-right: 10px;
}

div.range-to{
    float: left;
    font-size: 13px;
}

span.range-ie9{
    display: none;
}

div.radio-schedule{
    position: relative;
}

div.radio-schedule label{
    margin-left: 18px;
}

div.radio-schedule input[type=radio]{
    position: absolute;
    margin-top: 6px;
}

div.change-vehicle.modal{
    z-index: 100;
}

div.change-vehicle div.modal-content{
    top: 110px;
    margin-bottom: 30px;
}

div.change-vehicle h1{
    text-indent: 25px;
}

div.change-vehicle i.change{
    background-image: url(../../../images/devis/exchange.png);
    height: 13px;
    width: 11px;
    position: absolute;
    top: 1px;
    left: 0;
}

div.change-vehicle h2{
    font-size: 12px;
    text-transform: none;
    margin: 10px 0 5px 0;
}

div.change-vehicle div.vehicle{
    margin: 10px 0 0 0;
    cursor: pointer;
}
#devis-maintain-content .vehicle.active,
div.change-vehicle div.vehicle.active{
    border-left: 4px solid $blue9;
}

div.change-vehicle div.vehicle ul li{
    background: none;
    font-family: arial, sans-serif;
}

div.change-vehicle div.button-vehicle{
    margin-top: 25px
}

div.change-vehicle a.validate {
    background: #e10f21;
    padding: 13px 40px;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 11px;
    position: relative;
}
.validate-change-vehicle {
    background: $red;
    padding: 13px 40px;
    color: $white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 11px;
    position: relative;
    &:hover, &:active {
        color: $white !important;
    }
}
.select-vehicle-link {
    display: block;
    margin-bottom: 15px;
    margin-top: 15px;
    border-left: 4px solid transparent;
    &:hover {
        border-left-color: $blue9;
    }
    .vehicle {
        margin: 0;
    }
}
i.i-validate{
    background-image: url(../../../images/devis/validate.png);
    height: 13px;
    width: 14px;
    position: absolute;
    top: 13px;
    left: 15px;
}

div.change-vehicle div.button-vehicle a.fiche{
    font-family: arial, sans-serif;
    font-weight: bold;
}

/******** range css ***********/
input[type=range] {
    -webkit-appearance: none;
    border: 1px solid white;
    padding: 0;
}

@media screen and (min-width:0\0){
    input[type=range] {
        margin-top: -5px;
    }
}
input[type=range]::-webkit-slider-runnable-track {
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
}
input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #0f5e9b;
    cursor: pointer;
    margin-top: -5px;
}
input[type=range]:focus {
    outline: none;
}
input[type=range]:focus::-webkit-slider-runnable-track {
    background: #ccc;
}

input[type=range]::-moz-range-track {
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
}
input[type=range]::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #0f5e9b;
    cursor: pointer;
}

input[type=range]:-moz-focusring{
    outline: 1px solid white;
    outline-offset: -1px;
}

input[type=range]::-ms-track {
    height: 5px;
    background: transparent;
    border-color: transparent;
    border-width: 6px 0;
    color: transparent;
}
input[type=range]::-ms-fill-lower {
    background: #777;
    border-radius: 10px;
}
input[type=range]::-ms-fill-upper {
    background: #ddd;
    border-radius: 10px;
}
input[type=range]::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #0f5e9b;
    cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
    background: #888;
}
input[type=range]:focus::-ms-fill-upper {
    background: #ccc;
}

output {
    position: absolute;
    text-align: center;
    display: inline-block;
    font-size: 13px;
    margin-left: 30px;
    color: #0f5e9b;
    margin-top: 5px;
}

/***** Step 4 *****/

div.devis-confirm section:first-of-type{
    margin-bottom: 40px;
}

div.devis-confirm section ul {
    list-style-type: none !important;
}

div.devis-confirm section li{
    position: relative;
    margin-bottom: 20px;
}

div.devis-confirm section li p{
    font-weight: bold;
}

div.devis-confirm section li i.check-blue{
    background: url(../../../images/devis/cercle_etape_valide.png) no-repeat;
    position: absolute;
    top: 0;
    left: -30px;
    height: 12px;
    width: 12px;
}

div.devis-confirm section li a{
    font-size: 11px;
    color: #b2b2b2;
    font-weight: normal;
    text-decoration: underline;
    padding-left: 15px;
}

div.devis-confirm section .divider{
    width: 100%;
    border-bottom: 1px solid #b2b2b2;
    margin: 5px 0 10px ;
}

div.devis-confirm section label {
    font-size: 11px;
}

div.devis-confirm section h2 {
    font-size: 26px;
    font-weight: bold;
    color: #0f5e96;
    margin: 0 0 10px 0;
}

div.devis-confirm section h2 span {
    font-size: 16px;
}

div.devis-confirm section h2 .total-price {
    border: 2px solid #e2e2e2;
    font-size: 26px;
}

div.devis-confirm section.price-details{
    margin-bottom: 20px;
}

div.devis-confirm section.price-details p {
    color: #8c8c8c;
    font-size: 12px;
}

div.devis-confirm section.price-details .total .mention {
    padding: 30px 0 0;
}

div.devis-confirm section.price-details .total .mention p span {
    color: #0f5e96;
    font-weight: bold;
}

div.devis-confirm .nav-bottom-btns div.btn-classic a.btn-red{
    padding: 10px 20px 10px 15px;
}
.divToLeft {
    float: left !important;
}
.divTotalResized {
    width: 45%;
}

.DESumTotal {
    color: #0f5e96;
    font-size: 25px;
    font-weight: bold;
    height: 39px;
    width: 110px;
}

div.radio-vehicle [type="radio"]:checked,
div.radio-vehicle [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

div.radio-vehicle [type="radio"]:not(:checked) + label,
div.radio-vehicle [type="radio"]:checked + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}

div.radio-vehicle [type="radio"]:not(:checked) + label:before,
div.radio-vehicle [type="radio"]:checked + label:before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}

div.radio-vehicle [type="radio"]:checked + label:before{
    border: 1px solid #e10f21;
}

div.radio-vehicle [type="radio"]:checked + label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: #e10f21;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.hideSchedule {
    display: none;
}

.DEPopinRefEqui.modal {
    z-index: 11;
}

.kilometer {
    &--text {
        font-size: 13px;
        color: $black;
        line-height: 25px;
    }
}

select#mySelectOil {
    width: 85px;
}

.oil-preferred {
    position: absolute;
    margin-top: -18px;
    left: 216px;
    font-style: italic
}
.oil-infos {
    position: relative;
    font-style: italic;
    bottom: -8px;
    left: -6px;
}

