/*
adaptation main.css fourni par PLANNET
*/
/*
    Created on : 3 nov. 2014,
    Author     : Tayeb Chikhi
*/
/* PT Promotions */
/* line 40, ../sass/bootstrap/mixins/_grid-framework.scss */

/*
.col-xs-8 {
    width: 30%;
}
*/

/* line 42, ../sass/bootstrap/mixins/_grid-framework.scss */
.ie7 .col-xs-8 {
    width: 29%;
}

/* line 47, ../sass/autossimo_pieces/_promotion.scss */
.buttons {
    left: 8px;
    margin-top: 5px;
}

.dates {
    font-size: 14px;
    text-transform: capitalize;
}

/* PT dimension image promotion */
/* line 464, ../sass/autossimo_pieces/_produit.scss */
/* line 1, ../sass/autossimo_pieces/_diaporama.scss */
.DiaporamaVignette {
    /*border: 8px solid #f8f8f8;*/
    min-height: 30px;
}
/* line 10, ../sass/autossimo_pieces/_diaporama.scss */
.bloc-promotions .DiaporamaVignette {
    min-height: 30px;
}



/* PT Formulaire */
.form_element { margin-bottom:5px}
.form_element label { display:inline-block; padding-top:5px}
.form_element input {display:inline-block}


/* PT Fiche liaison */
.form_fiche_liaison .form_element label { width:200px}
.form_element input { width:200px}
.form_element input.fullsize { width:400px}
.form_fiche_liaison textarea.fullsize { width:400px; margin-bottom:5px}
.popin input[type="text"], .popin input[type="email"], .popin input[type="password"] {
    width: 400px;
}
#modalForgotPassword input[type="email"] {
    width: 100%;
    margin: 0;
}
.form_element input.fullsize {
    width: 400px;
}
/* PT Fiche liaison immat */
.form_value{
    padding-top: 5px;
    display: inline-block;
}
.form_fiche_liaison .form_element.inputradio label {
    float: none;
    width: 96%;
}
.form_fiche_liaison .form_element.inputradio label:after {
    display: block;
}
.form_fiche_liaison .form_element.inputradio input {
    float: none;
    margin-right: 5px;
    width: auto;
}


/* PT Popin Carte Grise */
.popin-list { list-style:none; margin-left:20px}
.popin ul.popin-list li { background:none; margin-bottom:5px}
.popin-list strong { display:inline-block; width:250px}
.btn-action .popin-btn { background: #565656; margin-left: 15px;  padding: 5px 15px; color: #fff; display: inline-block; font-size: 12px;font-family: Arial, sans-serif; font-weight: bold;}
.btn-action .popin-btn-valid { background: #d93f01; margin-left: 15px;  padding: 5px 16px; color: #fff; display: inline-block; font-size: 12px;font-family: Arial, sans-serif; font-weight: bold;}

/* PT Transmission */
.form_transmission textarea { width:550px}
.form_transmission .form_element label { width:210px}
.form_transmission .form_element input { width:334px}
.form_transmission .form_element { margin-top:5px}

/* PT Compte ERP */
.form_compte_erp .form_element label { width:220px}
.form_compte_erp .form_element input { width:150px}

/* PT Popin Fiche Vehicule */
/*#popinFicheVehicule {
    margin-top:-150px;
}*/

/* PT Popin */
#popinAiv {
    margin-top:300px;
}

#popinAiv.modal {
    position: absolute;
}

.ie7 #popinAiv.modal {
    top: 21px;
    left: 25px;
}

#popinAiv .modal-dialog {
    width: 630px;
}

#popinAiv .modal-header {
    border-bottom: none;
    padding: 10px 60px 10px 20px;
}

#popinAiv .modal-body {
    padding-right: 60px;
}

#popinAiv .close {
    position: absolute;
    top: 17px;
    right: 9px;
}

#popinAiv .close button {
    background: url('../../../images/close_button.jpg?1413533134') no-repeat;
    width: 21px;
    height: 24px;
    border: none;
}

#popinAiv .nav > li > a {
    padding: 5px 10px;
}

#popinAiv ul li {
    padding: 0;
    font-family: 'Futura LT', sans-serif;
}

#popinAiv h3, #popinAiv h4 {
    text-transform: uppercase;
}

#popinAiv h3 {
    font-family: 'Futura LT Heavy', sans-serif;
    font-size: 24px;
    display: inline-block;
}

#popinAiv .identite {
    position: relative;
}

#popinAiv .identite .row {
    border-bottom: solid 1px #ddd;
}
.ie7 #popinAiv .identite .row {
    clear: both;
}

#popinAiv .identite hr {
    margin: 0 0 4px;
    clear: both;
}

#popinAiv .cartouche {
    background: #646464;
    color: #ffffff;
    position: absolute;
    top: -120px;
    right: 0px;
    width: 70%;
    padding: 5px 15px;
    margin-bottom: 8.5px;
    margin-top: 10px;
    font-size: 11px;
}

#popinAiv .cartouche a {
    color: #fff;
    text-decoration: underline;
}

.ie7 #popinAiv .cartouche {
    top: -111px;
}

#popinAiv .donnees .container .row a img {
    margin-right: 5px;
}

#popinAiv .donnees .container .donnees-visuels {
    background: #f8f8f8;
    min-height: 200px;
    padding-top: 10px;
}

#popinAiv .NbPortes {
    background: #646464;
    color: #ffffff;
    display: inline-block;
    padding: 0 10px;
    margin-left: -10px;
}

#popinAiv span.info-vehicule {
    display: inline-block;
    margin-right: 20px;
}

#popinAiv .container .row {
    border: none;
    margin: 0 0 0 20px;
}

#popinAiv .manuels .row.bordhgb {
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

#popinAiv .identite_vehicule_form {
    width:100%
}

#popinAiv .identite_vehicule_form .col1 {
    width:55%
}

#popinAiv .btn-holder {
    padding:10px 0 27px 0
}

#popinAiv .btn-holder .btn {
    text-transform:uppercase
}

/* Pour le lien depuis le fil d'ariane sur la sous famille '*/
.ariane .slide a {
    color:#2a2a2a;
    font-size: 12px;
}

/* Probleme icone fiche vehicule */
#popinFicheVehicule .donnees .container .row a img {
    margin-right:2px;
}

.table-result-search tbody .name {
    width: 340px;
}

/* zoom éclaté  */
.popinFamilles .piece_sousFamille .legend {
    padding-left: 5px;
    padding-top: 30px;
}

.ariane .family .slide .holder-repere,
.ariane .subfamily .slide .holder-repere,
.ariane .subsubfamily .slide .holder-repere {
    width: 999px; /* 1024px; */
}

.ariane .subfamily .slide,
.ariane .subsubfamily .slide {
    left: -400px;
}

.references-details a {
    color: #565656;
    text-decoration: underline;
}

/* Fiche produit véhicule compatible */

.product .bloc-vehicules-compatibles .vehicules .list_vehicules .vehicule {
    float: left;
    width: 265px;
}
.product .bloc-vehicules-compatibles .vehicules .list_vehicules .type_mine {
    float: left;
    width: 250px;
}

/* Affiche tooltip nb fiches de liaison */
.scrolled .wrapper .header-nav {
    z-index:8999;
}

.tooltip {
    z-index:9000;
}

/* Place holder login */
input.address:-ms-input-placeholder {
    font-style:italic;
    color: red;
    background-color: yellow;
}

/* Pour fiche produit et tarif origine */
.alignRight {
    text-align: right;
}

#popinLegendesPneus {
    margin-left: auto;
    margin-right: auto;
    width: 456px;
}

#popinLegendesPneus .modal-header {
    position: absolute;
    background-color: white;
    width: 456px;
    top: 0;
    left: 0;
    height: 42px;
    border-bottom: none;
    padding: 0;
}

#popinLegendesPneus .modal-header .title {
    position: absolute;
    margin: 0;
    top: 10px;
    left: 3px;
    padding: 0;
}

#popinLegendesPneus .modal-header .close {
    position: absolute;
    margin: 0;
    top: 5px;
    right: 9px;
    padding: 0;
}

#popinLegendesPneus .modal-body {
    position: absolute;
    left: 0px;
    top: 42px;
    z-index: 3;
    width: 456px;
    height: 200px;
    background-color: #0F5E96;
    overflow: hidden;
    border-radius: 2px;
    padding: 0px;
    padding-top: 8px;
}

#popinLegendesPneus #label_legendes {
    z-index: 4;
    position: absolute;
    left: 16px;
    bottom: 7px;
}

#popinLegendesPneus #pneu_legendes {
    z-index: 3;
    position: absolute;
    right: -233px;
    bottom: -203px;
}

#popinLegendesPneus #chiffres_pneu_legendes {
    z-index: 3;
    position: absolute;
    right: 17px;
    bottom: 7px;
}

div.affectation-origine .row.main img.verified-badge { display:none; }
div.affectation-origine div.result.verified .row.main img.verified-badge { display: inline; height: 15px; }
a.help_link_pdf{color: #0092ef !important}
.video_peinture{border: 1px black solid;}




.image_box_search_result {
    height: 119px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fav-btn {
    right: 0px;
    z-index: 1;
    font-size: 23px !important;
    color: #757575;
    width: 31px;
    height: 31px;
    position: absolute;
}
.product_file {
    font-size: 18px !important;
    position: relative;
    right: 2px;
    color: #0f5e96;
}
.zoom-icon-piece{
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    font-size: 20px !important;
    color: #236B9F;
}
#zoom-img-popin {
    background: rgba(0,0,0,0.6);
    .owl-carousel .owl-item{
        text-align: center;
    }
    .owl-carousel .owl-item > a{
        display: inline-block;
        position: relative;
    }
    .owl-carousel .owl-item > a > img{
        display: inline-block;
    }
    .owl-height{
        height: auto !important;
    }
    .close-zoom-img{
        background: #000;
        width: 24px;
        height: 24px;
        line-height: 24px;
        color:#fff;
        display: inline-block;
        position: absolute;
        right: 0;
    }
}

.g-recaptcha {display: none;}
.grecaptcha-badge { visibility: hidden; }

.g-recaptcha > .grecaptcha-badge {
    position: static !important;
    box-shadow: none !important;
}
