$arial:  Arial,"Helvetica Neue",Helvetica,sans-serif;

.section_cart {
    width: 100%;
    background-color: $white;
    padding: 20px;

    h1 {
        color: #0f5e9b;
        font-family: Arial, sans-serif;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 6px;
    }

    hr {
        border-top: 1px solid #dcdcdc;
        margin: 15px 0;
    }

    &--header {
        &-count_results {
            display: inline;
            color: $black;
            font-family: Arial, sans-serif;
            font-size: 16px;
            font-weight: 400;
            margin-left: 6px;
            span {
                color: $blue;
                font-weight: 700;
            }
        }
    }

    &--total_price {
        p {
            color: #373737;
            font-family: Arial, sans-serif;
            font-size: 14px;
            font-weight: 400;
        }
        a {
            color: $blue;
            font-family: Arial, sans-serif;
            font-size: 12px;
            line-height: 19px;
            text-decoration: underline;
        }
    }

    &--body {
        &-item {
            border: 1px solid #dfdfdf;
            padding: 13px;
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            margin: 28px 0;
            position: relative;

            &--badge {
                position: absolute;
                width: 100%;
                height: 100%;
                overflow: hidden;
                top: -13px;
                left: -13px;
            }

            &--image_container {
                position: relative;
                width: 140px;
                height: 140px;
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &--info_container {
                flex: 1.8;
                padding-left: 20px;
                cursor: pointer;
                z-index: 2;
                &.flex3 {
                    flex: 2.8;
                }
                &-brand_ref {
                    &--brand {
                        color: $blue;
                        font-family: Arial, sans-serif;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 22.21px;
                        text-transform: uppercase;
                    }

                    &--ref {
                        color: #6e6e6e;
                        font-family: Arial, sans-serif;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 22.21px;
                        margin-left: 10px;
                    }
                }

                &-product_name {
                    text-transform: uppercase;
                    color: $black;
                    font-family: Arial, sans-serif;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 16px;
                }

                &-product_desc {
                    width: 70%;
                    font-family: Arial, sans-serif;
                    margin-top: 10px;
                    color: #595959;
                    font-size: 12px;
                    font-weight: 400;
                    text-transform: uppercase;
                }
            }

            &--info_price {
                flex: 2;
                position: relative;

                &-price_ttc {
                    color: $blue;
                    font-family: Arial, sans-serif;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 23.43px;
                    text-transform: uppercase;

                    .ttc_ht {
                        font-size: 18px;
                    }
                }

                &-price_ht {
                    color: #4c4c4c;
                    font-family: Arial, sans-serif;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 23.43px;
                    text-transform: uppercase;

                    &.red_price {
                        color: $red;
                        font-size: 18px;
                        font-weight: 700;

                        .ttc_ht {
                            font-size: 18px;
                        }
                    }
                }

                &-ecotaxe {
                    color: $blue;
                    font-family: Arial, sans-serif;
                    font-size: 12px;
                    line-height: 19px;
                    text-decoration: underline;
                }

                &--buttons {
                    position: absolute;
                    bottom: -6px;
                    right: 0;

                    a:first-child {
                        margin-right: 13px;
                    }

                    a:nth-child(2) {
                        margin-right: 16px;
                    }
                }
            }
        }
    }

    .pricing-commandes-info {
        .label {
            color: #262626;
            font-family: Arial, sans-serif;
            font-size: 14px;
            font-weight: 400;
            display: block;
        }

        input[name="send_order[refcde]"] {
            width: 100%;
            height: 35px;
            border: 1px solid #b1b1b1;
        }

        textarea[name="send_order[messagerep]"] {
            width: 100%;
            height: 117px;
            border: 1px solid #b1b1b1;
        }

        .send-order-error {
            font-size: 14px;
        }
    }

    .pricing_dispo {
        .relative {
            padding: 0;
            .dispo-details {
                &.dispoD, &.DispoE, &.DispoECPF, &.bloc-contact-your-distributor {
                    //padding-right: 13px !important;
                }
                width: 160px;
                text-align: left;

            }

            .update-bloc-pricing {
                float: left !important;
                //margin-right: 13px;

            }

            .dispoA {
                margin: 0;
                float: left;

                &.with-contact-us {
                    margin-bottom: 10px;

                    span {
                        height: auto;

                        &.inline-span {
                            display: inline !important;
                        }
                    }

                    a.tooltips {
                        &:hover {
                            span {
                                left: -175px;
                                width: 257px;
                            }
                        }
                    }
                }

                span {
                    width: 174px;
                    display: block;
                }
            }

            .bloc-contact-your-distributor {
                &.with-contact-us {
                    span {
                        display: block;

                        &.inline-span {
                            display: inline !important;
                        }
                    }

                    a.tooltips {
                        float: none;
                    }
                }
            }
        }

        .tooltips {
            float: right;
        }
        &.dispoD {
            .tooltips {
                float: none;
            }
        }

        .pfpt {
            position: absolute;
            left: -220px;
            top: 125px;
        }
    }
}

.custom_margin_right {
    padding: 0;
    position: relative;
    left: 15px;
    font-family: $arial;
    &.custom_left {
        left: -25px;
    }
    .rate-ttc{
        font-weight: bold;
        font-size: 18px;
        color: $blue;
    }
    .rate-ht{
        font-size: 14px !important;
        color: $black;
    }
    .net-price-container {
        display: block;
        padding-bottom: 10px;
        .net-price-rate-ttc {
            color: $redE;
            font-weight: bold;
            font-size: 18px;
        }
    }
    .price-level-bloc {
        width: 100%;
        float: right;
        margin-right: 18px;
        .selectPriceLevel {
            width: 200px;
        }
        .bootstrap-select {
            .dropdown-toggle {
                z-index: 0;
            }
        }
    }
    .discount-autover {
        font-weight: bold;
    }
}
.folder-link {
    font-size: 12px;
    font-weight: bold;
    flex: 1;
    span {
        i {
            color: $blue;
            font-size: 17px;
            position: relative;
            top: 8px;
        }
        display: block;
        &.folder-type {
            text-align: left;
            padding-left: 20px;
        }
    }
    a {
        padding-left: 20px;
        color: inherit;
        text-decoration: underline;
    }
}
