/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 14 mars 2017, 18:02:18
    Author     : mambakha
*/

.font-red{
    color : #E2061B;
}

.table-devis-bordered,  td {
    border: 1px solid #cbcbcb;
    margin-top: 30px;
}

.table-vente-bordered,  td {
    border: 0px;
    margin-top: 30px;

}

.tableDevisWorkforce {
    border: 0px;
    margin-top: 30px;
    width: 60%;
    border-collapse: collapse;
}

.table-blue-header {
    color: #0f5e96;
    font-weight: bold;
}

.edit.btn, .edit.btn:hover , .save.btn, .save.btn:hover{
    background: #d80000 none repeat scroll 30% center;
    color: #fff;
    font-family: Arial,"Helvetica Neue",Helvetica,sans-serif;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    margin-bottom: 10px;
}

.reset.btn{
    background: #ddd;
    color:#000;
    font-family: Arial,"Helvetica Neue",Helvetica,sans-serif;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    margin-bottom: 10px;
}

.update.btn{
    background: #0f5e96;
    color: #FFF;
    font-family: Arial,"Helvetica Neue",Helvetica,sans-serif;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    margin-bottom: 10px;
}

#update_capacity.update.btn{
    float: right;
    width: 345px;
}

input#reinitialiser.btn.btn-default.reset-all.reset-all-devis{
    margin-top: 15px;
    background-color:#8c8c8c;
    color:#fff;
    text-transform: uppercase;
    height: 45px;
    width: auto;
}

input#seuil_ht.iradio_square-red
{
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 22px;
    height: 40px;
    background:#ee1c25;
    border: none;
    cursor: pointer;
}
#vente-piece-liquide input
{
    float: right;
    width: 250px;
}

#vente-piece-liquide span
{
    float: right;
}

#bloc-vente-piece-liquide
{
    background-color: #fff;
    padding-left:30px;
    padding-right:30px;
    margin-top: 15px;
}

.toggle-vente-piece-liquide .toggle {
    border-radius: 10px;
    background: #aaaaaa;
}
.champs-obligatoires { float: right; color:#61c320; }
.txtht { text-align:right; color:#8c8c8c; }

.table-blue-header-generique
{
    color: #0f5e96;
    font-weight: bold;
    float: left;

}

div#table-blue-title-generique.table-blue-header-generique { margin-top: 30px; margin-bottom: 30px;}

.bloc-parametre{
    background-color: #fff;
    padding-left:30px;
    padding-right:30px;
    margin-bottom: 20px;
}

.devis_parametre_breadcrumb{
    margin : 10px 0px;
    font-size: 13px;
}


input.update_appoint, input.vente-piece-liquide{
    width:250px;
}

.appoint_actions{
    float : right;
}

.manufacturer_section ul {
    display :inline-block;
}

.manufacturer_section li{
    list-style-type : none;
    color: #0f5e96;
    margin: 0px 0px 7px;
}

.manufacturer_section {
    font-weight : bold;
    min-height : 140px;
}

.manufacturer_section .category{
    padding:0;
}

.worforceParameter td.table-blue-header div.radio-red > label {
    position : absolute;
    right : 20px;
}
.worforceParameter .radio-red{
    position : relative;
}

.choose_manufacturer{
    margin-top : 20px;
}

.manualReferenceParameter .table_manual-reference .table-blue-header,
.manualReferenceParameter .trash{
    text-align: center;
}

.manualReferenceParameter table input{
    width: 100px;
    display: block;
    margin : 10px auto;
}

.manualReferenceParameter table select{
    margin : 10px auto;
    height:24px;
}


.manualReferenceParameter input.ref-price,
.manualReferenceParameter .trash{
    width: 40px;
}

.manualReferenceParameter .trash{
    cursor: pointer;
    margin: 5px;
}

.manualReferenceParameter select.ref-type{
    width: 80px;
}

.manualReferenceParameter  .wrapper-ios{
    display: block;
    margin: 5px auto;
}

.manualReferenceParameter  .wrapper-ios input{
    display: none;
}

.manualReferenceParameter  .ref_activation{
    width: 110px;
    overflow: hidden;
}

input.required{
    border: solid 1px red;
}

.required_succes img{
    width : 16px;
}

.required_error, .valid_error {
    display:none;
    float :right;
    color : #E2061B;
    margin : 10px;
    &.invalid_price {
        float :none;
    }
}

.required_succes {
    float :right;
    color : #2DA53E;
    margin : 10px;
    display:none;
    &.forfait-mo {
        display: block;
    }
}
.popin .modal-dialog.capacity{
    font-size: 14px;
    width : 550px;
}
.popin .modal-dialog.capacity input[type=text]{
    width :70px
}
.table-without-border > tbody > tr > td{
    border :none;
}

.table_manual-reference .table-bordered > tbody > tr > td{
    border :solid 1px #E6E6E6;
    padding : 5px;
}

.refProduct {
    position: relative;
    top: 13px;
}
td.step5.refequip .modal-equipref {
    .DEreference {
        width: 80%;
        text-align: center;
        span:first-of-type {
            color: $orange;
            font-weight: bold;
        }
        select {
            width: 125%;
        }
    }
    img {
        float: right;
    }
}

#forfaitmoForm {
    span.field_packages {
        float: none;
        margin: 2px;
    }
}

#forfaitMoTable td{
    vertical-align: middle;
    .btn {
        border-radius: 3px;
    }
}

#forfait-mo-edit-modal-error, #forfait-mo-delete-modal-error {
    color: red;
}
