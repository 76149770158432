.v3_modal_style {
  .modal-header {
    padding: 10px 15px 10px 15px !important;
    #add_quote_confirm_tab {
      margin-top: 5px;
    }
    .nav-tabs {
      .active-paint {
        margin: -15px 0 -15px -16px !important;
        .border_bottom_red:after{
          margin-top: 6px !important;
        }
      }
      li {
        margin: -10px 0 -15px -15px !important;
        .border_bottom_red:after{
          margin-top: 6px !important;
        }
      }
    }
  }
  span.custom_close_modal{
    text-indent: -9999px;
    overflow: hidden;
    padding: 0;
    cursor: pointer;
    border: 0;
    -webkit-appearance: none;
    background: url(../../../../images/close_button_white.png) no-repeat center;
    height: 20px;
    width: 20px;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    margin-top: 2px;
    margin-right: 0;
    float: right;
    opacity: .8;
  }
  .relative {
    margin-bottom: 0px;
  }
  .modal-header {
    height: 45px !important;
    .nav-tabs {
      li {
        margin: -15px 0 -15px -15px !important;
        a {
          height: 45px !important;
          padding: 14px 25px 10px !important;
          &.border_bottom_red:after {
            padding-bottom: 0 !important;
          }
          &:hover {
            cursor: pointer !important;
            background: white !important;
            height: 45px;
          }
        }
      }
    }
  }
}

.quotes {
  font-family: "Futura LT",sans-serif;
  &-container {
    width: 100%;
    background-color: $white;
    padding: 15px;
  }

  &-title {
    border-bottom: 1px solid $greyE0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    h2 {
      color: $blue;
      font-family: 'Futura LT Heavy',sans-serif;
      text-transform: uppercase;
      font-size: 22px;
    }
  }

  &-status {
    p {
      font-size: 14px;

      & .status {
        color: $red_dark;
      }
    }

    & .list-status {
      &-item {
        display: inline-block;
        input {
          position: relative;
          top: 3px;
        }
      }
    }
  }

  &-search {
    margin-top: 15px;
    &--block-form {
      border: 1px solid $blue1;
      margin: 0;
      &--header {
        color: $white;
        border-bottom: 1px solid $greyE0;
        position: relative;
        h3 {
          padding: 5px 0 5px 5px;
          margin: 0;
          background: $blue;
          color: $white;
          font-size: 14px;
        }
      }

      &--sub-header {
        font-size: 14px;
      }

      &-body {
        margin: 10px 5px;
        &--input {
          font-family: "Futura LT",sans-serif;
          label {
            font-family: Arial, sans-serif;
            font-size: 14px;
            margin-top: 5px;
          }

          td {
            font-family: "Futura LT",sans-serif;
            padding: 0 6px;
            &:nth-child(2n+1) {
              width: 50px;
            }
            &.all_auto {
              width: auto;
            }
          }

          input {
            font-style: normal;
            color: #686868;
            height: 22px;
            width: 100%;
            padding-left: 2px;
            margin: 2px;
            border: 1px solid #ccc;
            font-size: 14px;
            &.datepicker {
              background-image: url('../../../../images/icon-calendar-orange.png');
              background-repeat: no-repeat;
              background-position: right 3px top 2px;
              background-size: 12px;
              font-size: 9px;
            }
          }

          select {
            width: 100%;
          }
          td.datepicker-container {
            max-width: 100px;
            min-width: 100px;
          }

          .datepicker-container {
            ul {
              max-height: 400px !important;
            }
          }

          textarea {
            width: 100%;
            height: 75px;
          }
        }
      }

      &--show-more {
        font-size: 1.5rem;
        display: block;
        padding: 0;
        text-decoration-line: underline;
        line-height: 1.5;
        color: $grey56;

        &:hover, &:focus {
          color: $grey56;
          text-decoration: underline;
        }
      }

      &--show-more {
        font-size: 1.5rem;
        display: block;
        padding: 0;
        text-decoration-line: underline;
        line-height: 1.5;
        color: $grey56;

        &:hover, &:focus {
          color: $grey56;
          text-decoration: underline;
        }
      }
    }
  }
}

.choose_vehicle {
  background-color: rgba(86, 86, 86, .8);

  .modal-dialog {
    width: 50%;
    top: 0 !important;

    .modal-content {
      top: 0 !important;
    }

    .modal-header {
      background-color: #0f5e96;

      .border_bottom_red:after {
        width: 142px;
      }

      .nav-tabs {
        border-bottom: none;
      }
    }

    .modal-body {
      overflow-y: scroll;
      height: 60vh;
    }
  }

  .caret {
    margin-top: -5px !important;
  }

  &.confirm_add_to_quote {
    #add_quote_confirm_tab {
      font-size: 12px;
      font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    }

    .modal-body {
      height: auto;
    }
  }
}

.btn-quote {
  color: $white;
  cursor: pointer;
  display: inline-block;
  border: none;
  padding: 2px 4px;
  font-size: 14px;
  font-family: "Futura LT",sans-serif;

  & i {
    border: none !important;
  }

  &.blue {
    background: $blue;
    color: $white;

    &:hover {
      background: $orange3;
    }
  }

  &.orange {
    background: $orange3;
    color: $white;

    &:hover {
      background: $blue;
    }
  }

  &.without-hover {
    &.blue {
      &:hover {
        background: $blue;
      }
    }

    &.orange {
      &:hover {
        background: $orange3;
      }
    }
  }

}

a.btn-quote {
  &:hover {
    color: $white;
  }
}

.gray-bg {
  background: $greyF3;
  border: 1px solid $greyC;
  padding: 0 5px;
}

.scroll-300 {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 300px;
  border-bottom: 1px solid $greyCB;
  border-top: 1px solid $greyCB;
}

.scroll-250 {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 250px;
  border-top: 1px solid $greyCB;
  margin-bottom: 10px !important;
  padding-bottom: 0 !important;
}


.border-black {
  border: 1px solid $black;
}

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.force-text-right {
  text-align: right !important;
}

.force-text-left {
  text-align: left !important;
}

.force-text-center {
  text-align: center !important;
}

.quotes_alert {
  font-size: 14px;
  font-weight: bold;

  &.red {
    color: $red;
  }

  &.green {
    color: $green;
  }
}

.btn-add-product-quote {
  position: relative;
  padding: 10px 30px 10px 36px;
  color: $white;
  margin-top: 10px;
  display: block;
  background: $blue;
  cursor: pointer;
  vertical-align: text-top;
  font-family: Arial,"Helvetica Neue",Helvetica,sans-serif;
  font-size: 12px;
  line-height: 1.42857;
  &:hover, &:focus {
    color: $white;
  }
  &:before {
    content: "";
    background: url(../../../../images/devis_white.png) no-repeat;
    background-size: 14px;
    height: 24px !important;
    width: 20px;
    display: inline-block;
    position: absolute;
    left: 10px;
    top: 6px;
  }
}

.quote_radio_custom {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 17px;
  user-select: none;
  float: none !important;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .quote_check_mark {
      background-color: $blue;
    }

    &:checked ~ .quote_check_mark:after {
      display: block;
    }
  }

  &:hover input:not(:checked) ~ .quote_check_mark {
    background-color: #ccc;
  }

  & .quote_check_mark {
    position: absolute;
    top: 6px;
    left: 9px;
    height: 15px;
    width: 15px;
    background-color: #eee;
    border-radius: 50%;
    &:after {
      content: "";
      display: none;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: white;
      margin: 5px auto auto;
    }
  }
}

.current_quote_not_exists {
  pointer-events: none;
  color: #ccc;
}

.table_container {
  display: table;
  width: 100%;


  .table_row {
    display: table-row;
    height: 40px;


    .table_column {
      display: table-cell;
      vertical-align: center;
      border: 1px solid #cbcbcb;
      text-align: center;
      line-height: 40px;
      font-family: 'Futura LT',sans-serif;
      &:nth-of-type(1) {
        width: 50px;
      }
      .quote_check_mark {
        top: 2px;
        left: 16px;
      }
    }

    &.table_header {
      color: $blue;
      font-size: 15px;
      .table_column {
        text-align: center;
        line-height: 40px;
        border: 1px solid #cbcbcb;
        font-family: 'Futura LT',sans-serif;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }

}