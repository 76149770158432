.glassmaker-alert {
  background: $white;
  border: 4px solid $orange;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  p {
    padding-top: 10px;
    padding-bottom: 12px;
    width: 700px;
    margin: auto;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    strong {
      color: $orange3;
      text-transform: uppercase;
    }
  }
}
.glass-makers-disable-Btn {
  & > a {
    opacity: 0.4;
  }
}
.alert-icon {
  position: absolute;
  top: 50%;
  width: 50px;
  height: 44px;
  background-repeat: no-repeat;
  background-image: url(../../../../images/alert-icon.png);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-left: 50px;
}

.glass-makers-disable-Btn:hover {
  & > .disabled-btn-info {
    display: block;
  }
}

.disabled-btn-info {
  display: none;
  position: absolute;
  top: 103%;
  background: $white;
  border: 1px solid $blue;
  border-radius: 3px;
  padding: 5px;
  text-align: center;
  box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.2);
}